/* eslint no-eval: 0 */
import {get, post} from "../Network/ProcessNetwork";
import env from "../Environment";
import {getForm} from "./FormBuilderService";
import {getTaskDetail} from "./WorkflowServices/TaskService";
import {CONDITIONS} from "../Utils/Constants";

export function launchProcess(processId: number, initialValue: any = {}) {
  return post(
    `/api/v2/${env.domain}/wf/process/launch/${processId}`,
    initialValue,
  );
}

export async function getTaskExecDetail(
  taskAssignmentId: number,
  processId: number,
): Promise<any> {
  const res: any = await get(
    `/api/v2/${env.domain}/wf/task/${taskAssignmentId}`,
  );
  if (res.success) {
    const attachmentToShow = res.item.attachments.find((i: any) =>
      eval(replaceValuesInRule(i.rule, res.item.process_data)),
    );

    if (attachmentToShow) {
      let resAttach = await getFormByAttachmentId(
        attachmentToShow.attachment_id,
      );
      if (resAttach.success) {
        attachmentToShow.form = resAttach.item;
        for (let field of attachmentToShow.form.fields) {
          field.options = [];
          if (field.list_provider_id) {
            const resFieldOptions = await getProviderList(
              field.list_provider_id,
            );
            if (resFieldOptions.success) {
              field.options = resFieldOptions.item.options;
            }
          }
          if (field.detail_form_id) {
            const result = await getForm(field.detail_form_id);
            if (result && result.success) {
              field.detail_form = result.item;
            }
          }

        }
      }
      res.item.attachments = [attachmentToShow];
    }
    const resTaskDetail = await getTaskDetail(
      res.item.task_ref.task_id,
      processId,
    );
    if (resTaskDetail.success) {
      res.item.task_ref.task = resTaskDetail.item;
    }
    return Promise.resolve(res);
  }
  return Promise.resolve(new Response());
}

export function getFormByAttachmentId(
  attachmentId: number,
): Promise<any> {
  return get(
    `https://sbx-svc-form.nubesocobox.com/api/v2/${env.domain}/wf/form/${attachmentId}`,
  );
}

function getProviderList(providerId: number) {
  return get(
    `https://sbx-svc-form.nubesocobox.com/api/v2/${env.domain}/wf/dl/${providerId}/options`,
  );
}


const replaceValuesInRule = (rule: string, data: any) => {
  const defaultValues: string[] = [];
  if (rule) {
    const vArrOr = rule.split(' || ');
    vArrOr.forEach(a => {
      const vArrAnd = a.split(' && ');
      vArrAnd.forEach(a => defaultValues.push(a));
    });
  }

  const sp = defaultValues
    .map(a => {
      const condition = CONDITIONS.find(c => a.includes(c));
      if (condition) {
        const d = a.split(condition);
        const f = d[0].split('_');
        const field: string = d[0].replace(
          `${f[0]}_${f[1]}_`
          , '');
        return {
          formId: f[0],
          fieldId: f[1],
          value: d[1],
          field,
        };
      }
      return null;
    })
    .filter(i => i !== null);

  sp.forEach(field => {
    if (field?.field) {
      rule = rule.replace(
        `${field?.formId}_${field?.fieldId}_${field?.field}`,
        `"${data[field?.field] ? data[field?.field].value : ''}"`
      );
    }
  });
  console.log('replaceValuesInRule -> rule', rule);
  return rule;
};


export function finishTask(
  processExecId: number,
  actionId: number,
  formId: number,
  fields: any = {},
) {
  return post(
    `/api/v2/${env.domain}/wf/task/${processExecId}/finish/${actionId}`,
    {
      form_id: formId,
      action_id: actionId,
      fields,
    },
  )
}

export function saveTaskService(taskId: number, fields: any[]) {
  return post(
    `/api/v2/${env.domain}/wf/task/${taskId}/save`,
    {fields},
  );
}


export function getPropertiesService(process_id: number, execution_id: number) {
  return get(`/api/v2/${env.domain}/wf/process/${process_id}/execution/${execution_id}`);
}

import Form from '../../Models/FormBuilder/Form';
import IField from '../../Models/FormBuilder/IField';
import Fragment from '../../Models/Fragment';
import Section from '../../Models/Section';
import Document from '../../Models/Document';

export const GET_FRAGMENTS = 'fragment/getFragments';
export const GET_ADD_FRAGMENT = 'fragment/getAddFragment';
export const SET_FRAGMENTS = 'fragment/setFragments';
export const UPDATE_FRAGMENT = 'fragment/updateFragment';
export const REMOVE_FRAGMENT = "fragment/removeFragment";
export const ON_SORT_END_FRAGMENTS = "fragment/onSortEnd"
export const GET_CURRENT_FRAGMENT = "fragment/getCurrentFragment"

export interface FormBuilderState {
  fragments: Fragment[];
  fields: IField[];
  subFields: IField[],
  forms: Form[];
  sections: Section[];
  fragment?: Fragment;
  document?: Document;
  state: string;
}

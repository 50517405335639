import IField from "../../../Models/FormBuilder/IField";
import * as sbx from "sbx-ui-input-library";
import {
  faAt,
  faCalendarDay,
  faCheckCircle,
  faClock,
  faComment,
  faCommentAlt,
  faCopy,
  faDotCircle,
  faFile,
  faGripHorizontal,
  faHashtag,
  faIdCard,
  faKey,
  faLayerGroup,
  faListAlt,
  faMoneyBillAlt,
  faPhoneAlt,
  faTable
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

let currency: number = 0;
export const currencyField: IField = {
  label: 'Moneda - etiqueta',
  name: 'moneda_nombre',
  size: 12,
  field_type: 'SMALL_TEXT',
  sub_type: 'CURRENCY',
  component: sbx.CurrencyComponent,
  input: <sbx.CurrencyComponent id="small_text_currency"
                                name="small_text_currency"
                                value={currency}
                                placeholder="here placeholder currency"
                                onChange={val => currency = val}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faMoneyBillAlt
};

let date: Date | null = new Date();
export const DateField: IField = {
  label: 'Fecha - etiqueta',
  name: 'fecha_nombre',
  size: 12,
  field_type: 'DATE',
  component: sbx.DateComponent,
  input: <sbx.DateComponent id="date_input"
                            value={date}
                            onChange={(val: any) => date = val}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faCalendarDay
};

let dateRage: { startDate: Date, endDate: Date | null } = {startDate: new Date(), endDate: null}
export const DateRangeField: IField = {
  label: 'Rango de fechas - etiqueta',
  name: 'rango_fecha_nombre',
  size: 12,
  field_type: 'DATE_RANGE',
  component: sbx.DateRangeComponent,
  input: <sbx.DateRangeComponent id="date_input"
                                 value={dateRage}
                                 onChange={(val: any) => dateRage = val}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faCalendarDay
};


let document = '';
export const documentField: IField = {
  label: 'Documento - etiqueta',
  name: 'documento_nombre',
  size: 12,
  field_type: 'SMALL_TEXT',
  sub_type: 'DOCUMENT',
  component: sbx.DocumentComponent,
  input: <sbx.DocumentComponent id="small_text_document"
                                name="small_text_document"
                                value={document}
                                placeholder="here placeholder document"
                                onChange={val => document = val}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faIdCard
};

let email = '';
export const emailField: IField = {
  label: 'Email - etiqueta',
  name: 'email_nombre',
  size: 12,
  field_type: 'SMALL_TEXT',
  sub_type: 'EMAIL',
  component: sbx.EmailComponent,
  input: <sbx.EmailComponent id="small_text_email"
                             name="small_text_email"
                             value={email}
                             placeholder={"here placeholder email"}
                             onChange={val => email = val}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faAt
};

export const formGroup: IField = {
  label: 'Grupo - etiqueta',
  name: 'grupo_nombre',
  size: 12,
  field_type: 'FORM_GROUP',
  required: false,
  single_value: true,
  read_only: false,
  icon: faLayerGroup
};

let largeText = '';
export const largeTextField: IField = {
  label: 'Texto largo - etiqueta',
  name: 'texto_largo_nombre',
  size: 12,
  field_type: 'LARGE_TEXT',
  component: sbx.LargeTextComponent,
  input: <sbx.LargeTextComponent id="large_text_default"
                                 name="large_text_default"
                                 value={largeText}
                                 placeholder={"here placeholder email"}
                                 onChange={val => email = val}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faCommentAlt
};

let multiFile: File[] = [];
export const MultiFileField: IField = {
  label: 'Archivos - etiqueta',
  name: 'archivos_nombre',
  size: 12,
  field_type: 'FILE',
  component: sbx.MultiFileComponent,
  input: <sbx.MultiFileComponent value={multiFile}
                                 onChange={files => multiFile = files}
                                 onDropRejected={() => null}/>,
  required: false,
  single_value: false,
  read_only: false,
  icon: faCopy
};

export const multiSelectField: IField = {
  label: 'Selector Múltiple - etiqueta',
  name: 'selector_multiple_nombre',
  size: 12,
  field_type: 'OPTIONS',
  sub_type: 'SELECT',
  component: sbx.MultiSelectComponent,
  input: <sbx.MultiSelectComponent id="multi_select_input" name="multi_select_input"
                                   options={[]}
                                   value={[]}
                                   placeholder="here placeholder multi select"
                                   onChange={() => null}/>,
  required: false,
  single_value: false,
  read_only: false,
  icon: faListAlt
};

let number = '';
export const numberField: IField = {
  label: 'Número - etiqueta',
  name: 'número_nombre',
  size: 12,
  field_type: 'SMALL_TEXT',
  sub_type: 'NUMBER',
  component: sbx.NumberComponent,
  input: <sbx.NumberComponent id="number_input"
                              name="number_input"
                              value={number}
                              placeholder="here placeholder number"
                              onChange={val => number = val}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faHashtag
};

let password = '';
export const passwordField: IField = {
  label: 'Contraseña - etiqueta',
  name: 'contrasena_nombre',
  size: 12,
  field_type: 'SMALL_TEXT',
  sub_type: 'PASSWORD',
  component: sbx.PasswordComponent,
  input: <sbx.PasswordComponent id="small_text_password"
                                name="small_text_password"
                                value={password}
                                placeholder="here placeholder password"
                                onChange={val => password = val}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faKey
};

let phone = '';
export const phoneField: IField = {
  label: 'Telefono - etiqueta',
  name: 'telefono_nombre',
  size: 12,
  field_type: 'SMALL_TEXT',
  sub_type: 'PHONE',
  component: sbx.PhoneComponent,
  input: <sbx.PhoneComponent id="small_text_phone"
                             name="small_text_phone"
                             value={phone}
                             placeholder="here placeholder phone"
                             onChange={val => phone = val}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faPhoneAlt
};

export const selectField: IField = {
  label: 'Selector - etiqueta',
  name: 'selector_nombre',
  size: 12,
  field_type: 'OPTIONS',
  sub_type: 'SELECT',
  component: sbx.SelectComponent,
  input: <sbx.SelectComponent id="select_input" name="select_input"
                              options={[]}
                              value={''}
                              placeholder="here placeholder select"
                              onChange={() => null}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faListAlt
};

let singleFile: File | null = null;
export const SingleFileField: IField = {
  label: 'Archivo - etiqueta',
  name: 'archivo_nombre',
  size: 12,
  field_type: 'FILE',
  component: sbx.SingleFileComponent,
  input: <sbx.SingleFileComponent value={singleFile}
                                  onChange={file => singleFile = file}
                                  onDropRejected={() => {
                                  }}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faFile
};

let smallText = '';
export const smallTextField: IField = {
  label: 'Texto corto - etiqueta',
  name: 'texto_corto_nombre',
  size: 12,
  field_type: 'SMALL_TEXT',
  component: sbx.SmallTextComponent,
  input: <sbx.SmallTextComponent id="small_text_default"
                                 name="small_text_default"
                                 value={smallText}
                                 placeholder={"here placeholder default"}
                                 onChange={val => smallText = val}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faComment
};

let table: { [key: string]: any } = {};
export const tableField = {
  label: 'Tabla - etiqueta',
  name: 'tabla_nombre',
  size: 12,
  field_type: 'TABLE',
  component: sbx.TableComponent,
  input: <sbx.TableComponent id="table"
                             name="table"
                             value={table}
                             fields={[]}
                             onChange={val => table = val}/>,
  required: false,
  single_value: false,
  read_only: false,
  icon: faTable
}

let container: { [key: string]: any } = {};
export const fieldsContainer = {
  label: 'Grupo - etiqueta',
  name: 'grupo_nombre',
  size: 1,
  field_type: 'FORM_GROUP',
  component: sbx.FormGroupComponent,
  input: <sbx.FormGroupComponent value={container}
                                 onChange={e => container = e}
                                 count={2}
                                 fields={[]}/>,
  icon: faGripHorizontal,
  single_value: true
}

let time = '';
export const timeField: IField = {
  label: 'Hora - etiqueta',
  name: 'hora_nombre',
  size: 12,
  field_type: 'SMALL_TEXT',
  sub_type: 'TIME',
  component: sbx.TimeComponent,
  input: <sbx.TimeComponent id="small_text_time"
                            name="small_text_time"
                            value={time}
                            placeholder="here placeholder time"
                            onChange={val => time = val}/>,
  required: false,
  single_value: true,
  read_only: false,
  icon: faClock
};

let check = null;
export const checkboxField: IField = {
  label: "Checkbox - etiqueta",
  name: "checkbox_nombre",
  size: 1,
  field_type: "OPTIONS",
  sub_type: "TOGGLE",
  component: sbx.CheckboxComponent,
  required: false,
  single_value: false,
  read_only: false,
  icon: faCheckCircle,
  input: <sbx.CheckboxComponent id={"checkbox__"}
                                name={"checkbox"}
                                value={check}
                                onChange={e => check = e}
                                options={[]}/>
}

let radio = null;
export const radioField: IField = {
  label: "Radio - etiqueta",
  name: "radio_nombre",
  size: 1,
  field_type: "OPTIONS",
  sub_type: "TOGGLE",
  component: sbx.RadioButtonComponent,
  required: false,
  single_value: true,
  read_only: false,
  icon: faDotCircle,
  input: <sbx.RadioButtonComponent id={"radio__"}
                                   name={"radio"}
                                   value={radio}
                                   onChange={e => radio = e}
                                   options={[]}/>
}

import env from "../../Environment";
import {get, post} from "../../Network/ProcessNetwork";
import Response from "../../Models/Response";
import TaskType from "../../Models/Workflow/TaskType";
import AttachmentType from "../../Models/Workflow/AttachmentType";
import TargetType from "../../Models/Workflow/TargetType";

export function createProcessModel(name: string, identifier: string, params: any = {}): Promise<Response<any>> {
  return post(`/api/v2/${env.domain}/wf/process/model`, {
    "identifier": identifier,
    "name": name,
    "params": JSON.stringify(params)
  }).catch(reason => {
    return {success: false};
  });
}

export function getProcessModel(process_id: number): Promise<Response<any>> {
  return get(`/api/v2/${env.domain}/wf/process/model/${process_id}`).catch(reason => {
    return {success: false};
  });
}

export function attachForm(process_id: number, task_id: number, form_id: number, rule?: string): Promise<Response<any>> {
  return post(`/api/v2/${env.domain}/wf/process/model/${process_id}/attachment`, {
    "target_item_id": task_id,
    "attachment_type": AttachmentType.FORM,
    "target_type": TargetType.TASK,
    "task_type": TaskType.USER,
    "attachment_id": form_id,
    rule
  }).catch(reason => {
    return {success: false};
  });
}

import React, {Fragment} from "react";
import {conditions} from "../../../Utils/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {RuleData} from "./RuleComponent";
import ToggleCheckbox from "../ToggleCheckbox";

const ListRules = ({
                     rules,
                     deleteRule,
                     onChangeOperator
                   }: {
  rules: RuleData[];
  deleteRule: (index: number) => void;
  onChangeOperator: (e: "&&" | "||", index: number) => void
}) => (

  <div className="card-body px-3">
    {!rules.length ? 'No hay reglas' : <b className="mb-2">Mostrar cuando</b>}
    <div className="col-12">
      <div className="row">
        {rules.map((r, i) => (
          <Fragment key={i}>
            {r.operator && (
              <div className="col-1 mb-2 d-flex align-items-center justify-content-center">
                <ToggleCheckbox
                  id={"label-operator" + i}
                  value={r.operator === "&&"}
                  labelInfo={r.operator === '&&' ? ' Y ' : ' Ó '}
                  onChange={() => onChangeOperator(r.operator === "&&" ? "||" : "&&", i)}/>
              </div>
            )}
            <div className="col-3 mb-2 bg-light shadow-sm p-2">
              <p className="m-0">
                {r.field.label}{' '}
                <b className="mr-2">
                  {conditions.find(c => c.value === r.condition)?.label || ''}
                </b>
                {typeof (r.value === 'string' || r.value === 'number') ? (r.value || "vacío") : r.value.label}
                <br/>
                {!r.field.id && <small className="text-danger text-center">(Puede no cumplirse)</small>}
              </p>
              <span
                style={{top: '-10px', right: '-10px', cursor: "pointer"}}
                className="position-absolute">
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  onClick={() => deleteRule(i)}
                  className="text-danger"
                />
              </span>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  </div>
);

export default ListRules;

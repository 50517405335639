import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  className?: string;
  style?: any;
  size?: SizeProp;
}

const FaSpinnerComponent: React.FC<IProps> = (props) => {
  return (
    <FontAwesomeIcon icon={faSpinner} pulse={true} {...props}/>
  );
};

export default FaSpinnerComponent;

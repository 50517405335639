import React, {useState} from 'react';
import Category from '../../../../Models/Category';
import DeleteCategoryModal from '../DeleteCategory/DeleteCategoryModal';
import CreateCategoryModal from '../CreateCategory/CreateCategoryModal';
import {useDispatch} from 'react-redux';
import {actionsCategory} from '../../../../Store/Category/Slice';
import TableComponent from "../../../Shared/TableComponent/TableComponent";
import {Head} from "../../../../Models/Table";
import {State} from "../../../../Store/Category/Types";


type Props = {
  list: Category[],
  state: State
}


const CategoryListComponent: React.FC<Props> = ({list, state}) => {


  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [row, setRow] = useState<Category>();
  const dispatch = useDispatch();

  const setEditModal = (r: Category) => {
    setRow(r);
    setOpenEditModal(true);
    dispatch(actionsCategory.setEditModal())
  }

  const setDeleteModal = (r: Category) => {
    setRow(r);
    setOpenDeleteModal(true);
    dispatch(actionsCategory.setDeleteModal())
  }

  const headers: Array<Head> = [
    {field: "name", header: "Nombre", width: "100%"}
  ];

  return (
    <>
      <div className="table-responsive">
        <TableComponent filter
                        rows={list}
                        pagination
                        loading={state === State.PENDING}
                        rowsCount={15}
                        headers={headers}
                        onEdit={setEditModal}
                        onRemove={setDeleteModal}/>
        {row && <>
          <CreateCategoryModal category={row} isOpen={openEditModal} toggle={() => setOpenEditModal(false)}
                               onClosed={() => setOpenEditModal(false)}/>
          <DeleteCategoryModal category={row} isOpen={openDeleteModal} toggle={() => setOpenDeleteModal(false)}
                               onClosed={() => setOpenDeleteModal(false)}/>
        </>}
      </div>
    </>
  );
};

export default CategoryListComponent;

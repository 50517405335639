import React, {useRef, useState} from 'react';
import ModalComponent from "../Shared/ModalComponent";
import {ModalProps} from "reactstrap";
import {useDispatch} from "react-redux";
import Document from "../../Models/Document";
import {actions} from "../../Store/Section/Slice";
import Section from "../../Models/Section";

interface Props extends ModalProps {
  document: Document;
  prevSec?: Section
}

const CreateSectionModal: React.FC<Props> = ({document, prevSec, ...props}) => {
  const ref = useRef<HTMLInputElement>(null);
  const descRef = useRef<HTMLInputElement>(null);
  const [prevSection] = useState<Section | undefined>(prevSec);

  const dispatch = useDispatch();
  const onSubmit = () => {
    const name = ref.current?.value;
    const description = descRef.current?.value || "";
    if (name) {
      dispatch(actions.getAddSection({name, document, description, prevSec: prevSection}));
      if (props.onClosed) {
        props.onClosed()
      }
    }
  };
  return (
    <ModalComponent  {...props} noFooter>
      <form onSubmit={event => {
        event.preventDefault();
        onSubmit();
      }}>
        <div className="form-group">
          <label htmlFor="" className="label">Nombre de la sección</label>
          <input type="text" className="form-control" ref={ref}/>
        </div>
        <div className="form-group">
          <label htmlFor="">Descripción</label>
          <input ref={descRef} type="text" className="form-control"/>
        </div>
        <div className="">
          <button type="button" onClick={props.onClosed} className="btn btn-danger mr-3">Cancelar</button>
          <button className="btn btn-primary mr-3">Crear Seccion</button>
        </div>
      </form>
    </ModalComponent>
  );
};

export default CreateSectionModal;

import {all, call, put, takeEvery} from "redux-saga/effects";
import {GET_USERS, State} from "./Types";
import {getAllUserService} from "../../Services/UserService";
import {usersAction} from "./Slice";


function* getUsersSaga() {
  try {
    const res = yield call(getAllUserService);
    console.log(res)
    yield put(usersAction.setAllUsers(res.results));
  } catch (e) {
    yield put(usersAction.changeState(State.REJECTED));
  }
}

export default function* UsersSaga() {
  return yield all([
    takeEvery(GET_USERS, getUsersSaga)
  ])
}

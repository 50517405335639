import React from 'react';
import {Link, RouteComponentProps, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {useSelector} from 'react-redux';
import {RootState} from '../../../Store/Reducers';
import FormList from "../FormBuilder/FormList";

const DocumentDetailComponent: React.FC<RouteComponentProps> = ({match}) => {
  const {section} = useSelector((state1: RootState) => state1.sectionReducer);
  const {workflowId} = useParams<{ [key: string]: string }>();

  console.log(match);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          <Link
            to={`/sections/${workflowId}`}
            className="btn btn-sm btn-primary mr-2">
            <FontAwesomeIcon icon={faArrowLeft}/>
          </Link>
          <h3 className="m-0">Nombre de la seccion: {section?.name}</h3>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <FormList/>
        </div>
      </div>
    </div>
  );
};

export default DocumentDetailComponent;

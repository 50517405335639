import React from 'react';
import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import {editorConfiguration} from "./EditorComponent";

interface Props {
  id?: string;
  data: string;
  setData: (data: string) => void;
}

export default function EditorTextComponent({
                                              id,
                                              data,
                                              setData,
                                            }: Props): React.ReactElement<Props> {
  return (
    <>
      <div id={id || "toolbar-container-editor-component"}/>
      <CKEditor

        editor={DecoupledEditor}
        data={data}
        config={editorConfiguration}
        onInit={(editor: any) => {
          // You can store the "editor" and use when it is needed.
          const toolbarContainer = document.querySelector(`#${id || "toolbar-container-editor-component"}`);
          if (toolbarContainer)
            toolbarContainer.appendChild(editor.ui.view.toolbar.element);
          console.log('Editor is ready to use!', editor);
        }}
        onChange={(event: any, editor: any) => {
          const d = editor.getData();
          setData(d);
        }}
        onBlur={(event: any, editor: any) => {
          console.log('Blur.', editor);
        }}
        onFocus={(event: any, editor: any) => {
          console.log('Focus.', editor);
        }}
      />
    </>
  );
}

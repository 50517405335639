import Axios, {AxiosError, AxiosResponse} from "axios";
import Response from "../Models/Response";
import {logout} from "../Services";

// ==========================================================================================

const http = Axios.create({
  baseURL: 'https://sbx-svc-process.nubesocobox.com',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("access_token")}`
  }
});

http.interceptors.response.use(function (response: AxiosResponse) {
    return response;
  }, function (error: AxiosError) {
    if (!error.response?.status || !(error.response.status >= 200 && error.response.status <= 300)) {
      logout();
    }
  }
);

export function setHeaderToken(token: string) {
  http.defaults.headers = {Authorization: "Bearer " + token};
}

export function get(url: string, params?: any): Promise<Response<any>> {
  return http.get(url, {params}).then(res => res.data).catch(reason => Promise.reject(new Error(reason.statusText)));
}

export function post(url: string, params?: any): Promise<Response<any>> {
  return http.post(url, params).then(res => {
    return res.data ? res.data : res;
  }).catch(reason => {
    return Promise.reject(reason);
  });
}

export function put(url: string, params?: any): Promise<Response<any>> {
  return http.put(url, params).then(res => {
    return res.data ? res.data : res;
  }).catch(reason => {
    return Promise.reject(reason);
  });
}

export function deleteReq(url: string, params?: any): Promise<Response<any>> {
  return http.delete(url, params).then(res => res.data).catch(reason => {
    console.error(reason.message);
  });
}

import {sbxCoreService} from '../Network';
import Document from '../Models/Document';
import Section from '../Models/Section';
import {createForm} from './FormBuilderService';
import {toast} from '../Utils/Utils';
import {plainToClass} from 'class-transformer';
import {createTask, deleteTaskService} from './WorkflowServices/TaskService';
import TaskType from '../Models/Workflow/TaskType';
import {attachForm, getProcessModel,} from './WorkflowServices/ProcessModelService';
import {createEvent, createSeq, deleteSequenceService} from './WorkflowServices/EventService';
import EventType from '../Models/Workflow/EventType';
import TargetType from '../Models/Workflow/TargetType';
import Response from '../Models/Response';

export const addSection = async (
  name: string,
  document: Document,
  description: string,
  prevSec?: Section,
): Promise<Response<any>> => {

  const addEventType = async (type: EventType) => {
    return await createEvent(
      document.workflow_id,
      `${type.toLocaleLowerCase()} ${document.name}`,
      type,
    );
  };


  const section = new Section();
  section.name = name;
  section.description = description;
  // TODO: CREATE SERVICE TO ADD TASK
  const resProcessModel = await getProcessModel(document.workflow_id);
  const resTask = await createTask(
    section.name,
    document.workflow_id,
    TaskType.USER,
  );
  if (resTask.success) {
    section.task_id = resTask.item?.id;
    if (resProcessModel.success && resProcessModel.item) {
      const {
        item: {events, sequences, tasks},
      } = resProcessModel;
      let resEvent: any, endRes: any;
      const startEvent = events.find(
        (e: any) => e.event_type === EventType.START),
        endEvent = events.find((e: any) => e.event_type === EventType.END);
      if (!startEvent) {
        resEvent = await addEventType(EventType.START);
      }
      if (!endEvent) {
        endRes = await addEventType(EventType.END);
      }
      const endSeq = sequences.find(
        (s: any) => s.to_item_id === endEvent?.id,
      );
      let from_item;
      let from_type;
      if (resEvent || (startEvent && !tasks.length)) {
        from_item = startEvent ? startEvent.id : resEvent.item.id;
        from_type = TargetType.EVENT;
      } else {
        from_item = prevSec?.task_id;
        from_type = TargetType.TASK;
      }
      if (prevSec) {
        await createSeq(
          document.workflow_id,
          'BACK',
          'Atras',
          section.task_id,
          prevSec.task_id,
          TargetType.TASK,
          TargetType.TASK,
        );
      }
      const seqNext = await createSeq(
        document.workflow_id,
        'NEXT',
        'Siguiente',
        from_item,
        section.task_id,
        from_type,
        TargetType.TASK,
      );
      if (seqNext) {
        const {
          item: {to_item_id: idSeq},
        } = seqNext;
        if (endRes) {
          const {
            item: {id: idEvent},
          } = endRes;
          await createSeq(
            document.workflow_id,
            'NEXT',
            'Finalizar',
            idSeq,
            idEvent,
            TargetType.TASK,
            TargetType.EVENT,
          );
        } else {
          if (endSeq) {
            await deleteSequenceService(document.workflow_id, endSeq.id);
          }

          await createSeq(
            document.workflow_id,
            'NEXT',
            'Finalizar',
            idSeq,
            endRes?.item?.id || endEvent.id,
            TargetType.TASK,
            TargetType.EVENT,
          );
        }
      }
    }
  }
  const resForm = await createForm({name: `${section.name}`});
  if (resForm.success && resForm.item?.id) {
    section.form_id = resForm.item.id;
    const resAttachForm = await attachForm(
      document.workflow_id,
      section.task_id,
      resForm.item?.id,
    );
    if (resAttachForm.success) {
    }
  }
  section.last_updated = new Date().toISOString();
  if (document.key) {
    section.document = document.key;
  }
  const resSbx: any = await sbxCoreService.with('section').insert(section);
  if (resSbx) {
    toast('Nueva seccion creada');
    return Promise.resolve({success: true});
  }
  return Promise.resolve({success: false});
};

// export const getSections = async (
//   document: string,
// ): Promise<| { success: boolean; contents: Section[]; document: Document }
//   | { success: boolean }> => {
//   const res: any = await sbxCoreService
//     .with('section')
//     .andWhereIsEqualTo('document', document)
//     .fetchModels(["document"])
//     .loadAll();
//   if (res.success) {
//     return Promise.resolve({
//       success: true,

//     });
//   }
//   return Promise.resolve({success: false});
// };

export const getSectionService = async (
  section: string,
  document: string,
): Promise<Response<any>> => {
  const res: any = await sbxCoreService
    .with('section')
    .whereWithKeys([section])
    .loadAll();
  if (res.results) {
    const section = res.results[0];
    const resDoc = await sbxCoreService
      .with('document')
      .whereWithKeys([document])
      .find();

    return Promise.resolve({
      success: true,
      section: plainToClass(Section, section, {
        excludeExtraneousValues: true,
      }),
      document: plainToClass(Document, resDoc.results[0], {
        excludeExtraneousValues: true,
      }),
    });
  }

  return Promise.resolve({success: false});
};

export async function getSections(document: string):
  Promise<{ success: boolean, contents: Section[], document: Document } | { success: boolean }> {
  const resSec: any = await sbxCoreService
    .with("section")
    .andWhereIsEqualTo('document', document)
    .loadAll();

  const resDoc: any = await sbxCoreService
    .with("document")
    .whereWithKeys([document]).find();

  if (resDoc.results.length && resSec.results) {
    const doc = resDoc.results[0];
    const processRes = await getProcessModel(doc.workflow_id);
    const {item: {sequences, events}} = processRes;

    const newList: Section [] = sortSectionBySequences(sequences, events, resSec.results);

    return Promise.resolve({
      success: true,
      contents: newList.map(s =>
        plainToClass(Section, s, {
          excludeExtraneousValues: true,
        })
      ),
      document: plainToClass(Document, doc, {
        excludeExtraneousValues: true,
      })
    });
  } else {
    return Promise.resolve({success: false});
  }
}

export async function onDragEndSectionsService(
  {
    newRow,
    oldRow,
    newRows
  }:
    {
      newRow: Section,
      oldRow: Section,
      newRows: Section[]
    }, workflow_id: number) {

  const {success, item} = await getProcessModel(workflow_id);
  if (success) {
    let sequences = item.sequences.filter((seq: any) =>
      (seq.from_item_id !== newRow.task_id) && (seq.to_item_id !== newRow.task_id) &&
      (seq.from_item_id !== oldRow.task_id) && (seq.to_item_id !== oldRow.task_id));

    let deleteSequences = item.sequences.filter((seq: any) =>
      (seq.from_item_id === newRow.task_id) || (seq.to_item_id === newRow.task_id) ||
      (seq.from_item_id === oldRow.task_id) || (seq.to_item_id === oldRow.task_id));


    const startEvent = item.events.find((e: any) => e.event_type === EventType.START);
    const endEvent = item.events.find((e: any) => e.event_type === EventType.END);
    let startSequence = sequences.find((e: any) => e.from_item_id === startEvent.id);
    let endSequence = sequences.find((e: any) => e.to_item_id === endEvent.id);

    for await (let seq of deleteSequences) {
      await deleteSequenceService(workflow_id, seq.id)
    }
    await Promise.all(newRows
      .map(async (t: any, index: number) => {
        if (!startSequence && !index) {
          const startRes = await createSeq(
            workflow_id,
            'NEXT',
            'Siguiente',
            startEvent.id,
            t.task_id,
            TargetType.EVENT,
            TargetType.TASK,
          );
          if (startRes.item) {
            startSequence = startRes.item;
          }

        }
        if (!endSequence && index === (newRows.length - 1)) {
          const endRes = await createSeq(
            workflow_id,
            'NEXT',
            'Finalizar',
            t.task_id,
            endEvent.id,
            TargetType.TASK,
            TargetType.EVENT
          );
          if (endRes.item) {
            endSequence = endRes.item;
          }
        }

        if (index !== newRows.length - 1) {
          let nextItem = sequences.find((e: any) => e.name === "NEXT" && e.from_item_id === t.id);
          if (!nextItem) {
            nextItem = newRows[index + 1];
            const nextRes = await createSeq(
              workflow_id,
              'NEXT',
              'Siguiente',
              t.task_id,
              nextItem.task_id,
              TargetType.TASK,
              TargetType.TASK,
            )
            if (nextRes.item) {
              sequences.push(nextRes.item);
            }
          }
        }
        if (index) {
          let backItem = sequences.find((e: any) => e.name === "BACK"
            && e.from_item_id === t.id);
          if (!backItem) {
            backItem = newRows[index - 1];
            const backRes = await createSeq(
              workflow_id,
              'BACK',
              'Atras',
              t.task_id,
              backItem.task_id,
              TargetType.TASK,
              TargetType.TASK,
            );
            if (backRes.item) {
              sequences.push(backRes.item);
            }
          }
        }
        return t;
      }));
    return {success: true}
  }
  return {success: false}
}

function sortSectionBySequences(sequences: any[], events: any[], array: Section[] | any[]) {
  try {
    const startEvent = events.find((e: any) => e.event_type === EventType.START);
    const startSequence: any = sequences
      .find((e: any) => e.from_item_id === startEvent.id);

    const getSeq = (id: number) => sequences
      .find(s => s.from_item_id === id && s.name !== "BACK");

    let newList: Section[] | any[] = [];

    function setDataToList(seq: any) {
      if (newList.length !== array.length) {
        const item = array
          .find((s: Section | any) => (s.task_id === seq.to_item_id || s.id === seq.to_item_id));
        if (item) {
          newList.push(item);
          setDataToList(getSeq(seq.to_item_id))
        }
      }
    }
    setDataToList(startSequence);
    return newList;
  } catch (e) {
    toast("La lista de secciones tiene problemas mueve las secuencias para corregir.", "warning")
    return array;
  }
}


export async function connectSequencesService(
  document: Document,
  section: Section,
): Promise<Response<any>> {
  try {
    const {success, item} = await getProcessModel(document.workflow_id);
    const {tasks, sequences: seq, events} = item;
    const sequences = new Array(...seq);
    if (success && item && section.key) {
      const startEvent = events.find((e: any) => e.event_type === EventType.START);
      const endEvent = events.find((e: any) => e.event_type === EventType.END);
      let startSequence = sequences.find((e: any) => e.from_item_id === startEvent.id);
      let endSequence = sequences.find((e: any) => e.to_item_id === endEvent.id);
      const sortTasks: any[] = sortSectionBySequences(sequences, events, tasks);

      await Promise.all(sortTasks
        .map(async (t: any, index: number) => {
          if (!startSequence && !index) {
            const startRes = await createSeq(
              document.workflow_id,
              'NEXT',
              'Siguiente',
              startEvent.id,
              t.id,
              TargetType.EVENT,
              TargetType.TASK,
            );
            if (startRes.item) {
              startSequence = startRes.item;
            }

          }
          if (!endSequence && index === (sortTasks.length - 1)) {
            const endRes = await createSeq(
              document.workflow_id,
              'NEXT',
              'Finalizar',
              t.id,
              endEvent.id,
              TargetType.TASK,
              TargetType.EVENT
            );
            if (endRes.item) {
              endSequence = endRes.item;
            }
          }

          if (index !== sortTasks.length - 1) {
            let nextItem = sequences.find((e: any) => e.name === "NEXT" && e.from_item_id === t.id);
            if (!nextItem) {
              nextItem = sortTasks[index + 1];
              const nextRes = await createSeq(
                document.workflow_id,
                'NEXT',
                'Siguiente',
                t.id,
                nextItem.id,
                TargetType.TASK,
                TargetType.TASK,
              )
              if (nextRes.item) {
                sequences.push(nextRes.item);
              }
            }
          }
          if (index) {
            let backItem = sequences.find((e: any) => e.name === "BACK"
              && e.from_item_id === t.id);
            if (!backItem) {
              backItem = sortTasks[index - 1];
              const backRes = await createSeq(
                document.workflow_id,
                'BACK',
                'Atras',
                t.id,
                backItem.id,
                TargetType.TASK,
                TargetType.TASK,
              );
              if (backRes.item) {
                sequences.push(backRes.item);
              }
            }
          }
          return t;
        }));
      return Promise.resolve({success: true})
    }
    throw Error;
  } catch (e) {
    return Promise.resolve({success: false})
  }
}

export async function deleteSectionService(
  document: Document,
  section: Section,
): Promise<Response<any>> {
  try {
    if (section.key) {
      const res = await deleteTaskService(document.workflow_id, section.task_id);
      if (res.success) {
        const resSec = await deleteSectionData(section.key);
        if (resSec.success) {
          return connectSequencesService(document, section);
        }
      }
    }
    throw Error;
  } catch (e) {
    return Promise.reject({success: false});
  }
}

export function deleteSectionData(key: string): Promise<Response<any>> {
  return sbxCoreService
    .with('section')
    .whereWithKeys([key])
    .delete();
}

export const renameSection = (
  name: string,
  description: string,
  key: string,
): Promise<Response<any>> => {
  return sbxCoreService
    .with('section')
    .update({name, description, _KEY: key});
};

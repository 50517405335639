import {sbxSessionService} from "../Network";

export const login = async (email: string, password: string) => {
  try {
    const res = await sbxSessionService.login(email, password);
    if (res) {
      return Promise.resolve(res);
    }
    return Promise.reject();
  } catch (e) {
    console.log(e)
  }
};

export const logout = () => {
  sbxSessionService.logout();
  localStorage.clear();
  window.location.href = 'http://app-dev.simplelegalapp.co/#/login';
};

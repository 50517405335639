import {all, call, put, select, take, takeEvery,} from '@redux-saga/core/effects';
import * as Services from '../../Services';
import {appendDocxHeaders, toast} from '../../Utils/Utils';
import {actions} from './Slice';
import {actionsCategory} from '../Category/Slice';
import Document from '../../Models/Document';
import {
  DELETE_FOLDER_LIST,
  GET_ADD_NEW_DOCUMENT,
  GET_ADD_NEW_FILE,
  GET_ADD_NEW_FOLDER,
  GET_FIRST_LOAD,
  GET_FOLDER_LIST,
} from './Types';
import {RootState} from '../Reducers';
import {PayloadAction} from '@reduxjs/toolkit';
import Category from '../../Models/Category';

function* getFolderList({payload}: any) {
  try {
    const res = yield call(Services.getDocuments, payload);
    if (res.success) {
      yield put(
        actions.setFolderList({content: res.contents, folder: res.folder}),
      );
    } else {
      throw new Error('Success false');
    }
  } catch (e) {
    yield put(actions.rejectAction());
    console.error(e.message);
  }
}

function* getFirstLoad({payload}: any) {
  try {
    yield put(actionsCategory.getCategories());
    yield take(actionsCategory.setCategories);
    const {list} = yield select((state: RootState) => state.categoryReducer);
    if (list.length) {
      yield put(actions.getFolderList(list[0].folder_key));
    }
  } catch (e) {
    // toast("Can't get this step", "error");
  }
}

function* getAddNewFolder({payload}: any) {
  try {
    const res = yield call(
      Services.createFolder,
      payload.parentKey,
      payload.name,
    );
    if (res.success) {
      yield put(actions.setAddNewFolder({folder: res.folder}));
      yield put(actions.getFolderList(res.folder.parent_key));
      toast('New folder created');
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
  }
}

function* getAddNewFile({payload}: any) {
  try {
    const file = new File([appendDocxHeaders('')], payload.name + '.html', {
      type: 'text/html',
    });
    const res = yield call(Services.uploadFile, file, payload.parentKey);
    if (res.success) {
      yield put(actions.setAddNewFolder({folder: res.folder}));
      yield put(actions.getFolderList(payload.parentKey));
      toast('New file created');
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
  }
}

function* getAddNewDocument({
                              payload,
                            }: PayloadAction<{ name: string; category: Category }>) {
  try {
    const list = yield select((state: RootState) => state.folderReducer.list);
    const res = yield call(
      Services.addNewDocument,
      payload.name,
      payload.category,
      list.length,
    );
    if (res.success) {
      yield put(actions.getFolderList(payload.category));
    } else throw Error;
  } catch (e) {
    toast("No se pudo crear el documento", "error");
  }
}

function* deleteFolderListSaga({
                                 payload: {key, category, folder_key},
                               }: PayloadAction<Document>) {
  try {
    const res: any = yield call(Services.deleteDocument, key);
    if (res.success) {
      yield put(
        actions.getFolderList({
          key: category,
          description: '',
          folder_key,
          name: '',
        }),
      );
    } else throw new Error('No se pudo eliminar este documento');
  } catch (e) {
    yield put(actions.rejectAction());
    toast(e.message || 'No se pudo eliminar este documento', 'error');
  }
}

export function* folderSaga(): Generator {
  yield all([
    takeEvery(GET_FOLDER_LIST, getFolderList),
    takeEvery(GET_ADD_NEW_FOLDER, getAddNewFolder),
    takeEvery(GET_ADD_NEW_FILE, getAddNewFile),
    takeEvery(GET_FIRST_LOAD, getFirstLoad),
    takeEvery(GET_ADD_NEW_DOCUMENT, getAddNewDocument),
    takeEvery(DELETE_FOLDER_LIST, deleteFolderListSaga),
  ]);
}

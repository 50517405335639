import {all, call, put, takeEvery} from 'redux-saga/effects';
import {PayloadAction} from "@reduxjs/toolkit";
import {
  finishTask,
  getPreview,
  getPropertiesService,
  getTaskExecDetail,
  launchProcess,
  saveTaskService
} from "../../Services";
import {toast} from "../../Utils/Utils";
import {FINISH_TASK, FinishTaskPayload, GET_PREVIEW_ITEM, GET_PROPERTIES, LAUNCH_PROCESS, SAVE_TASK} from "./Types";
import {actionsExecution} from "./Slice";

function* launchProcessSaga({payload}: PayloadAction<{ processId: number }>) {
  try {
    const data = yield call(launchProcess, payload.processId);
    if (data.success) {
      const processLaunch = data.item;
      const currentAssignment = processLaunch.current_assignments[0];
      if (currentAssignment?.id) {
        const res: any = yield call(
          getTaskExecDetail,
          currentAssignment.id,
          processLaunch.process_id,
        );
        if (res.success) {
          const execTask = res.item;
          yield put(actionsExecution.setCurrentTask(execTask));
        }
      }
    }
  } catch (e) {
    toast(e.message, 'error');
  }
}

function* finishTaskSaga({payload}: PayloadAction<FinishTaskPayload>) {
  try {
    const data = yield call(
      finishTask,
      payload.processExecId,
      payload.actionId,
      payload.formId,
      payload.fields,
    );
    if (data.success) {
      const nextTask = data.next_items[0];
      if (nextTask) {
        const res: any = yield call(
          getTaskExecDetail,
          nextTask.id,
          data.item.process_id,
        );
        if (res.success) {
          const execTask = res.item;
          yield put(actionsExecution.setCurrentTask(execTask));
        }
      } else {
        yield put(actionsExecution.finishProcess())
      }
    }
  } catch (e) {
    toast(e.message, 'error');
  }
}

function* getPreviewSaga({
                           payload: {processId, executionId, documentKey},
                         }: PayloadAction<{
  processId: number;
  executionId: number;
  documentKey: string;
}>) {
  try {
    const res = yield call(
      getPreview,
      processId,
      executionId,
      documentKey,
    );
    if (res.success) {
      yield put(actionsExecution.setPreviewItem(res.item));
    } else throw Error;
  } catch (e) {
    toast('No se pudo obtener la vista previa.', 'error');
    yield put(actionsExecution.rejectAction());
  }
}

function* saveTaskSaga({
                         payload: {taskId, fields},
                       }: PayloadAction<{ taskId: number; fields: any }>) {
  try {
    const res = yield call(saveTaskService, taskId, fields);
    if (res.success) {
      yield put(actionsExecution.setSaveTask());
    } else throw Error;
  } catch (e) {
    yield put(actionsExecution.rejectAction());
  }
}

function* getPropertiesSaga({
                              payload: {process_id, execution_id},
                            }: PayloadAction<{ process_id: number; execution_id: number }>) {
  try {
    const res = yield call(getPropertiesService, process_id, execution_id);

    if (res.success) {
      yield put(actionsExecution.setProperties(res.item.properties));
    } else throw Error;
  } catch (e) {
    yield put(actionsExecution.rejectAction());
  }
}


export function* ExecutionSaga() {
  yield all([
    //Auth sagas
    takeEvery(FINISH_TASK, finishTaskSaga),
    takeEvery(SAVE_TASK, saveTaskSaga),
    takeEvery(GET_PREVIEW_ITEM, getPreviewSaga),
    takeEvery(LAUNCH_PROCESS, launchProcessSaga),
    takeEvery(GET_PROPERTIES, getPropertiesSaga)
  ]);
}

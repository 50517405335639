import React, {useState} from 'react';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem} from 'reactstrap';
import icon from '../../icon.svg';
import {faAlignLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {logout} from "../../Services";

const NavbarComponent = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar style={{height: "6vh"}} color="white" light expand="md">
      <div className="px-3 d-inline-flex justify-content-between">
        <NavbarBrand href="http://simplelegalapp.co">
          <img src={icon} className="logo" alt="" style={{height: '50px'}}/>
        </NavbarBrand>
        <span className="align-self-center clickable" onClick={props.toggle}>
          <FontAwesomeIcon color="#383eb3" icon={faAlignLeft}/>
        </span>
      </div>
      <NavbarToggler onClick={toggle}/>
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          {/*<NavItem>*/}
          {/*  <NavLink href="#/">Listado de documentos</NavLink>*/}
          {/*</NavItem>*/}
          {/*<NavItem>*/}
          {/*  <NavLink href="#/categories">Listado de categorias</NavLink>*/}
          {/*</NavItem>*/}
          <NavItem className="list-unstyled navbar-menu-text navbar-menu-text-up mb-3">
            <button className="btn btn-danger btn-sm px-3" onClick={() => logout()}>Salir</button>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarComponent;

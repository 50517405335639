import React from "react";

interface props {
	className?: string;
	style?: any;
}

const SpinnerComponent = ({className, style}:props) => {
	return(
			<div className={`spinner-border ${className ? className : ''}`} style={style}>
				<span className="sr-only">Loading...</span>
			</div>
	);
};

export default SpinnerComponent;

import React from 'react';
import {Draggable, Droppable} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsAltH, faArrowsAltV, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import CreateFieldPreview from "../../Modals/CreateFieldModal/CreateFieldPreview";
import {useDispatch} from "react-redux";
import {actions} from "../../../Store/FormBuilder/Slice";
import IField from "../../../Models/FormBuilder/IField";
import Form from "../../../Models/FormBuilder/Form";
import {LabelComponent} from "sbx-ui-input-library";
import FieldType from "../../../Models/FormBuilder/FieldType";

type Props = {
  form: Form,
  row?: boolean,
  label?: string
}

const FormBuilderList: React.FC<Props> = ({form, row, label}) => {
  const dispatch = useDispatch();
  const height = row ? 'calc(100vh - 600px)' : 'calc(100vh - 280px)';

  const onDelete = (field: IField) => {
    if (form.id && field.id) {
      dispatch(actions.getDeleteField({formId: form.id, field: field}));
    }
  }

  const onEdit = (field: IField) => {
    if (form.id && field.id) {
      if ((field.field_type === FieldType.TABLE || field.field_type === FieldType.FORM_GROUP) && field.detail_form_id) {
        dispatch(actions.getChildForm({childFormId: field.detail_form_id, parentFormId: form.id}));
        dispatch(actions.updateParentField({field, openModal: false}));
      } else {
        dispatch(actions.setCurrentField(field));
      }
    }
  }


  const getBody = (field: IField) => {
    return <CreateFieldPreview field={field} hiddenLabel={Boolean(row)} valueNewRow={null}/>;
  }

  return (
    <Droppable droppableId={`form-${form.id}`} type="INPUT" direction={row ? 'horizontal' : 'vertical'}>
      {(provided, snapshot) => {
        return <>
          {
            label && <LabelComponent>{label}</LabelComponent>
          }
          <div ref={provided.innerRef}
               {...provided.droppableProps}>
            <div className={`mx-auto py-3 shadow-sm d-flex ${row ? 'flex-row' : 'flex-column'}`} style={
              {
                height, overflowY: 'auto',
                border: '1px dashed #D8DCE1',
                backgroundColor: snapshot.isDraggingOver ? '#E2E6EB' : '#ECF0F5'
              }
            }>
              {
                form.fields.map((field, index) => (
                  <Draggable key={field.key} draggableId={field.id + index.toString()}
                             index={index}>
                    {(provided, snapshot) => (

                      <div className="card m-2" ref={provided.innerRef} {...provided.draggableProps}>
                        <div className="card-header py-1 px-2 d-flex justify-content-between">
                          <span>{field.name}</span>
                          <div>
                            <i className="ml-2" onClick={() => onEdit(field)}>
                              <FontAwesomeIcon icon={faEdit} className="clickable"/>
                            </i>
                            <i className="ml-2 text-danger" onClick={() => onDelete(field)}>
                              <FontAwesomeIcon icon={faTrash} className="clickable"/>
                            </i>
                            <i {...provided.dragHandleProps} className="ml-2 ">
                              <FontAwesomeIcon icon={row ? faArrowsAltH : faArrowsAltV}/>
                            </i>

                          </div>
                        </div>
                        <div className="card-body">
                          {getBody(field)}
                        </div>
                      </div>

                    )}
                  </Draggable>)
                )
              }
            </div>
            {
              provided.placeholder
            }
          </div>
        </>;
      }}
    </Droppable>
  );
};

export default FormBuilderList;

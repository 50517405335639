import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import * as Services from '../../Services';
import {deleteSectionService, getSectionService} from '../../Services';
import {actions} from './Slice';
import {ADD_SECTION, DELETE_SECTION, GET_EDIT_DOCUMENT, GET_SECTIONS,} from './Types';
import {PayloadAction} from '@reduxjs/toolkit';
import Document from '../../Models/Document';
import {toast} from '../../Utils/Utils';
import Section from '../../Models/Section';
import {RENAME_SECTION} from '../Section/Types';

function* getSections({payload}: any) {
  try {
    const res = yield call(Services.getSections, payload);
    if (res.success) {
      yield put(
        actions.setSections({content: res.contents, workflow: res.document}),
      );
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
    console.error(e);
  }
}

function* addSection({
                       payload,
                     }: PayloadAction<{
  name: string;
  document: Document;
  description: string;
  prevSec?: Section;
}>) {
  try {
    const res = yield call(
      Services.addSection,
      payload.name,
      payload.document,
      payload.description,
      payload.prevSec,
    );
    if (res.success && payload.document.key) {
      yield put(actions.getSections(payload.document.key));
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
    console.error(e);
  }
}

function* editDocument({payload}: PayloadAction<Document>) {
  try {
    const res = yield call(Services.editDocument, payload);
    if (res.success) {
      toast('Documento Actualizado');
      yield put(actions.setEditDocument(payload));
    }
  } catch (e) {
    // toast("Can't get this step", "error");
  }
}

function* renameSectionSaga({
                              payload: {name, description, key, workFLowId},
                              type,
                            }: {
  type: string;
  payload: {
    name: string;
    description: string;
    key: string;
    workFLowId: string;
  };
}) {
  try {
    const res = yield call(Services.renameSection, name, description, key);
    if (res.success) {
      yield put(actions.getSections(workFLowId));
    } else {
      throw new Error("Can't rename this section.");
    }
  } catch (e) {
    yield put(actions.rejectAction());
    toast(e.message, 'error');
  }
}

function* deleteSectionSaga({
                              payload: {document, task, workflowId},
                            }: PayloadAction<{ document: Document; task: Section; workflowId: string }>) {
  try {
    const res = yield call(deleteSectionService, document, task);
    if (res.success) {
      yield put(actions.getSections(workflowId));
      toast('Sección eliminada.');
    } else throw Error;
  } catch (e) {
    yield put(actions.rejectAction());
    toast('No se pudo eliminar esta sección.', 'error');
  }
}

function* getSectionDetailSaga({
                                 payload,
                               }: PayloadAction<{ document: string; section: string }>) {
  try {
    const res = yield call(
      getSectionService,
      payload.section,
      payload.document,
    );
    if (res.success) {
      yield put(actions.setSection(res));
    } else throw Error;
  } catch (e) {
    yield put(actions.rejectAction());
    toast('No se pudo cargar la sección.', 'error');
  }
}


export function* Saga(): Generator {
  yield all([
    takeEvery(GET_SECTIONS, getSections),
    takeEvery(ADD_SECTION, addSection),
    takeEvery(GET_EDIT_DOCUMENT, editDocument),
    takeEvery(RENAME_SECTION, renameSectionSaga),
    takeEvery(DELETE_SECTION, deleteSectionSaga),
    takeEvery('section/getSection', getSectionDetailSaga),
  ]);
}

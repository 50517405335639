import React, {useEffect, useState} from "react";
import {Label} from "reactstrap";
import Select from "react-select";
import {conditions, formatDate, getDefaultValue} from "../../../Utils/Utils";
import {Opt, RuleData} from "./RuleComponent";
import IField from "../../../Models/FormBuilder/IField";
import {useSelector} from "react-redux";
import {RootState} from "../../../Store/Reducers";
import {useParams} from "react-router-dom";
import CreateFieldPreview from "../../Modals/CreateFieldModal/CreateFieldPreview";
import {FieldType} from "../../../Models/FieldTypes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {smallTextField} from "../../Main/FormBuilder/Fields";
import OptionDetail from "../OptionDetail";

interface Props {
  rules: RuleData[];
  setRules: (e: RuleData[]) => void;
  fields: IField[];
  subFields: IField[];
  field: Opt | null;
  setField: (e: any) => void;
  subField: Opt | null;
  setSubField: (e: any) => void;
  getFields: (e: any) => void;
  loading: boolean;
  section: Opt | null;
  visibleCurrentSection?: boolean;
}

const AddRule = ({
                   rules,
                   fields,
                   field,
                   setRules,
                   getFields,
                   loading,
                   section,
                   subFields,
                   setField,
                   subField,
                   setSubField,
                   visibleCurrentSection
                 }: Props) => {

  const {sectionId} = useParams();
  const {
    sectionReducer: {
      list
    }
  } = useSelector((s: RootState) => s);

  const [condition, setCondition] = useState<any>();
  const [value, setValue] = useState<any>(null);
  const [operator, setOperator] = useState<boolean>(false);

  useEffect(() => {
    if (!field) {
      setOperator(false)
      setCondition(null);
    }
  }, [field]);

  function addRule(fieldData: IField) {
    if (condition?.value) {
      const rs = getRow(fieldData);
      setRules(Array.isArray(rs) ? [...rules, ...rs] : [...rules, rs]);

      function getRow(field: any): RuleData | RuleData[] {
        let r: RuleData = {
          field,
          condition: condition.value,
          value: "",
          operator: rules.length ? (operator ? '&&' : '||') : undefined,
        }

        function getOperator(index: number) {
          return !index && !rules.length ? undefined : (operator ? '&&' : '||');
        }

        if (value?.data || value) {
          switch (field.field_type) {
            case "DATE":
              return Object.assign(r, {value: formatDate(value)});
            case "OPTIONS":
              if (field.single_value) {
                return Object.assign(r, {value: value.value})
              } else {
                return value.map((v: any, index: number) => {
                    return {
                      ...r, value: v.value,
                      operator: getOperator(index)
                    }
                  }
                )
              }
            default:
              return Object.assign(r, {value});
          }
        } else {
          return r;
        }
      }

      setField(null);
      setCondition(null);
      setOperator(false);
      setValue(null);
    }
  }

  const groupFields = (field?.data.field_type === FieldType.table ||
    field?.data.field_type === FieldType.formGroup);

  const NField = subField || field;

  return (
    <div className="row">
      <div className="col-2"><Label>Secciones</Label></div>
      <div className="col-2"><Label>Campos</Label></div>
      {groupFields && <div className="col-2">Sub campos</div>}
      <div className="col-2"><Label>Condición</Label></div>
      <div className={`${groupFields ? "col-2" : "col-4"}`}><Label>Valor</Label></div>
      <div className={`${groupFields ? "col-2" : "col-2"}`}/>

      <div className="col-2 d-flex align-items-center">
        <div className="w-100 form-group">
          <Select
            isDisabled={loading}
            value={section}
            onChange={(e: any) => getFields(e)}
            options={(visibleCurrentSection ? list : list.filter(l => l.key !== sectionId))
              .map(l => ({label: l.name, value: l.form_id, data: l}))}/>
        </div>
      </div>
      <div className="col-2 d-flex align-items-center">
        <div className="w-100 form-group">
          <Select value={field}
                  isDisabled={loading}
                  components={{Option: OptionDetail}}
                  onChange={(e: any) => {
                    setValue(getDefaultValue(e))
                    setField(e);
                  }}
                  options={fields.filter(f => f.field_type !== FieldType.dateRange && f.field_type).map((l: any) => ({
                    label: l.label,
                    value: l.id,
                    data: l
                  }))}/>
        </div>
      </div>

      {
        groupFields && (
          <div className="col-2 d-flex align-items-center">
            <div className="form-group w-100">
              <Select
                value={subField}
                isDisabled={loading}
                components={{Option: OptionDetail}}
                options={subFields
                  .filter((f: IField) => f.field_type !== FieldType.dateRange
                    && f.field_type !== FieldType.table && f.field_type !== FieldType.formGroup)
                  .map((l: any) => ({
                    label: l.label,
                    value: l.id,
                    data: l
                  }))}
                onChange={setSubField}
              />
            </div>
          </div>
        )
      }
      <div className="col-2 d-flex align-items-center">
        <div className="w-100 form-group">
          <Select
            isDisabled={loading}
            value={condition}
            onChange={e => setCondition(e)}
            options={conditions.filter(c => {
              if ((NField?.data.sub_type !== "NUMBER" && NField?.data.sub_type !== "CURRENCY") &&
                (c.value === " < " || c.value === " > " || c.value === " <= " || c.value === " >= ")) {
                return false
              } else {
                return c
              }
            })}
          />
        </div>
      </div>
      <div className={`${groupFields ? "col-2" : "col-4"} d-flex align-items-center`}>
        <CreateFieldPreview hiddenLabel
                            valueNewRow={null}
                            className="w-100"
                            field={(groupFields ? subField?.data : field?.data) || smallTextField}
                            value={value || ""}
                            onChange={setValue}/>
      </div>

      <div className={`${groupFields ? "col-2" : "col-2"} px-0 d-flex align-items-center`}>
        <div className="form-group w-100 text-center">
          <button className="btn btn-info btn-sm" onClick={() => {
            if (subField) {
              addRule(subField.data);
            } else if (field) {
              addRule(field.data);
            }
          }}>
            <FontAwesomeIcon icon={faPlusCircle}/> {" Condición"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddRule;

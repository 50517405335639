import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import ProcessExecutionTask from "../../Components/Main/ExecutionFlowComponent/Models/ProcessExecutionTask";
import {FinishTaskPayload, FormExecutionState, State} from "./Types";

const initialState: FormExecutionState = {
  state: State.IDLE,
  previewItem: '',
  properties: {},
};

const folderReducer = createSlice({
  name: 'form_execution',
  initialState,
  reducers: {
    getLaunchProcess(state, action: PayloadAction<{ processId: number }>) {
      state.state = State.PENDING;
    },
    setCurrentTask(state, action: PayloadAction<ProcessExecutionTask>) {
      state.state = State.RESOLVED;
      state.task = action.payload;
    },
    getFinishTask(state, action: PayloadAction<FinishTaskPayload>) {
      state.state = State.PENDING;
    },
    setFinishTask(state, action: PayloadAction<ProcessExecutionTask>) {
      state.state = State.RESOLVED;
      state.task = action.payload;
    },
    getSaveTask(
      state,
      action: PayloadAction<{ taskId: number; fields: any[] }>,
    ) {
      state.state = State.PENDING;
    },
    setSaveTask(state, action: PayloadAction) {
      state.state = State.PROCESS_FINISHED;
    },
    finishProcess: (state, action: PayloadAction) => ({
      ...state,
      state: State.PROCESS_FINISHED,
    }),
    getPreviewItem(
      state,
      action: PayloadAction<{
        processId: number;
        executionId: number;
        documentKey: string;
      }>,
    ) {
      state.state = State.PENDING_PREVIEW;
    },
    setPreviewItem(state, action: PayloadAction<string>) {
      state.state = State.RESOLVED;
      state.previewItem = action.payload;
    },
    rejectAction(state) {
      state.state = State.REJECTED;
    },
    getProperties(
      state,
      action: PayloadAction<{ process_id: number; execution_id: number }>,
    ) {
      state.state = State.PENDING;
    },
    setProperties(state, action: PayloadAction<{ [key: string]: any }>) {
      state.state = State.RESOLVED;
      state.properties = action.payload;
    }
  }
});


export const actionsExecution = folderReducer.actions;

export default folderReducer.reducer

import React, {CSSProperties, useEffect, useState} from "react";
import {Option} from "react-select/src/filters";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import IField from "../Models/IField";
import {checkMail} from "../Utils";
import LabelComponent from "../Shared/LabelComponent";
import ValidateFieldRequired from "../Shared/ValidateFieldRequired";
import SmallTextComponent from "./SmallTextComponent";
import {getFieldByField} from "./index";

const defaultVal = "No hay datos";

function getValue(field: any, value: any) {
  switch (field.sub_type || field.field_type) {
    case "SELECT":
    case 'TOGGLE':
      return value ? field.single_value ? value.value : <ul>
        {value.map((v: Option) => <li key={v.value}>{v.value}</li>)}
      </ul> : defaultVal;
    case "DATE":
      return value ? new Date(value).toDateString() : defaultVal;
    case "DATE_RANGE":
      return (value && value.startDate && value.endDate) ? `${new Date(value.startDate).toDateString()} -> ${new Date(value.endDate).toDateString()}` : defaultVal;
    case "FILE":
      if (field.single_value) return value ? value.name : defaultVal;
      else return value ? <ul>
        {value.map((v: File) => <li key={v.name}>{v.name}</li>)}
      </ul> : defaultVal;
    case "EMAIL":
      return value ? (checkMail(value) ? value : defaultVal) : defaultVal;
    default:
      return value || defaultVal;
  }
}

interface IProps {
  id: string,
  name: string,
  value: { [key: string]: any },
  fields: IField[];
  invalid?: boolean,
  required?: boolean,
  readonly?: boolean,
  disabled?: boolean,
  style?: CSSProperties,
  placeholder?: string,
  className?: string,
  iconDelete?: React.ReactNode;
  iconEdit?: React.ReactNode;
  onChange: (value: { [key: string]: any }) => void;
  format_rules_definition?: any;
  subType?: "LIST" | null;
  header?: IField[],
  onChangeCurrentRow?: (value: any) => void;
  getCurrentRow?: (row: string) => void;
  onDeleteRow?: (key: string) => void;
  valueNewRow?: { [key: string]: any },
}

const TableComponent = ({
                          fields,
                          className,
                          value,
                          onChange,
                          subType,
                          iconDelete,
                          iconEdit,
                          disabled,
                          required,
                          format_rules_definition = {columns: []},
                          onChangeCurrentRow,
                          getCurrentRow,
                          header,
                          onDeleteRow,
                          valueNewRow,
                        }: IProps) => {

  function deleteRow(key: string) {
    let newVal = Object.assign({}, value);
    delete newVal[key];
    if (onDeleteRow) {
      onDeleteRow(key);
    }
    onChange(newVal);
  }

  return (
    <ValidateFieldRequired
      value={Object.values(value).length}
      required={required}
      disabled={disabled}>
      <TableContent fields={fields}
                    valRow={valueNewRow}
                    onChangeCurrentRow={onChangeCurrentRow}
                    onChange={onChange}
                    getCurrentRow={getCurrentRow}
                    values={value}
                    header={header}
                    disabled={disabled}
                    subType={subType}
                    iconDelete={iconDelete}
                    iconEdit={iconEdit}
                    columns={format_rules_definition.columns}
                    className={className}
                    rows={Object.keys(value).map(e => ({value: value[e], key: e}))}
                    deleteRow={deleteRow}/>
    </ValidateFieldRequired>
  )
}


export default TableComponent;

interface ContentProps {
  fields: IField[];
  rows: any[];
  columns: string[];
  disabled?: boolean;
  values: { [key: string]: any };
  onChange: (value: { [key: string]: any }) => void
  deleteRow: (row: any) => void;
  className?: string;
  iconDelete?: React.ReactNode;
  iconEdit?: React.ReactNode;
  subType?: "LIST" | null;
  onChangeCurrentRow?: (value: any) => void;
  getCurrentRow?: (row: string) => void;
  header?: IField[];
  valRow?: { [key: string]: any }
}

const TableContent = ({
                        fields,
                        rows,
                        deleteRow,
                        values,
                        onChange,
                        columns,
                        subType,
                        iconDelete,
                        iconEdit,
                        disabled,
                        className = "table",
                        onChangeCurrentRow,
                        getCurrentRow,
                        header: head,
                        valRow,
                      }: ContentProps): JSX.Element => {

  const [open, setOpen] = useState<{ open: boolean, row: any, key: string }>({open: false, row: {}, key: ""});

  const header = (head || fields).filter(h => h.sub_type !== "PASSWORD" &&
    (!columns.length || columns.find(c => c === h.name)));

  const ButtonModal = () => {
    return (
      <div className="text-right p-2">
        <button
          disabled={disabled}
          className="btn btn-light"
          type="button"
          onClick={() => {

            const num = Object.keys(values)
              .map(value => parseInt(value.replace("row_", "")))
              .sort((a, b) => a - b).pop();

            let newRowKey = "row_" + ((num === undefined ? -1 : num) + 1);

            const r = {
              open: true,
              row: {},
              key: newRowKey
            }
            setOpen(r);
            if (getCurrentRow) {
              getCurrentRow(r.key);
            }
          }}>
          Agregar
        </button>
      </div>
    )
  }

  return (
    <>
      {subType ? (
        <div>
          {rows.map((row: { value: any, key: string }, indexRow) => <div key={indexRow}>
            <div className="d-flex align-items-center">
              <b>{"Lista " + (indexRow + 1)}</b>
              <button
                type="button"
                onClick={() => {
                  setOpen({open: true, row: row.value, key: row.key});
                  if (getCurrentRow) {
                    getCurrentRow(row.key);
                  }
                }}
                className="btn btn-link text-primary btn-sm mr-1">
                {iconEdit || "Editar"}
              </button>
              <button
                type="button"
                onClick={() => deleteRow(row.key)}
                className="btn btn-link text-danger btn-sm">
                {iconDelete || "Eliminar"}
              </button>
            </div>
            <ul>
              {header.map((field: IField, indexField) => <li
                key={indexField}>{getValue(field, row.value[field.id])}</li>)}
            </ul>
          </div>)}
        </div>
      ) : (
        <div className="table-responsive">
          <table className={className}>
            <thead>
            <tr>
              {header.map((r, index) => <th key={index} style={{minWidth: "180px"}}>{r.label}</th>)}
              <th className="action-th" style={{minWidth: "200px"}}>{" "}</th>
            </tr>
            </thead>
            <tbody>
            {rows.map((row: { value: any, key: string }, indexRow) => <tr key={indexRow}>
              {header.map((field: IField, indexField) => <td
                key={indexField}>{getValue(field, row.value[field.id])}</td>)}
              <td className="text-right">
                <button type="button"
                        onClick={() => {
                          setOpen({open: true, row: row.value, key: row.key});
                          if (getCurrentRow) {
                            getCurrentRow(row.key);
                          }
                        }}
                        className="btn btn-primary btn-sm mr-2">{iconEdit || "Editar"}
                </button>
                <button type="button" onClick={() => deleteRow(row.key)}
                        className="btn btn-danger btn-sm">{iconDelete || "Eliminar"}
                </button>
              </td>
            </tr>)}
            </tbody>
          </table>
        </div>
      )}
      {!rows.length && (
        <div className="text-center">
          <small>
            <b>No hay datos</b>
          </small>
        </div>
      )}

      <ButtonModal/>
      <AddNewRowModal open={open}
                      valRow={valRow}
                      fields={fields}
                      onChangeRow={(e: any) => {
                        if (onChangeCurrentRow) {
                          onChangeCurrentRow(e);
                        }
                      }}
                      onClose={e => {
                        setOpen({open: e, row: {}, key: ""});
                        if (getCurrentRow) {
                          getCurrentRow("");
                        }
                      }}
                      header="Agregar fila"
                      onSuccess={row => {
                        onChange({...values, [open.key]: row});
                        if (getCurrentRow) {
                          getCurrentRow("");
                        }
                      }}/>
    </>
  )
}

interface ModalProps {
  open: { open: boolean, row: any, key: string };
  onClose: (close: boolean) => void;
  header: React.ReactNode;
  fields: IField[];
  onSuccess: (row: any) => void;
  onChangeRow: (values: any) => void;
  valRow?: { [key: string]: any }
}

const AddNewRowModal = ({open, onClose, header, fields, onSuccess, onChangeRow, valRow: vr}: ModalProps) => {

  const [valRow, setValRow] = useState<any>(vr || {});
  const [error, setError] = useState<any>({});

  useEffect(() => {
    if (open.open) {
      setValRow(open.row);
      setError({});
    }
  }, [open]);

  useEffect(() => {
    if (vr) {
      setValRow((e: any) => {
        if (e !== vr) {
          return vr;
        }
        return e;
      });
    }
  }, [vr])

  function validateData() {
    let errorFields: any = {}
    const validate = fields.filter(field => field.required).filter(field => {
      const result = getValue(field, valRow[field.id]) === defaultVal;
      if (result) errorFields[field.id] = true;
      return result;
    });
    if (!validate.length) {
      onSuccess(valRow);
      onClose(false);
    } else {
      setError(errorFields);
    }
  }

  const classCol = fields.length >= 3 ? "col-lg-4 col-md-6 col-12" : fields.length === 2 ? "col-md-6 col-12" : "col-12";

  return (
    <Modal size="xl" isOpen={open.open} toggle={() => onClose(false)}>
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            {fields.map((f: IField, index: number) => {
              const component = getFieldByField(f);
              if (!component.field) {
                component.field = SmallTextComponent;
              }
              return (
                <div className={classCol} key={index}>
                  <div className="w-100">
                    <LabelComponent {...f.labelProps}>{f.label}</LabelComponent>
                    <component.field {...f}
                                     id={f.id}
                                     value={valRow[f.id] || component.defaultValue}
                                     selected={valRow[f.id] || component.defaultValue}
                                     onChange={(e: any) => {
                                       setValRow({...valRow, [f.id]: e});
                                       onChangeRow({value: e, field: f});
                                     }}/>
                    <small className="text-danger py-1">{error[f.id] ? component.errorDescription : ""}</small>
                  </div>
                </div>
              )
            })}
            {!fields.length && <div className="p-5 w-100 text-center">
              <h5>No Fields</h5>
            </div>}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={validateData} className="btn btn-primary btn-sm">
          Guardar
        </button>
      </ModalFooter>
    </Modal>
  )
}

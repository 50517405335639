import {Rule} from "../../Models/Rule";

export const ADD_NEW_RULE = "rule/addNewRule";
export const GET_RULES_BY_DOCUMENT = "rule/getRuleByDocument";
export const DELETE_RULE = "rule/deleteRule";

export enum StateReducer {
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
  LOADING = "loading"
}


export interface RuleState {
  list: Rule[];
  state: string;
}

import React, {useEffect} from 'react';
import {Route, RouteComponentProps, Switch, useParams,} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {actions} from '../../../Store/Section/Slice';
import DocumentDetailComponent from './DocumentDetailComponent';
// import FormDetail from "../FormBuilder/FormDetail";

const DocumentDetail = ({match}: RouteComponentProps): React.ReactElement => {
  const reduxDispatch = useDispatch();
  const {workflowId, sectionId} = useParams<{ [key: string]: string }>();

  useEffect(() => {
    if (sectionId && workflowId) {
      reduxDispatch(
        actions.getSection({section: sectionId, document: workflowId}),
      );
    }
    return () => {
      reduxDispatch(actions.restart());
    };
  }, [reduxDispatch, sectionId, workflowId]);


  console.log(match)
  return (
    <Switch>
      {/*<Route component={FormDetail} path={`${match.path}/form/:formId`} exact/>*/}
      <Route component={DocumentDetailComponent} path={`${match.path}`} exact/>
    </Switch>
  );
};

export default DocumentDetail;


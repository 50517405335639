import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, ModalFooter, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faEye,
  faFile,
  faFileDownload,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import {Link, useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../Store/Reducers";
import {actions} from "../../../Store/Section/Slice";
import {capitalize} from "@material-ui/core";
import {actionsExecution} from "../../../Store/ExecutionFlow/Slice";
import {State} from "../../../Store/ExecutionFlow/Types";
import {convertStringToPdf, filterFields, getValuesFields} from "../../../Utils/Utils";
import Section from "../../../Models/Section";
import ModalComponent from "../../Shared/ModalComponent";
import IField from "../../../Models/FormBuilder/IField";
import CreateFieldPreview from "../../Modals/CreateFieldModal/CreateFieldPreview";

declare var document: Document;

function activeSection(section: Section, task_id: number) {
  if (section.key && section.task_id === task_id) {
    const elm = document.getElementById("section" + section.task_id);
    if (elm) {
      elm.scrollIntoView(true);
    }
    return "active-section";
  }
  return "";
}

const ExecutionFlowComponent = () => {
  const {workflowId} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    sectionReducer: {document: docData, list, state: loadingSectionData},
    executionReducer: {task, state: loadingExecData, previewItem, properties},
  } = useSelector((state: RootState) => state);

  const [values, setValues] = useState<any>({});
  const [previewModal, setPreviewModal] = useState(false);
  const [finished, setFinished] = useState(false);
  const [rowValue, setRowValue] = useState<any>({});
  const [currentRow, setCurrentRow] = useState<any>({});
  const [valueCurrentRow, setValueCurrentRow] = useState({});
  useEffect(() => {
    if (workflowId) {
      dispatch(actions.getSections(workflowId));
    }
  }, [workflowId, dispatch]);

  useEffect(() => {
    if (docData) {
      dispatch(actionsExecution.getLaunchProcess({processId: docData.workflow_id}))
    }
  }, [docData, dispatch])

  useEffect(() => {
    if (task) {
      dispatch(actionsExecution.getProperties({
        process_id: task.process_id,
        execution_id: task.process_execution_id
      }))
    }
  }, [dispatch, task])

  const back = task?.actions.find(a => a.name === "BACK");
  const next = task?.actions.find(a => a.name === "NEXT");
  const finish = task?.actions.find(a => a.name === "NEXT" && a.to_item_type === "EVENT");
  const loadingForm = loadingExecData === State.PENDING;
  const loadingPreview = loadingExecData === State.PENDING_PREVIEW;


  const submit = (actionId: number, fields: any[], action?: "back") => {
    if (task) {
      if (!finish || action === "back") {
        dispatch(
          actionsExecution.getFinishTask({
            formId: task.attachments[0].target_item_id,
            actionId,
            fields,
            processExecId: task.id,
          }),
        );
      } else {
        dispatch(
          actionsExecution.getSaveTask({taskId: task.id, fields}),
        );
      }
    }
  };

  useEffect(() => {
    if (loadingExecData === State.PROCESS_FINISHED) {
      setPreviewModal(true);
      setFinished(true);
    }
  }, [loadingExecData]);

  useEffect(() => {
    if (docData && task && previewModal) {
      dispatch(
        actionsExecution.getPreviewItem({
          documentKey: docData.key,
          processId: docData.workflow_id,
          executionId: task.process_execution_id || 0,
        }),
      );
    }
  }, [previewModal, docData, task, dispatch]);

  let fields: IField[] = [];

  if (task) {
    fields = task.attachments[0].form.fields;
  }

  function NIField(field: IField, nValues: any, rv: any) {
    let detail_form = field.detail_form;
    if (detail_form?.fields.length) {
      let val: any = {};
      detail_form.fields.forEach(f => {
        switch (field.field_type) {
          case "TABLE":
            if (rv[`${field.id}`] && rv[`${field.id}`][currentRow[`${field.id}`]] && rv[`${field.id}`][currentRow[`${field.id}`]][f.id + ""]) {
              val[`${f.id}`] = {value: rv[`${field.id}`][currentRow[`${field.id}`]][f.id + ""].value, field: f};
            }
            break;
          default:
            if (nValues[`${field.id}`]?.value && nValues[`${field.id}`].value[`${f.id}`]) {
              val[`${f.id}`] = {value: nValues[`${field.id}`].value[`${f.id}`], field: f}
            }
            break;
        }
      });
      detail_form = {
        ...detail_form,
        fields: filterFields(detail_form.fields, val, properties)
      }
    }
    return {...field, detail_form}
  }

  function validateValues(field: IField) {
    const cr = currentRow[`${field.id}`];
    const rv = rowValue[`${field.id}`]
    if (cr && rv && rv[cr] && field.detail_form) {
      const deleteFields = Object.keys(rv[cr])
        .filter(e => !field.detail_form?.fields
          .find(f => `${f.id}` === e));

      const _nov = Object.assign({}, rv);

      if (deleteFields.length) {
        deleteFields.forEach(e => {
          delete _nov[cr][e];
        });
        const _t = Object.keys(_nov[cr])
          .reduce((e: any, a) => {
            e[a] = _nov[cr][a].value;
            return e;
          }, {})
        setValueCurrentRow(_t || {});
      }
    }
  }

  return <div>
    {loadingSectionData === State.PENDING && (
      <p>Cargando datos...</p>
    )}
    <Card>
      <CardHeader className="d-flex justify-content-start align-items-center">
        <Link
          to={`/sections/${workflowId}`}
          className="btn btn-primary btn-sm w-auto mr-2">
          <FontAwesomeIcon icon={faArrowLeft}/>
        </Link>
        <h3 className="m-0">{capitalize(docData?.name || "")}</h3>
      </CardHeader>
      <CardBody style={{height: "calc(100vh - 165px)"}}>
        <form className="h-100" onSubmit={e => {
          e.preventDefault();
          if (next) {
            submit(next.id, getValuesFields(values))
          }
        }}>
          <Row className="h-100">
            <Col md="4" className="h-100">
              <div className="overflow-auto bg-light p-2 h-90 custom-scroll custom-scroll-light">
                <ListGroup flush className="shadow-sm">
                  {list.map(sec => <ListGroupItem id={"section" + sec.task_id}
                                                  className={activeSection(sec, task?.task_ref.task_id || 0)}
                                                  key={sec.key}>{sec.name}</ListGroupItem>)}
                </ListGroup>
              </div>
              <div className="h-10 bg-white shadow-sm d-flex justify-content-between align-items-center px-2">
                <button onClick={() => back && submit(back.id, getValuesFields(values), "back")}
                        type="button"
                        disabled={!back || loadingForm} className="btn btn-light">
                  <FontAwesomeIcon className="mr-2" icon={faAngleLeft}/>
                  {back?.label || "Atras"}
                </button>

                <button
                  type={"button"}
                  onClick={() => {
                    setPreviewModal(true);
                  }}
                  disabled={loadingForm}
                  className="btn btn-info">
                  <FontAwesomeIcon icon={faEye} className="mr-1"/>
                  Vista previa
                </button>

                <button disabled={(!next && !finish) || loadingForm} className="btn btn-primary">
                  {finish?.label || next?.label || "Siguiente"}
                  <FontAwesomeIcon className="ml-2" icon={faAngleRight}/>
                </button>
              </div>
            </Col>
            <Col md="8" className="h-100 overflow-auto custom-scroll custom-scroll-light">
              {!loadingForm && task ? (
                <>
                  {(filterFields(fields, values, properties))
                    .map(field => {
                        const nField: any = Object.assign({}, NIField(field, values, rowValue));
                        validateValues(nField);
                        return <CreateFieldPreview
                          valueNewRow={valueCurrentRow}
                          onChangeCurrentRow={e => {
                            const cvr = rowValue[nField.id] ? rowValue[nField.id][currentRow[nField.id]] : {}
                            setRowValue({
                              ...rowValue,
                              [nField.id]: {
                                ...rowValue[nField.id],
                                [currentRow[nField.id]]: {
                                  ...cvr,
                                  [e.field.id]: e
                                }
                              }
                            })
                          }}
                          header={field.detail_form?.fields}
                          getCurrentRow={setCurrentRow}
                          key={field.id}
                          field={nField}
                          value={values[field.id + ""]?.value}
                          onChange={e => setValues({...values, [field.id + ""]: {value: e, field}})}/>
                      }
                    )}
                </>
              ) : (
                <p>Cargando formulario...</p>
              )}
            </Col>
          </Row>
        </form>

      </CardBody>
    </Card>
    <ModalComponent
      size="lg"
      noFooter
      header={(
        <>
          <FontAwesomeIcon icon={faFile} className="mr-2"/> {docData?.name}
        </>
      )}
      isOpen={previewModal}
      toggle={() => setPreviewModal(false)}>
      {loadingPreview ? <div className="text-center p-5">
          <FontAwesomeIcon size="2x" icon={faSpinner} spin/>
          <p> Cargando vista previa...</p>
        </div> :
        <div className="p-5 bg-white rounded shadow document-editor__editable-container"
             dangerouslySetInnerHTML={{__html: previewItem}}/>}


      <ModalFooter>
        <button
          disabled={loadingPreview}
          className="btn btn-info"
          onClick={() => convertStringToPdf(previewItem, {name: `${docData?.name}`, download: true})}>
          <FontAwesomeIcon icon={faFileDownload} className="mr-2"/>
          Descargar
        </button>
        {finished && (
          <>
            <button
              disabled={loadingPreview}
              className="btn btn-primary"
              onClick={() => {
                setPreviewModal(false);
                setFinished(false);
              }}>
              Seguir editando
            </button>
            <button
              disabled={loadingPreview}
              onClick={() => history.push(`/sections/${workflowId}`)}
              className="btn btn-primary">
              Finalizar
            </button>
          </>)}
      </ModalFooter>

    </ModalComponent>
  </div>
}

export default ExecutionFlowComponent;

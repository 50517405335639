import React from 'react';
import {Route, Switch, RouteComponentProps} from "react-router-dom";
import DataListListComponent from "./DataListListComponent";
import DataListComponent from "./DataListComponent";

const DataListMainComponent: React.FC<RouteComponentProps> = ({match}) => {
  return (
    <Switch>
      <Route path={match.path} component={DataListListComponent} exact />;
      <Route path={`${match.path}/:dataListId`} component={DataListComponent} exact />;
    </Switch>
  );
};

export default DataListMainComponent;

import React from "react";
import {getFieldName} from "../../Utils/Utils";

const OptionDetail = ({isDisabled, innerProps, label, data}: any) => {
  const name = getFieldName(data.data);
  return !isDisabled ? (
    <div {...innerProps} className="pl-2 hover-mode align-items-center">
      <span>{label}</span><br/>
      <small className="text-dark">({name})</small>
    </div>
  ) : null
};

export default OptionDetail;

import Section from '../../Models/Section';
import Document from '../../Models/Document';

export const GET_SECTIONS = 'section/getSections';
export const ADD_SECTION = 'section/getAddSection';
export const GET_EDIT_DOCUMENT = 'section/getEditDocument';
export const RENAME_SECTION = 'section/renameSection';
export const DELETE_SECTION = 'section/deleteSection';

export enum StateReducer {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
}

export interface SetFolderList {
  content: Section[];
  workflow: Document;
}

export interface SectionState {
  list: Section[];
  section?: Section;
  document?: Document;
  state: string;
}

import React, {useCallback, useEffect, useState} from "react";
import Select from "react-select";
import Section from "../../Models/Section";
import IField from "../../Models/FormBuilder/IField";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../Store/Reducers";
import {actions} from "../../Store/Fragments/Slice";
import {ChildType, FieldType} from "../../Models/FieldTypes";
import {getForm} from "../../Services";
import ModalComponent from "./ModalComponent";
import {Input} from "reactstrap";
import OptionDetail from "./OptionDetail";

const AddFieldNameToEditor = ({
                                workflowId,
                                getName,
                                title
                              }: { workflowId: string, getName: (text: string) => void, title: string }) => {

  const [form, setForm] = useState<any | null>(null);
  const [field, setField] = useState<any | null>(null);
  const [subField, setSubField] = useState<any | null>(null);
  const [sectionValue, setSection] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [typeList, setTypeList] = useState<"bulleted" | "numbered" | "custom">("bulleted")
  const dispatch = useDispatch();

  const isFormGroup = field?.data.field_type === FieldType.formGroup;
  const isTableList = field?.data.sub_type === ChildType.tableList;
  const isSelectorMulti = field?.data.field_type === FieldType.options && !field?.data.single_value;


  const {
    fragmentReducer: {forms, fields, sections, subFields},
    sectionReducer: {document}
  } = useSelector(
    (state: RootState) => state,
  );

  const setNewDataToEditor = () => {
    if (!isFormGroup && !isTableList) {
      const {
        data: {name},
      } = field;
      if (isSelectorMulti) {
        getName(`@${typeList}_${name}[$value];`);
      } else {
        getName("${" + name + "};");
      }
      resetActions();
    } else if (isFormGroup && subField) {
      const {
        data: {name},
      } = subField;
      getName("${" + name + "};");
      resetActions();
    } else if (isTableList && field && subFields.length) {
      const {data: {name}} = field;
      let acm = `@list_${name}[ ${subFields.map(sf => ("${" + sf.name + "};")).join(" ")} ];`
      getName(acm);
      resetActions();
    }
  }

  useEffect(() => {
    if (form) {
      const formSelected = forms.find(i => i.id.toString() === form.value);
      dispatch(actions.setFields(formSelected?.fields || []));
    }
  }, [form, dispatch, forms]);

  const getSubFields = useCallback(async () => {
    if (field && (isFormGroup || isTableList)) {
      const res = await getForm(field.data.detail_form_id);
      if (res.success) {
        dispatch(actions.setSubFields(res.item.fields))
      }
    }
  }, [field, isFormGroup, isTableList, dispatch]);

  useEffect(() => {
    getSubFields();
  }, [getSubFields])


  useEffect(() => {
    if (document && sectionValue) {
      dispatch(
        actions.getForms({
          workflowId: document.workflow_id,
          taskId: sectionValue.value,
        }),
      );
    }
  }, [sectionValue, dispatch, document]);

  useEffect(() => {
    dispatch(actions.getSections(workflowId));
  }, [workflowId, dispatch]);

  function resetActions() {
    setField(null);
    setSubField(null);
    setForm(null);
    setOpenModal(false);
    setTypeList("bulleted");
  }

  return (
    <div className="card mb-3 text-left">
      <div className="card-header">
        <h5>{title}</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className={isFormGroup ? "col-2" : "col-3"}>
            <div className="form-group">
              <label>Secciones</label>
              <Select
                id="sections_preview"
                value={sectionValue}
                onChange={e => {
                  setSection(e);
                }}
                options={sections.map((a: Section) => ({
                  label: a.name,
                  value: a.task_id,
                  data: a,
                }))}
              />
            </div>
          </div>
          <div className={isFormGroup ? "col-2" : "col-3"}>
            <div className="form-group">
              <label>Formularios</label>
              <Select
                id="fields_preview"
                value={form}
                onChange={setForm}
                isDisabled={!forms?.length}
                options={forms?.map(a => ({
                  label: a.name,
                  value: a.id.toString(),
                }))}
              />
            </div>
          </div>
          <div className={"col-3"}>
            <div className="form-group">
              <label>Campos</label>
              <Select
                id="fields_preview"
                value={field}
                onChange={setField}
                isDisabled={!fields?.length}
                components={{Option: OptionDetail}}
                options={fields.map((a: IField) => ({
                  label: a.label,
                  value: a.id,
                  data: a,
                }))}
              />
            </div>
          </div>

          {isFormGroup && <div className={"col-3"}>
            <div className="form-group">
              <label>Sub Campos</label>
              <Select
                id="fields_preview"
                value={subField}
                onChange={setSubField}
                isDisabled={!subFields.length}
                options={subFields.map((a: IField) => ({
                  label: a.label,
                  value: a.id,
                  data: a,
                }))}
              />
            </div>
          </div>}

          <div className={isFormGroup ? "col-2" : "col-3"}>
            <div className="form-group">
              <label>&nbsp;</label>
              <button
                onClick={() => isSelectorMulti ? setOpenModal(true) : setNewDataToEditor()}
                className="btn btn-primary btn-block">
                <small>Agregar</small>
              </button>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalComponent
          size="md"
          isOpen
          title="Formato de lista"
          onSuccess={setNewDataToEditor}
          labelSuccess="Agregar"
          toggle={() => setOpenModal(false)}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-4 d-flex align-items-center justify-content-start">
                <div className="form-group">
                  <label htmlFor={"bulleted"}>
                    <Input
                      type="radio"
                      id={"bulleted"}
                      name="type_list_to_selector"
                      checked={typeList === "bulleted"}
                      onChange={() => setTypeList("bulleted")}/>
                    Viñetas
                  </label>
                </div>
              </div>

              <div className="col-8">
                <div className="p-3 shadow-sm rounded">
                  <ul>
                    <li>Ejemplo</li>
                    <li>Ejemplo</li>
                  </ul>
                </div>
              </div>
              <div className="col-4 d-flex align-items-center justify-content-start ">
                <div className="form-group">
                  <label htmlFor={"numbered"}>
                    <Input
                      type="radio"
                      id={"numbered"}
                      name="type_list_to_selector"
                      checked={typeList === "numbered"}
                      onChange={() => setTypeList("numbered")}/>
                    Numérico
                  </label>
                </div>
              </div>

              <div className="col-8">
                <div className="p-3 shadow-sm rounded">
                  <ol>
                    <li>Ejemplo</li>
                    <li>Ejemplo</li>
                  </ol>
                </div>
              </div>


              <div className="col-4 d-flex align-items-center justify-content-start ">
                <div className="form-group">
                  <label htmlFor={"custom"}>
                    <Input
                      type="radio"
                      id={"custom"}
                      name="type_list_to_selector"
                      checked={typeList === "custom"}
                      onChange={() => setTypeList("custom")}/>
                    Personalizado
                  </label>
                </div>
              </div>

              <div className="col-8">
                <div className="p-3 shadow-sm rounded">
                  En esta opción podrá definir el formato en el que desea mostrar la lista de valores seleccionados.
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  )

}
export default AddFieldNameToEditor;

import {Expose} from "class-transformer";

export default class Document {
  @Expose({name: '_KEY'})
  key!: string;
  @Expose()
  name!: string;
  @Expose()
  workflow_id!: number;
  @Expose()
  description = '';
  @Expose()
  large_description = "";
  @Expose()
  category!: string;
  @Expose()
  sort_index!: number;
  @Expose()
  folder_key!: string;
  @Expose()
  document_key!: string;
  @Expose()
  last_updated!: Date;
  @Expose()
  pages = 0;
  @Expose()
  downloads = 0;
  @Expose()
  visible!: boolean
}

import Folder from "../../Models/Folder";
import Document from "../../Models/Document";

export const GET_FOLDER_LIST = 'folder/getFolderList';
export const GET_ADD_NEW_FOLDER = 'folder/getAddNewFolder';
export const GET_ADD_NEW_FILE = 'folder/getAddNewFile';
export const GET_ADD_NEW_DOCUMENT = 'folder/getAddNewDocument';
export const GET_FIRST_LOAD = 'folder/getFirstLoad';
export const DELETE_FOLDER_LIST = "folder/deleteFolderList";

export enum StateReducer {
  IDLE = 'idle',
  PENDING = 'pending',
  REJECTED = "REJECTED",
}

export interface SetFolderList {
  content: Document[]
  folder: Folder
}

export interface FolderState {
  list: Document[]
  currentFolder: Folder | null
  state: string
}

export interface GetFolderList {
  type: typeof GET_FOLDER_LIST
  payload: string
}

import React, {useEffect} from "react";
import FormBuilderComponent from "./FormBuilderComponent";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {actions as actionsSection} from "../../../Store/Section/Slice";
import {RootState} from "../../../Store/Reducers";
import {actions} from "../../../Store/FormBuilder/Slice";

export default () => {
  const {sectionId, workflowId, formId} = useParams();
  const dispatch = useDispatch();

  const {
    formBuilder: {
      currentForm
    }
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    if (formId) {
      dispatch(actions.getFormBuilder({id: formId}));
    }
  }, [dispatch, formId]);

  useEffect(() => {
    if (sectionId && workflowId) {
      dispatch(actionsSection.getSection({section: sectionId, document: workflowId}))
    }
  }, [dispatch, sectionId, workflowId])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          <Link
            to={`/sections/${workflowId}/${sectionId}`}
            className="btn btn-sm btn-primary mr-2">
            <FontAwesomeIcon icon={faArrowLeft}/>
          </Link>
          <h3 className="m-0">Fomulario: {currentForm.name}</h3>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <FormBuilderComponent/>
        </div>
      </div>
    </>
  )
}

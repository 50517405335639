export const GET_USERS = "user/getAllUsers"


export enum State {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  RESOLVED = "RESOLVED",
  IDLE = "IDLE"
}


export interface UserState {
  users: any[],
  state: State
}

import React, {useEffect, useState} from 'react';
import Document from '../../../../Models/Document';
import {useDispatch} from "react-redux";
import {actions} from "../../../../Store/Section/Slice";
import EditorTextComponent from "../../EditorComponent/EditorTextComponent";
import {useHistory, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";

interface Props {
  document: Document,
}

const MetadataComponent: React.FC<Props> = ({document}) => {
  const dispatch = useDispatch();
  const {workflowId} = useParams();
  const History = useHistory();
  const [value, setValue] = useState<string>("");
  const [largeDescription, setLargeDescription] = useState<string>("");
  const [documentPages, setDocumentPages] = useState<number>(0);

  const onsubmit = () => {
    console.log('submit');
    //  Edit Document
    if (value) {
      const newObject = Object.assign(document, {description: value, large_description: largeDescription, pages: documentPages})
      dispatch(actions.getEditDocument(newObject));
    }
  };
  const onClear = () => {
    console.log('clear');
    //  Edit Document
  };

  const goToFragment = () => {
    // if (workflowId) dispatch(ActionsFragment.getCurrentFragment(workflowId));
    History.push(`/sections/fragment/detail/${workflowId}`);
  }

  useEffect(() => {
    setValue(document.description);
    setLargeDescription(document.large_description)
    setDocumentPages(document.pages);
  }, [document])

  return <div>
    <form onSubmit={(event => {
      event.preventDefault();
      onsubmit();
    })}>
      <div className="row">
        <div className="col-5">
          <label htmlFor="" className="label">Descripción</label>
          <input required onChange={e => setValue(e.target.value)} className="form-control" value={value || ""}/>

          <label htmlFor="" className="label">Paginas</label>
          <input className="form-control" onChange={e => setDocumentPages(parseInt(e.target.value))} value={documentPages || 0}/>
        </div>
        <div className="col-7">
          <label htmlFor="" className="label">Descripción adicional</label>
          <EditorTextComponent data={largeDescription || ""} setData={e => setLargeDescription(e)}/>
        </div>
      </div>

      <button type="button" onClick={onClear} className="btn btn-danger mr-3">Limpiar</button>
      <button className="btn btn-primary mr-3">Actualizar descripcion</button>
      <button type="button" className="btn btn-info" onClick={goToFragment}>
        <FontAwesomeIcon className="mr-1" icon={faCog}/>Vista previa
      </button>
    </form>
  </div>;
}

export default MetadataComponent;

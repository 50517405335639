import React from 'react';
import {Route, RouteComponentProps, Switch} from 'react-router-dom';
import SectionListComponent from '../FolderListComponent/SectionListComponent/SectionListComponent';
import DocumentDetail from '../DocumentDetail/DocumentDetail';
import FragmentDetailComponent from "../FolderListComponent/FragmentDetailComponent/FragmentDetailComponent";
import ExecutionFlowComponent from "../ExecutionFlowComponent/ExecutionFlowComponent";
import FormDetail from "../FormBuilder/FormDetail";

const SectionComponent = ({
                            match,
                          }: RouteComponentProps): React.ReactElement => {
  return (
    <Switch>
      <Route
        component={SectionListComponent}
        path={`${match.path}/:workflowId`}
        exact
      />
      <Route component={FragmentDetailComponent}
             path={`${match.path}/fragment/detail/:workflowId`}
             exact
      />

      <Route
        component={ExecutionFlowComponent}
        path={`${match.path}/flow/detail/:workflowId`}
        exact/>

      <Route
        component={DocumentDetail}
        path={`${match.path}/:workflowId/:sectionId`}
        exact
      />

      <Route component={FormDetail}
             path={`${match.path}/:workflowId/:sectionId/form/:formId`}
             exact/>
    </Switch>
  );
};

export default SectionComponent;

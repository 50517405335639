import {uuidV4} from "../Utils/Utils";
import env from "../Environment";
import {deleteReq, get, post, put, sbxCoreService} from "../Network";
import {deleteReq as deleteProcess, get as getProcess, post as postProcess} from "../Network/ProcessNetwork"
import Response from "../Models/Response";
import IField from "../Models/FormBuilder/IField";
import {attachForm} from "./WorkflowServices/ProcessModelService";
import {Attachment} from "../Models/FormBuilder/Attachment";


export async function setOptionsToFieldService(fields: IField[]) {
  return await Promise.all(fields.map(async (f) => {
    if (!f.sub_type)
      f.sub_type = undefined;
    const {item: {options}}: any = (f.list_provider_id && await getOptionsByIdService(f.list_provider_id)) || {item: {options: []}}
    f.options = options.map((o: any) => ({label: o.label, value: o.value, data: o}));
    return f
  }));
}

export const getForm = async (formId: string | number) => {
  const res = await get(`/api/v2/${env.domain}/wf/form/${formId}`);
  if (res && res.success) {
    const form = res.item;
    const array: Array<IField> = res.item.fields;
    res.item.fields = await setOptionsToFieldService(array);

    for (let i = 0; i < form.fields.length; i++) {
      const field = form.fields[i];
      field.key = uuidV4();
      if (field.detail_form_id) {
        const result = await getForm(field.detail_form_id);
        if (result && result.success) {
          field.detail_form = result.item;
        }
      }
    }
    return Promise.resolve({success: true, item: form});
  }
  return Promise.reject();
}

export const getTaskService = async (docKey: string, secKey: string, w?: { workflow_id: number, task_id: number }): Promise<any> => {
  let taskId, workflowId;
  if (!w?.workflow_id && !w?.task_id) {
    const resSec: any = await sbxCoreService
      .with('section')
      .whereWithKeys([secKey])
      .loadAll();

    const resDoc: any = await sbxCoreService
      .with('document')
      .whereWithKeys([docKey])
      .loadAll();

    taskId = resSec?.results[0]?.task_id;
    workflowId = resDoc?.results[0]?.workflow_id;

  } else {
    taskId = w.task_id;
    workflowId = w.workflow_id;
  }
  if (taskId && workflowId) {
    return getProcess(`/api/v2/${env.domain}/wf/process/model/${workflowId}/task/${taskId}`)
      .then(async (res: any) => {
        res.item.attachments = await Promise.all(res.item.attachments.map(async (f: any) => {
          const form = await get(`/api/v2/${env.domain}/wf/form/${f.attachment_id}`);
          return Object.assign(form.item, f);
        })).catch(() => []);
        return Promise.resolve(res)
      })
      .catch(err => Promise.reject({success: false}));
  } else {
    return {success: false}
  }
}

export async function createFormAttachmentService(name: string, task_id: number, workflow_id: number, rule: string): Promise<any> {
  try {
    const resForm = await createForm({name: `${name}`});
    if (resForm.success && resForm.item?.id) {
      const resAttachForm = await attachForm(
        workflow_id,
        task_id,
        resForm.item?.id,
        rule
      );
      if (resAttachForm.success) {
        return Promise.resolve(resAttachForm)
      } else throw Error;
    } else throw Error;
  } catch (e) {
    return Promise.reject({success: false})
  }

}

export async function getFieldForm(formId: string | number, fieldId: string | number): Promise<any> {
  try {
    const res = await get(`/api/v2/${env.domain}/wf/form/${formId}/field/${fieldId}`);
    if (res.success) {
      return Promise.resolve(res);
    } else throw Error;
  } catch (e) {
    return Promise.resolve({success: false})
  }
}

export function getOptionsByIdService(id: string) {
  return get(`/api/v2/${env.domain}/wf/dl/${id}/options`)
    .then(res => {
      if (!res.success) return {success: false, item: {options: []}}
      return res;
    })
    .catch(res => {
      res.success = false;
      res.item = {options: []};
      return res
    })
}

export const createForm = (form: any): Promise<Response<any>> => {
  return post(`/api/v2/${env.domain}/wf/form`, form);
}

export const deleteForm = (formId: string | number) => {
  return deleteReq(`/api/v2/${env.domain}/wf/form/${formId}`)
}

export const deleteAttachment = (processId: number | string, attachmentId: number | string) => {
  return deleteProcess(`/api/v2/${env.domain}/wf/process/model/${processId}/attachment/${attachmentId}`)
}

export const updateForm = (form: { name: string, domain: number }, formId: number) => {
  return put(`/api/v2/${env.domain}/wf/form/${formId}`, form)
}


export const updateAttachment = (processId: number | string, attachmentId: number | string, attachment: Attachment) => {
  return postProcess(`/api/v2/${env.domain}/wf/process/model/${processId}/attachment/${attachmentId}`, attachment)
}

export const createField = (formId: string | number, field: any): Promise<Response<any>> => {
  return post(`/api/v2/${env.domain}/wf/form/${formId}/field`, field);
}

export const updateField = (formId: string | number, field: any): Promise<Response<any>> => {
  return put(`/api/v2/${env.domain}/wf/form/${formId}/field/${field.id}`, field);
}

export const deleteField = (formId: string | number, fieldId: string | number) => {
  return deleteReq(`api/v2/${env.domain}/wf/form/${formId}/field/${fieldId}`)
}

export async function getFieldsByIdService(id: string): Promise<any> {
  const resForms = await getForm(id);
  if (resForms.success) {
    const {item: {fields}} = resForms;
    const newFields = await setOptionsToFieldService(fields);
    return Promise.resolve(newFields || [])
  }
}

import { all, call, put, takeEvery } from '@redux-saga/core/effects';
import {
  GET_ALL_DATA_LIST,
  GET_ADD_DATA_LIST,
  GET_DELETE_DATA_LIST,
  GET_EDIT_DATA_LIST,
  GET_DATA_LIST_WITH_OPTIONS,
  GET_ADD_DATA_LIST_OPTION,
  GET_EDIT_DATA_LIST_OPTION,
  GET_DELETE_DATA_LIST_OPTION,
} from './Types';
import * as Services from '../../Services';
import { actions } from './Slice';
import { PayloadAction } from '@reduxjs/toolkit';
import DataList from '../../Models/FormBuilder/DataList';
import { uuidV4 } from '../../Utils/Utils';

const r = { success: false };
const cache = { getAllDataList: r };

function* getAllDataList(reload = false) {
  try {
    let res;
    if (cache.getAllDataList.success && !reload) {
      res = cache.getAllDataList;
    } else {
      res = yield call(Services.getAllDataList);
    }
    if (res.success) {
      cache.getAllDataList = res;
      yield put(
        actions.setAllDataList(
          res.items.map((item: any) => {
            item.key = uuidV4();
            return item as DataList;
          }),
        ),
      );
    } else {
      throw Error;
    }
  } catch (e) {
    console.error(e);
  }
}

function* saveDataList({ payload }: PayloadAction<DataList>) {
  try {
    const service = payload.id
      ? Services.updateDataList
      : Services.createDataList;
    const res = yield call(service, payload);
    if (res.success) {
      yield call(getAllDataList, true);
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
    console.error(e);
  }
}

function* getDeleteDataList({
  payload,
}: PayloadAction<{ dataListId: number | string }>) {
  try {
    const res = yield call(Services.deleteDataList, payload.dataListId);
    if (res.success) {
      yield call(getAllDataList, true);
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
    console.error(e);
  }
}

function* getDataListWithOptions({
  payload,
}: PayloadAction<{ dataListId: number | string }>) {
  try {
    const res = yield call(Services.getDataListWithOptions, payload.dataListId);
    console.log(res);
    if (res.success) {
      yield put(actions.setDataListWithOptions(res.item));
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
    console.error(e);
  }
}

function* saveDataListOption({
  payload,
}: PayloadAction<{ dataListId: number | string; option: any }>) {
  try {
    const service = payload.option.id
      ? Services.updateDataListOption
      : Services.createDataListOption;
    const res = yield call(service, payload.dataListId, payload.option);
    if (res.success) {
      yield put(actions.setDataListWithOptions(res.item));
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
    console.error(e);
  }
}
function* saveDataListOptions({
  payload,
}: PayloadAction<{ dataList: DataList }>) {
  try {
    let res: any;
    if (!payload.dataList.id) {
      throw new Error('Missing id');
    }
    res = yield Promise.all(
      payload.dataList.options.map(i =>
        payload.dataList.id
          ? Services.updateDataListOption(payload.dataList.id, i)
          : null,
      ),
    );
    if (res.length) {
      yield put(
        actions.getDataListWithOptions({ dataListId: payload.dataList.id }),
      );
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
    console.error(e);
  }
}

function* getDeleteDataListOption({
  payload,
}: PayloadAction<{ dataListId: number | string; optionId: number | string }>) {
  try {
    const res = yield call(
      Services.deleteDataListOption,
      payload.dataListId,
      payload.optionId,
    );
    if (res.success) {
      yield put(actions.setDataListWithOptions(res.item));
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
    console.error(e);
  }
}

export function* dataListSaga(): Generator {
  yield all([
    takeEvery(GET_ALL_DATA_LIST, getAllDataList),
    takeEvery(GET_ADD_DATA_LIST, saveDataList),
    takeEvery(GET_DELETE_DATA_LIST, getDeleteDataList),
    takeEvery(GET_EDIT_DATA_LIST, saveDataList),
    takeEvery(GET_DATA_LIST_WITH_OPTIONS, getDataListWithOptions),
    takeEvery(GET_ADD_DATA_LIST_OPTION, saveDataListOption),
    takeEvery(GET_EDIT_DATA_LIST_OPTION, saveDataListOption),
    takeEvery(GET_EDIT_DATA_LIST_OPTION + 's', saveDataListOptions),
    takeEvery(GET_DELETE_DATA_LIST_OPTION, getDeleteDataListOption),
  ]);
}

import {Expose} from "class-transformer";

export default class Category {
  @Expose()
  name: string;
  @Expose()
  folder_key: string;
  @Expose({name: "_KEY"})
  key?: string;
  @Expose()
  description?: string;
  @Expose()
  image?: string | Blob
  @Expose()
  icon?: string

  constructor(name: string, folder_key: string) {
    this.name = name;
    this.folder_key = folder_key;
  }
}

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, RouteChildrenProps, useHistory, useParams,} from 'react-router-dom';
import {RootState} from '../../../../Store/Reducers';
import {actions} from '../../../../Store/Section/Slice';
import MetadataComponent from '../../Worflow/MetadataComponent/MetadataComponent';
import CreateSectionModal from '../../../Modals/CreateSectionModal';
import Section from '../../../../Models/Section';
import RenameSectionModal from '../../../Modals/RenameSectionModal/RenameSectionModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import TableComponent from '../../../Shared/TableComponent/TableComponent';
import {Head} from '../../../../Models/Table';
import EditValueComponent from '../../../Shared/EditValueComponent';
import {State} from '../../../../Store/Category/Types';
import {getHistorySection} from '../../../../Utils/Utils';
import ConfirmModal from '../../../Modals/ConfirmModal';
import {onDragEndSectionsService} from "../../../../Services";

const SectionListComponent = ({
                                match,
                              }: RouteChildrenProps): React.ReactElement => {
  const {state, list, document} = useSelector(
    (state: RootState) => state.sectionReducer,
  );


  const [sections, setSections] = useState(list);
  const [createSectionModal, setCreateSectionModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selected, setSelected] = useState<Section | null>(null);
  const [dragging, setDragging] = useState(false);
  const [renameSection, setRenameSection] = useState<{
    open: boolean;
    section: Section;
  } | null>(null);
  const dispatch = useDispatch();
  const {workflowId} = useParams<{ [key: string]: string }>();
  const history = useHistory();

  const loading = state === State.PENDING;

  useEffect(() => {
    if (workflowId) {
      dispatch(actions.getSections(workflowId));
    }
  }, [dispatch, workflowId]);

  useEffect(() => {
    setSections(list)
  }, [list])

  function goToDetail(i: any) {
    history.push(`${match?.url}/${i.key}`);
  }

  function editDocument(value: string) {
    if (value) {
      const newObject = Object.assign(document, {name: value});
      dispatch(actions.getEditDocument(newObject));
    }
  }

  const headers: Array<Head> = [
    {field: 'name', header: 'Nombre', width: "20%"},
    {field: 'description', header: 'Descripción', width: "60%", type: "html"},
    {field: 'last_updated', header: 'Fecha de actualización', width: "20%", type: 'Date'},
  ];

  function removeSection(section: Section) {
    if (document && workflowId) {
      dispatch(
        actions.deleteSection({
          document: document,
          task: section,
          workflowId,
        }),
      );
    }
  }

  useEffect(() => {
    if (state === "resolved") {
      setOpenConfirmModal(false);
    }
  }, [state]);


  const linkTo = getHistorySection();

  async function onDragEndItem(data: { newRow: Section, oldRow: Section, newRows: Section[] }) {
    setSections(data.newRows);
    if (document) {
      setDragging(true);
      await onDragEndSectionsService(data, document.workflow_id);
      setDragging(false);
    }
  }

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-start align-items-center">
        <Link
          to={'/' + (linkTo ? '?key=' + linkTo : '')}
          className="btn btn-primary btn-sm w-auto">
          <FontAwesomeIcon icon={faArrowLeft}/>
        </Link>
        <EditValueComponent
          loading={loading}
          onSuccess={editDocument}
          value={document?.name || 'First workflow'}
          label={'Documento: ' + document?.name || 'First workflow'}
        />
      </div>
      <div className="card-body ">
        <div className="mb-5">
          {document && <MetadataComponent document={document}/>}
        </div>
      </div>
      <div className="card-header d-flex justify-content-between align-items-center">
        <h4>Secciones</h4>
        <div>
          <button
            className="btn btn-info mr-2"
            onClick={() => history.push(`/sections/flow/detail/${workflowId}`)}>
            Ejecutar
          </button>
          <button
            className="btn btn-primary"
            onClick={() => setCreateSectionModal(true)}>
            Crear nueva sección
          </button>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <TableComponent
            headers={headers}
            filter
            drag
            pagination
            isDragging={dragging}
            onSortEnd={onDragEndItem}
            loading={state === State.PENDING}
            onEdit={e => setRenameSection({open: true, section: e})}
            onEvent={goToDetail}
            rowsCount={15}
            labelEvent="Detalle"
            onRemove={e => {
              setSelected(e);
              setOpenConfirmModal(true);
            }}
            rows={sections}
          />

        </div>
      </div>
      {document && createSectionModal && (
        <CreateSectionModal
          prevSec={list[list.length - 1]}
          isOpen={true}
          toggle={() => setCreateSectionModal(false)}
          onClosed={() => setCreateSectionModal(false)}
          document={document}
        />
      )}

      <ConfirmModal
        header={
          <span>
            <FontAwesomeIcon icon={faTrashAlt}/> Eliminar
          </span>
        }
        isOpen={openConfirmModal}
        size="md"
        loading={state === State.PENDING}
        description={
          <p>
            ¿Desea eliminar la sección <b>{selected?.name}?</b>
          </p>
        }
        labelSuccessButton="Eliminar Seccción"
        setOpen={setOpenConfirmModal}
        onSuccess={() => selected && removeSection(selected)}
        noFooter
      />

      {renameSection && workflowId && (
        <RenameSectionModal
          sectionData={{...renameSection, workFLowId: workflowId}}
          onToggle={() => setRenameSection(null)}
        />
      )}
    </div>
  );
};

export default SectionListComponent;

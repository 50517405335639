import { DataListState } from './Types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DataList from '../../Models/FormBuilder/DataList';

const initialState: DataListState = {
  state: 'idle',
  list: [],
  currentDataList: undefined,
};

const dataList = createSlice({
  name: 'dataList',
  initialState,
  reducers: {
    getAllDataList(state) {
      state.state = 'pending';
    },
    setAllDataList(state, action: PayloadAction<DataList[]>) {
      state.state = 'idle';
      state.list = action.payload;
    },
    getAddDataList(state, action) {
      state.state = 'savingDataList';
    },
    setAddDataList(state, action) {
      state.state = 'idle';
    },
    getDeleteDataList(state, action) {
      state.state = 'deletingDataList';
    },
    setDeleteDataList(state, action) {
      state.state = 'idle';
    },
    getUpdateDataList(state, action) {
      state.state = 'savingDataList';
    },
    setUpdateDataList(state, action) {
      state.state = 'idle';
    },
    getDataListWithOptions(state, action) {
      state.state = 'pending';
    },
    setDataListWithOptions(state, action: PayloadAction<DataList>) {
      state.currentDataList = action.payload;
      state.state = 'idle';
    },
    getAddDataListOption(state, action) {
      state.state = 'savingDataListOption';
    },
    getUpdateDataListOption(state, action) {
      state.state = 'savingDataListOption';
    },
    getUpdateDataListOptions(state, action) {
      state.state = 'savingDataListOption';
    },
    getDeleteDataListOption(state, action) {
      state.state = 'deletingDataListOption';
    },
  },
});

export const actions = dataList.actions;

export default dataList.reducer;

import {all, call, put, select, takeEvery} from '@redux-saga/core/effects';
import {
  DELETE_FORM,
  GET_ADD_NEW_FIELD,
  GET_CHILD_FORM,
  GET_DELETE_FIELD,
  GET_FORM_BUILDER,
  GET_PARENT_FORM,
  GET_TASK,
  SET_NEW_FORM,
  UPDATE_FORM,
} from './Types';
import {actions, actions as formBuilderActions} from './Slice';
import Form from '../../Models/FormBuilder/Form';
import {plainToClass} from 'class-transformer';
import * as Services from '../../Services';
import {updateField} from '../../Services';
import {setNameToField, toast} from '../../Utils/Utils';
import {PayloadAction} from '@reduxjs/toolkit';
import IField from '../../Models/FormBuilder/IField';
import {RootState} from '../Reducers';
import FieldType from '../../Models/FormBuilder/FieldType';

function* getForm({payload}: any) {
  try {
    const res = yield call(Services.getForm, payload.id);
    if (res.success) {
      const form = plainToClass(Form, res.item);
      yield put(formBuilderActions.setFormBuilder(form));
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
    console.error(e);
  }
}

function* getDeleteField({
                           payload: {field, formId},
                         }: PayloadAction<{ formId: number; field: IField }>) {
  try {
    const calls = [call(Services.deleteField, formId, field.id || 0)];
    if (field.detail_form_id) {
      calls.push(call(Services.deleteForm, field.detail_form_id));
    }
    const res = yield all(calls);
    const deleteRes = res[0];

    if (deleteRes?.success) {
      const currentForm = yield select(
        (state: RootState) => state.formBuilder.currentForm,
      );
      const result = yield call(Services.getForm, currentForm.id);

      if (result && result.success) {
        const form = result.item;
        yield put(formBuilderActions.setFormBuilder(plainToClass(Form, form)));
        yield put(formBuilderActions.setDeleteField(form));
        toast('Field deleted.');
      }
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
    console.error(e);
  }
}

function* getAddNewField({
                           payload: {field, form_id},
                         }: PayloadAction<{ field: IField; form_id: string }>) {
  try {
    const id = field.form_id || parseInt(form_id, 10);
    const formGroupOrTableValidation =
      field.field_type === FieldType.FORM_GROUP ||
      field.field_type === FieldType.TABLE;
    if (!field.id && formGroupOrTableValidation && !field.detail_form_id) {
      const quantity = yield select((state: RootState) => {
        return state.formBuilder.currentForm.fields.reduce(
          (acc: number, field1: IField) => {
            if (
              field1.field_type === FieldType.FORM_GROUP ||
              field1.field_type === FieldType.TABLE
            ) {
              acc += 1;
            }
            return acc;
          },
          0,
        );
      });
      const res = yield call(Services.createForm, {
        name: `Form ${id}.${quantity + 1}`,
      });
      if (res?.success) {
        field.detail_form_id = res.item.id;
      } else {
        throw Error;
      }
    }

    let service = Services.createField;
    if (field.id) {
      service = Services.updateField;
    }
    const res = yield call(service, id, field);
    if (res?.success) {
      const currentForm = yield select((state: RootState) => {
        return state.formBuilder.currentForm;
      });

      if (!field.id) {
        let fieldCreated = res.item.fields.find((f: IField) => field.name === f.name);
        if (fieldCreated) {
          fieldCreated = setNameToField(fieldCreated);
          yield call(service, id, fieldCreated);
        }
      }

      const result = yield call(Services.getForm, currentForm.id);


      if (result && result.success) {
        yield put(actions.setFormBuilder(plainToClass(Form, result.item)));
        yield put(actions.setAddNewField());
        toast(`Field ${field.id ? 'edited' : 'created'}.`);
      }
    } else {
      throw Error;
    }
  } catch (e) {
    // toast("Can't get this step", "error");
    console.error(e);
  }
}

function* getChildForm({
                         payload: {childFormId},
                       }: PayloadAction<{
  childFormId: string | number;
  parentFormId: string | number;
}>) {
  try {
    yield put(actions.getFormBuilder({id: childFormId}));
  } catch (e) {
    console.error(e);
  }
}

function* getParentForm({
                          payload: {parentFormId},
                        }: PayloadAction<{ parentFormId: string | number }>) {
  try {
    yield put(actions.getFormBuilder({id: parentFormId}));
  } catch (e) {
    console.error(e);
  }
}

function* updateFormSaga({payload}: PayloadAction<any>) {
  try {
    const res = yield call(
      Services.updateForm,
      {name: payload.name, domain: payload.domain},
      payload.attachment_id,
    );
    if (res.success) {
      const resA = yield call(
        Services.updateAttachment,
        payload.process_id,
        payload.id,
        {
          attachment_id: payload.attachment_id,
          id: payload.id,
          rule: payload.rule || 'true',
          attachment_type: payload.attachment_type,
          target_item_id: payload.target_item_id,
          target_type: payload.target_type,
          task_type: payload.task_type,
        },
      );
      if (resA.success) {
        toast('Formulario actualizado.');
        yield put(
          actions.getTask({
            secKey: '',
            docKey: '',
            w: {task_id: payload.task_id, workflow_id: payload.process_id},
          }),
        );
      } else throw Error;
    } else throw Error;
  } catch (e) {
    yield put(actions.rejectAction());
    toast('No se pudo actualizar el formulario', 'error');
  }
}

function* deleteFormSaga({
                           payload,
                         }: PayloadAction<{
  process_id: number;
  task_id: number;
  form_id: number;
  attachment_id: number;
}>) {
  try {
    const deleteFormRes = yield call(Services.deleteForm, payload.form_id);
    if (deleteFormRes.success) {
      const deleteAttachementRes = yield call(
        Services.deleteAttachment,
        payload.process_id,
        payload.attachment_id,
      );
      if (deleteAttachementRes.success) {
        toast('Formulario Borrado', 'warning');
        yield put(
          actions.getTask({
            secKey: '',
            docKey: '',
            w: {task_id: payload.task_id, workflow_id: payload.process_id},
          }),
        );
      } else throw Error;
    } else throw Error;
  } catch (e) {
    yield put(actions.rejectAction());
    toast('No se pudo eliminar el formulario', 'error');
  }
}

function* getTaskSaga({
                        payload: {docKey, secKey, w},
                      }: PayloadAction<{
  docKey: string;
  secKey: string;
  w?: { task_id: number; workflow_id: number };
}>) {
  try {
    const res = yield call(Services.getTaskService, docKey, secKey, w);
    if (res && res.success) {
      yield put(actions.setTask(res.item));
    } else throw Error;
  } catch (e) {
    toast('No se pudo obtener la lista de formularios', 'error');
    yield put(actions.restart());
  }
}

function* createNewFormSaga({
                              payload: {task_id, workflow_id, name, rule},
                            }: PayloadAction<{
  task_id: number;
  workflow_id: number;
  name: string;
  rule: string;
}>) {
  try {
    const res = yield call(
      Services.createFormAttachmentService,
      name,
      task_id,
      workflow_id,
      rule,
    );
    if (res && res.success) {
      yield put(
        actions.getTask({
          docKey: '',
          secKey: '',
          w: {workflow_id, task_id},
        }),
      );
    } else throw Error;
  } catch (e) {
    toast('No se pudo crear el formulario');
    yield put(actions.restart());
  }
}

function* sortFieldsSaga({
                           payload: {source, destination},
                         }: PayloadAction<{ source: number; destination: number }>) {
  try {
    const fields: IField[] = yield select((state: RootState) => {
      return state.formBuilder.currentForm.fields;
    });

    const res = yield all(
      fields.map(i => (i.form_id ? call(updateField, i.form_id, i) : null)),
    );
    console.log(res);
  } catch (e) {
    toast('No se pudo crear el formulario');
    console.error(e);
    // yield put(actions.restart())
  }
}

export function* formBuilderSaga(): Generator {
  yield all([
    takeEvery(GET_FORM_BUILDER, getForm),
    takeEvery(GET_ADD_NEW_FIELD, getAddNewField),
    takeEvery(GET_DELETE_FIELD, getDeleteField),
    takeEvery(GET_CHILD_FORM, getChildForm),
    takeEvery(GET_PARENT_FORM, getParentForm),
    takeEvery(GET_TASK, getTaskSaga),
    takeEvery(SET_NEW_FORM, createNewFormSaga),
    takeEvery(UPDATE_FORM, updateFormSaga),
    takeEvery('formBuilder/getSortFields', sortFieldsSaga),
    takeEvery(DELETE_FORM, deleteFormSaga),
  ]);
}

export const NOW_DEFAULT_VALUE = /^\${now}$/;
export const CURRENT_USER_DEFAULT_VALUE = /^\${currentUser}$/;
export const CONDITIONS: string[] = [
  ' === ',
  ' !== ',
  ' > ',
  ' < ',
  ' >= ',
  ' <= ',
];

import env from "../../Environment";
import {deleteReq, post} from "../../Network/ProcessNetwork";
import Response from "../../Models/Response";
import EventType from "../../Models/Workflow/EventType";
import TargetType from "../../Models/Workflow/TargetType";

export async function createEvent(process_model_id: number, name: string, event_type: EventType,): Promise<Response<any>> {
  const res = await post(`/api/v2/${env.domain}/wf/process/model/${process_model_id}/event`, {
    name,
    event_type
  });

  if (res.success) {
    return Promise.resolve(res);
  } else {
    return Promise.reject({success: false});
  }
}


export async function deleteSequenceService(modelId: number, seqId: number): Promise<Response<any>> {
  const res = await deleteReq(`/api/v2/${env.domain}/wf/process/model/${modelId}/sequence/${seqId}`);
  if (res.success) {
    return Promise.resolve(res)
  } else {
    return Promise.reject({success: false})
  }
}


export async function createSeq(process_model_id: number,
                                name: string,
                                label: string,
                                from_item_id: number,
                                to_item_id: number,
                                from_item_type: TargetType,
                                to_item_type: TargetType): Promise<Response<any>> {
  const res = await post(`/api/v2/${env.domain}/wf/process/model/${process_model_id}/sequence`, {
    name,
    label,
    from_item_type,
    to_item_type,
    from_item_id,
    to_item_id
  });

  if (res.success) {
    return Promise.resolve(res);
  } else {
    return Promise.reject({success: false});
  }
}



import {SbxCoreService, SbxSessionService} from "sbx-axios";
import env from "../Environment";
import Axios from "axios";
import Response from "../Models/Response";

const sbxCoreService = new SbxCoreService();
const sbxSessionService = new SbxSessionService(sbxCoreService);

sbxSessionService.initialize(
  parseInt(env.domain),
  env.appKey
);

sbxCoreService.httpClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
  }
);

sbxCoreService.httpClient.interceptors.response.use(
  function (response: any) {
    // Do something with response data'
    if (!response.success && response.error === 'Invalid Token or App-Key provided.') {
      localStorage.removeItem('token');
      window.location.reload();
    }
    return response;
  },
  function (error) {
    // Do something with response error
  }
);

// ==========================================================================================

const http = Axios.create({
  baseURL: 'https://sbx-svc-form.nubesocobox.com',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("access_token")}`
  }
});

export function setHeaderTokenForm(token: string) {
  http.defaults.headers = { Authorization: "Bearer " + token };
}


export function get(url: string, params?: any): Promise<Response<any>> {
  return http.get(url, {params}).then(res => res.data).catch(reason => Promise.reject(new Error(reason.statusText)));
}

export function post(url: string, params?: any): Promise<Response<any>> {
  return http.post(url, params).then(res => {
    return res.data ? res.data : res;
  }).catch(reason => {
    return Promise.reject(reason);
  });
}

export function put(url: string, params?: any): Promise<Response<any>> {
  return http.put(url, params).then(res => {
    return res.data ? res.data : res;
  }).catch(reason => {
    return Promise.reject(reason);
  });
}

export function deleteReq(url: string, params?: any): Promise<Response<any>> {
  return http.delete(url, params).then(res => res.data).catch(reason => {
    console.error(reason.message);
  });
}

export {sbxSessionService, sbxCoreService};

import React, {useEffect, useState} from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';

import {Head} from "../../../Models/Table";
import {formatDate} from "../../../Utils/Utils";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import "./TableComponent.scss";
import TableBodyComponent from "./TableBodyComponent";
import {TablePaginationActions} from "./TablePaginationActions";


const useStyles = makeStyles({
  table: {
    width: '100%',
  },
});

interface Props {
  rows: Array<any>;
  headers: Array<Head>;
  alignHeaders?: 'left' | 'right' | 'center';
  alignRows?: 'left' | 'right' | 'center';
  rowsCount?: number;
  pagination?: boolean;
  onRemove?: (data: any) => any;
  onEdit?: (data: any) => any;
  onEvent?: (data: any) => any;
  labelRemove?: string;
  labelEdit?: string;
  labelEvent?: string;
  filter?: boolean;
  drag?: boolean;
  onSortEnd?: (data: any) => void;
  loading?: boolean;
  isDragging?: boolean;
}


export function getValue(value: any, type: "Date" | "String" | "Number" | "ReactNode" | "html" | undefined) {
  switch (type) {
    case "Date":
      return formatDate(new Date(value));
    default:
      return value;
  }
}


const TableComponent = ({
                          rows,
                          headers,
                          alignHeaders,
                          alignRows,
                          rowsCount,
                          pagination = false,
                          filter = false,
                          labelEdit,
                          labelEvent,
                          labelRemove,
                          onEdit,
                          onEvent,
                          onRemove,
                          drag = false,
                          onSortEnd,
                          loading,
                          isDragging
                        }: Props) => {

  const classes = useStyles();
  const [newRows, setNewRows] = useState<Array<any>>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsCount || 5);
  const [filterData, setFilter] = useState('');
  const [width, setWidth] = useState("0%");

  useEffect(() => setNewRows(rows), [rows]);
  const handleChangePage = (event: any, newPage: any) => setPage(newPage);

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if (filter) {
      if (filterData === '') {
        setNewRows(rows);
      } else {
        const r = rows.filter(r =>
          headers.some(h => {
            const value = (r[h.field] + '').toLowerCase();
            return (
              getValue(value, h.type).indexOf(filterData.toLowerCase()) !== -1
            );
          }),
        );
        setNewRows(r);
      }
    }
  }, [filter, filterData, headers, rows]);

  useEffect(() => {
    if (loading) {
      setWidth("50%");
    } else if (width === "50%") {
      setWidth("100%");
      setTimeout(() => setWidth("0%"), 600);
    }
  }, [width, loading])

  const actions = onRemove || onEdit || onEvent;
  return (
    <>
      {filter && (
        <div className="input-group mb-3  col-lg-4 col-md-6 col-12 ml-auto d-flex align-items-center">
          <input
            value={filterData}
            onChange={e => setFilter(e.target.value)}
            type="text"
            className="form-search-line mr-1"
            placeholder="Buscar..."
            aria-label=""
            aria-describedby="basic-addon1"
          />
          <FontAwesomeIcon icon={faSearch}/>
        </div>
      )}
      <div className="progress" style={{height: "2px", opacity: width !== "0%" ? 1 : 0}}>
        <div className="progress-bar progress-bar-striped progress-bar-animated"
             role="progressbar" style={{width, height: "2px"}}/>
      </div>
      <TableContainer component={Paper} data-testid="tableRender">
        <Table className={classes.table} aria-label="table component">
          {!drag && <TableHead>
            <TableRow>
              {headers.map((h, i) => (
                <TableCell key={i} align={h.align || alignHeaders}>
                  {h.header}
                </TableCell>
              ))}
              {actions && <TableCell style={{minWidth: "250px"}}/>}
            </TableRow>
          </TableHead>}
          <TableBodyComponent drag={drag}
                              rows={(pagination ? (rowsPerPage > 0 ?
                                newRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : newRows)
                                : newRows)}
                              actions={!!actions}
                              setRows={setNewRows}
                              alignRows={alignRows}
                              headers={headers}
                              labelEdit={labelEdit}
                              labelRemove={labelRemove}
                              labelEvent={labelEvent}
                              onEdit={onEdit}
                              onEvent={onEvent}
                              isDragging={isDragging}
                              onRemove={onRemove}
                              onSortEnd={onSortEnd}

          />
          {pagination && <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, {label: 'All', value: -1}]}
                colSpan={headers.length + 1}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {'aria-label': 'rows per page'},
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>}
        </Table>
      </TableContainer>
    </>
  );
};

export default TableComponent;



/**
 * @class Auth
 */
import * as React from "react";
import * as queryString from "querystring";
import { AuthConfig, initialize } from "./AuthConfig";
import { validateToken } from "./validateToken";

export type props = {
  children: React.ReactNode;
  onError: () => void;
  onSuccess: (response: object | boolean) => void;
};

const Auth: React.FC<props> = ({ children, onError, onSuccess }) => {
  const [logged, setLogged] = React.useState<boolean>(false);
  const authConfig = AuthConfig.getInstance();

  React.useEffect(() => {
    const run = async () => {
      const params = window.location.href.split("?")[1];
      let { token, user_key } = queryString.parse(params);
      if (user_key) {
        user_key = user_key.toString();
      }
      if (token) {
        token = token.toString();
        localStorage.clear();
        authConfig.sbxSessionService.updateToken(token.toString());
      }
      const storedToken = token || localStorage.getItem("token");
      if (storedToken) {
        const response = await validateToken(storedToken, user_key);
        if (response) {
          onSuccess(response);
          return setLogged(true);
        }
      }
      setLogged(false);
      onError();
    };
    run();
  }, []);

  return logged ? <>{children}</> : null;
};

export { Auth, initialize };

import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided,} from 'react-beautiful-dnd';
import FormBuilderList from './FormBuilderList';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../Store/Reducers';
import {actions} from '../../../Store/FormBuilder/Slice';
import CreateFieldModal from '../../Modals/CreateFieldModal/CreateFieldModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {duplicateField, getFieldByKey, getLabel, INPUT_BUILDER_TYPES,} from './data';
import {faArrowLeft, faComment, faEdit,} from '@fortawesome/free-solid-svg-icons';
import {getFieldName} from "../../../Utils/Utils";
import {getFile} from "../../../Services";
import IField from "../../../Models/FormBuilder/IField";
import {uploadFragment} from "../../../Services/FragmentService";

const FormBuilderComponent = (): ReactElement => {
  const dispatch = useDispatch();

  const [text, setText] = useState("");

  const {
    formBuilder: {
      currentForm, parentFormId, currentField, modalOpen, parentField
    }, sectionReducer: {document}
  } = useSelector(
    (state: RootState) => state,
  );

  const getFileDocument = useCallback(async (key: string) => {
    const res = await getFile(key);
    return res;
  }, []);

  useEffect(() => {
    if (document) {
      getFileDocument(document.document_key).then(setText);
    }
  }, [getFileDocument, document]);


  const onFieldCreateSuccess = async (oldField?: IField) => {
    let newField = Object.assign({}, (currentField || parentField));
    if (newField) {
      newField.name = getFieldName(newField, true);
      ;
      dispatch(
        actions.getAddNewField({form_id: currentForm.id, field: newField}),
      );
      if (oldField && newField && text.includes(`${oldField.name}`)) {
        const newFragment = text.replace(new RegExp(`${oldField.name}`, "g"), `${newField.name}`);
        await uploadFragment(newFragment, document);
        setText(newFragment);
      }
    }
  };

  const onFieldCreateError = () => {
    dispatch(actions.setAddNewField());
  };

  const setParentFormAsMain = () => {
    if (parentFormId) {
      dispatch(actions.resetCurrentField())
      dispatch(actions.getParentForm({parentFormId}));
    }
  };

  const createNewField = (
    form_id: string,
    indexToInsert: number,
    key: string,
  ) => {
    let field = getFieldByKey(key);
    field = duplicateField(field);
    field.sort_index = indexToInsert;
    field.name = getFieldName(field, true);
    dispatch(actions.getAddNewField({form_id: form_id, field}));
  };

  const dragEnd = (result: DropResult, provided: ResponderProvided) => {
    const {destination, source} = result;
    if (
      !destination ||
      destination.droppableId === 'input-maker' ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      return dispatch(
        actions.getSortFields({
          source: source.index,
          destination: destination.index,
        }),
      );
    } else {
      if (source.droppableId === 'input-maker') {
        const id = destination.droppableId.split('-')[1];
        return createNewField(id, destination.index, result.draggableId);
      }
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={dragEnd}>
        <div className="row">
          <div className="col-4">
            <Droppable droppableId="input-maker" type="INPUT">
              {(providedDroppable, _) => (
                <>
                  <h3>Campos</h3>
                  <div className="card shadow-sm">
                    <div
                      ref={providedDroppable.innerRef}
                      {...providedDroppable.droppableProps}
                      className="card-body"
                      style={{
                        height: 'calc(100vh - 280px)',
                        overflowY: 'auto',
                      }}>
                      {INPUT_BUILDER_TYPES.map(item => {
                        return (
                          <div key={item.key}>
                            <h5>{item.name}</h5>
                            <div className="border">
                              {item.inputs.map((input, index) => (
                                <Draggable
                                  draggableId={input.key}
                                  index={index}
                                  key={input.key}>
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        className={`p-2 bg-white ${
                                          snapshot.isDragging ? 'shadow-sm' : ''
                                        }`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <FontAwesomeIcon
                                          icon={input.field.icon || faComment}
                                          className="mr-2"
                                        />
                                        {getLabel(input.field)}
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              ))}
                            </div>
                          </div>
                        );
                      })}
                      {providedDroppable.placeholder}
                    </div>
                  </div>
                </>
              )}
            </Droppable>
          </div>
          <div className="col-8">
            <div
              className={`d-flex ${
                parentFormId
                  ? 'justify-content-between align-items-center'
                  : 'justify-content-center'
              }`}>
              {parentFormId && (
                <button type="button"
                        onClick={setParentFormAsMain}
                        className="btn btn-primary btn-sm">
                  <FontAwesomeIcon icon={faArrowLeft}/>
                </button>
              )}
              <h3>{parentField?.label || "Formulario"}</h3>
              {parentFormId && <FontAwesomeIcon icon={faEdit}
                                                className="clickable"
                                                onClick={() => {
                                                  if (parentField) {
                                                    dispatch(actions.updateParentField({
                                                      field: parentField,
                                                      openModal: true
                                                    }))
                                                  }
                                                }}
              />}
            </div>
            {currentForm && <FormBuilderList form={currentForm}/>}
          </div>
        </div>
      </DragDropContext>
      {modalOpen && (
        <CreateFieldModal
          onSuccess={onFieldCreateSuccess}
          onError={onFieldCreateError}
        />
      )}
    </>
  );
};

export default FormBuilderComponent;

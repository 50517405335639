import React, {useCallback, useEffect, useState} from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import IField from "../../../Models/FormBuilder/IField";
import {actions} from "../../../Store/Section/Slice";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getFieldForm, getFieldsByIdService} from "../../../Services";
import AddRule from "./AddRuleComponent";
import {getCondition} from "../../../Utils/Utils";
import ListRules from "./ListRulesComponent";
import {FieldType} from "../../../Models/FieldTypes";

interface Props {
  value?: string;
  onChange: (e: string) => void;
  onAction?: () => void;
  showAction?: boolean;
  showRules?: boolean;
  labelAction?: String;
  visibleCurrentSection?: boolean;
}

export interface RuleData {
  field: IField;
  value: any;
  condition: string;
  operator?: '&&' | '||';

}

export class Opt {
  label!: string;
  value!: any;
  data!: any;

  constructor(v: string, l: any, d: any) {
    this.label = l;
    this.value = v;
    this.data = d;
  }
}

const RuleComponent = ({value, onChange, onAction, labelAction, showAction = false, visibleCurrentSection = true, showRules = true}: Props) => {

  const {workflowId} = useParams();
  const dispatch = useDispatch();
  const [section, setSection] = useState<Opt | null>(null);
  const [field, setField] = useState<Opt | null>(null);
  const [subField, setSubfield] = useState<Opt | null>(null);
  const [fields, setFields] = useState<IField[]>([]);
  const [subFields, setSubFields] = useState<IField[]>([]);
  const [loading, setLoading] = useState(false);
  const [rules, setRules] = useState<RuleData[]>([]);
  const [valueRule] = useState(value || "");

  useEffect(() => {
    if (workflowId) {
      dispatch(actions.getSections(workflowId));
    }
  }, [dispatch, workflowId]);

  useEffect(() => {
    if (valueRule) {
      const defaultValues: string[] = [];
      const vArrOr = valueRule.split(' || ');
      vArrOr.forEach(a => {
        const vArrAnd = a.split(' && ');
        vArrAnd.forEach(a => defaultValues.push(a));
      });

      if (defaultValues.length) {
        getItems(defaultValues);
      }
    }

    async function getItems(values: Array<string>) {
      const rulesData = await Promise.all(values.map(async (a, i) => {
        const cnd = getCondition(a);
        let rvl: any = {};
        if (cnd) {
          const rv = a.split(`${cnd.value}`);
          if (rv.length === 2) {
            if (i) {
              rvl.operator = '||';
              if (valueRule.includes(`&& ${a}`)) {
                rvl.operator = '&&';
              }
            }
            rvl.condition = cnd.value;
            const formFieldId = rv[0].split("_");
            const res = await getFieldForm(formFieldId[0], formFieldId[1]);
            if (res.success) {
              rvl.field = res.item
            } else {
              rvl.field = {name: rv[0], label: rv[0]}
            }
            rvl.value = rv[1].includes('"') ? rv[1].split('"').join("") : parseInt(rv[1]);
            return rvl;
          }
        }
        return null;
      }))
      setRules(rulesData.filter(a => a));
    }
  }, [valueRule]);

  const callBack = useCallback(onChange, [])

  useEffect(() => {
    let setDefault = '';
    rules.forEach(a => {
      setDefault +=
        (a.operator ? ` ${a.operator} ` : '') +
        `${a.field.form_id}_${a.field.id}_${a.field.name}` +
        `${a.condition}` + (typeof a.value === "string" ? `"${a.value}"` : a.value);
    });
    callBack(setDefault);
  }, [callBack, rules])


  async function getFields(e: Opt) {
    setField(null);
    if (e.value) {
      setLoading(true);
      const resFields = await getFieldsByIdService(e.value)
      setFields(resFields);
    }
    setSection(e);
    setLoading(false);
  }

  async function getSubFields(e: Opt) {
    if (e && (e.data.field_type === FieldType.table || e.data.field_type === FieldType.formGroup)) {
      setSubfield(null);
      setLoading(true);
      const resFields = await getFieldsByIdService(e.data.detail_form_id);
      setSubFields(resFields);
      setLoading(false);
    }
    setField(e);
  }


  function deleteRule(i: number) {
    const r = new Array(...rules);
    r.splice(i, 1);
    if (r[0]) r[0].operator = undefined;
    setRules(r);
  }

  function editOperator(operator: "&&" | "||", i: number) {
    const r = new Array(...rules);
    r[i].operator = operator;
    setRules(r);
  }

  return (
    <Card className="mb-2 shadow-sm text-left">
      <CardHeader>
        <h5>Reglas</h5>
      </CardHeader>
      <CardBody>
        <AddRule rules={rules}
                 subFields={subFields}
                 subField={subField}
                 setSubField={setSubfield}
                 visibleCurrentSection={visibleCurrentSection}
                 setField={getSubFields}
                 section={section}
                 setRules={setRules}
                 fields={fields}
                 field={field}
                 getFields={getFields}
                 loading={loading}/>

        {showRules && <ListRules onChangeOperator={editOperator} rules={rules} deleteRule={deleteRule}/>}
        {showAction && <div className="text-right pt-4">
          <button className="btn btn-primary" onClick={onAction}>{labelAction || "Guardar"}</button>
        </div>}
      </CardBody>
    </Card>
  )
}


export default RuleComponent;

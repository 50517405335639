import React, {useEffect, useState} from "react";
import TableComponent from "../../Shared/TableComponent/TableComponent";
import {Head} from "../../../Models/Table";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../../Store/FormBuilder/Slice";
import {useHistory, useParams} from 'react-router-dom';
import {RootState} from "../../../Store/Reducers";
import ModalComponent from "../../Shared/ModalComponent";
import {FormGroup, Input, Label} from "reactstrap";
import RuleComponent from "../../Shared/RuleComponent/RuleComponent";
import ConfirmModal from "../../Modals/ConfirmModal";

const header: Head[] = [
  {type: "String", header: "Nombre", field: "name"},
  {type: "Date", header: "Creado", field: "created"},
  {type: "Date", header: "Atualizado", field: "updated"},

]

const initialModal = {open: false, data: null}
export default () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const {sectionId, workflowId} = useParams();
  const [newForm, setNewForm] = useState(initialModal);
  const [removeForm, setRemoveForm] = useState<{ open: boolean, data: any }>(initialModal);
  const {
    formBuilder: {
      task: {attachments, id: task_id, process_id}, state
    }
  } = useSelector((state: RootState) => state);


  useEffect(() => {
    if (state === "resolved") {
      setNewForm(initialModal);
      setRemoveForm(initialModal);
    }
  }, [state])

  useEffect(() => {
    if (sectionId && workflowId) {
      dispatch(actions.getTask({docKey: workflowId, secKey: sectionId}))
    }
  }, [dispatch, sectionId, workflowId])

  function detail(e: any) {
    history.push(`/sections/${workflowId}/${sectionId}/form/${e.attachment_id}`)
  }

  function deleteForm({id, attachment_id}: any) {
    dispatch(actions.deleteForm({process_id, task_id, attachment_id: id, form_id: attachment_id}))
  }

  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4>Formularios</h4>
          <button onClick={() => setNewForm({open: true, data: null})}
                  className="btn btn-primary">Crear un nuevo formulario
          </button>
        </div>
        <div className="card-body">
          <TableComponent rows={attachments}
                          pagination
                          filter
                          loading={state === "pending" || state === "creating_form" || state === "loading"}
                          onEvent={detail}
                          onRemove={e => setRemoveForm({open: true, data: e})}
                          onEdit={e => setNewForm({open: true, data: e})}
                          labelEvent="Detalle"
                          headers={header}/>
        </div>
      </div>

      <ConfirmModal onSuccess={() => deleteForm(removeForm.data)}
                    description={
                      <p>
                        ¿Desea eliminar el formulario <b>{removeForm.data?.name}?</b>
                      </p>
                    }
                    isOpen={removeForm.open}
                    setOpen={e => setRemoveForm(initialModal)}
                    title={"Remover formulario " + removeForm.data?.name}
                    loading={state === "loading"}/>

      {newForm.open && <ModalForm data={newForm.data}
                                  loading={state === "loading"}
                                  taskId={task_id}
                                  onClose={setNewForm}
                                  processId={process_id}/>}
    </>
  )
}

const ModalForm = ({onClose, processId, taskId, data, loading}: {
  onClose: (e: { open: boolean, data: any }) => void,
  processId: number,
  taskId: number,
  data: null | any,
  loading: boolean
}) => {

  const dispatch = useDispatch();
  const [name, setName] = useState(data?.name || "");
  const [rule, setRule] = useState(data?.rule || "");


  function addOrUpdateForm() {
    if (!data) {
      if (name) {
        dispatch(actions.setNewForm({name, workflow_id: processId, task_id: taskId, rule}));
      }
    } else {
      dispatch(actions.updateForm({...data, name, rule, process_id: processId, task_id: taskId}))
    }
  }

  return (
    <ModalComponent isOpen={true}
                    isLoading={loading}
                    size="xl"
                    title={data ? "Actualizar formulario" : "Nuevo formulario"}
                    onClose={() => onClose(initialModal)}
                    onSuccess={addOrUpdateForm}
                    toggle={() => onClose(initialModal)}>
      <div style={{minHeight: "calc(100vh - 250px)"}}>
        <FormGroup>
          <Label htmlFor="name_new_form">Nombre del formulario</Label>
          <Input id="name_new_form" value={name} onChange={e => setName(e.target.value)}/>
        </FormGroup>
        <RuleComponent value={rule} onChange={setRule} visibleCurrentSection={false}/>
      </div>
    </ModalComponent>
  )
}

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FormBuilderState} from './Types';
import {plainToClass, plainToClassFromExist} from 'class-transformer';
import Form from '../../Models/FormBuilder/Form';
import IField from '../../Models/FormBuilder/IField';
import {sortArray} from '../../Utils/Utils';

const initialState: FormBuilderState = {
  modalOpen: false,
  state: 'idle',
  currentForm: plainToClass(Form, {
    id: 1,
    name: 'test',
    fields: [],
    domain: 0,
  }),
  parentFormId: null,
  task: {attachments: [], id: 0, process_id: 0},
  fields: [],
};

const formBuilder = createSlice({
  name: 'formBuilder',
  initialState,
  reducers: {
    getChildForm(
      state,
      action: PayloadAction<{
        childFormId: string | number;
        parentFormId: string | number;
      }>,
    ) {
      state.state = 'pending';
      state.parentFormId = action.payload.parentFormId;
    },
    getParentForm(
      state,
      action: PayloadAction<{ parentFormId: string | number }>,
    ) {
      state.state = 'pending';
      state.parentFormId = null;
    },
    onChangeFormBuilder(state, action: PayloadAction<IField[]>) {
      state.state = 'pending';
      state.currentForm = plainToClassFromExist(state.currentForm, {
        fields: action.payload,
      });
    },
    modifyField(state, action: PayloadAction<{ key: string; value: any }>) {
      const payload = action.payload;
      const obj: any = Object.assign({}, state.currentField || state.parentField);
      obj[payload.key] = payload.value;
      if (state.currentField) {
        state.currentField = obj as IField;
      } else {
        state.parentField = obj as IField;
      }
    },
    getSortFields(
      state,
      {
        payload: {source, destination},
      }: PayloadAction<{ source: number; destination: number }>,
    ) {
      const obj = Object.assign({}, state.currentForm);
      obj.fields = sortArray(obj.fields, source, destination).map(
        (field, index) => {
          field.sort_index = index;
          return field;
        },
      );
      state.currentForm = obj;
    },
    getAddNewField(state, action) {
      state.state = 'creating_field';
    },
    setAddNewField(state) {
      state.modalOpen = false;
      state.state = 'resolved';
    },
    setCurrentField(state, action: PayloadAction<IField>) {
      state.modalOpen = true;
      state.currentField = action.payload;
    },
    resetCurrentField(state) {
      state.currentField = undefined;
      state.parentField = undefined;
    },
    updateParentField(state, action: PayloadAction<{ field: IField, openModal: boolean }>) {
      state.parentField = action.payload.field;
      state.currentField = undefined;
      state.modalOpen = action.payload.openModal;
    },
    getFormBuilder(state, action: PayloadAction<{ id: number | string }>) {
      state.state = 'loading';
    },
    getDeleteField(
      state,
      action: PayloadAction<{ formId: number; field: IField }>,
    ) {
      state.state = 'removing_field';
    },
    setDeleteField(state, action: PayloadAction<Form>) {
      state.state = 'resolved';
      state.currentForm = action.payload;
    },
    setFormBuilder(state, action: PayloadAction<Form>) {
      state.state = 'resolved';
      state.currentForm = action.payload;
    },
    getTask(
      state,
      action: PayloadAction<{
        docKey: string;
        secKey: string;
        w?: { workflow_id: number; task_id: number };
      }>,
    ) {
      state.state = 'loading';
    },
    setTask(state, action: PayloadAction<any>) {
      state.state = 'resolved';
      state.task = action.payload;
    },
    setNewForm(
      state,
      action: PayloadAction<{
        workflow_id: number;
        task_id: number;
        name: string;
        rule: string;
      }>,
    ) {
      state.state = 'creating_form';
    },
    updateForm(state, action: PayloadAction<any>) {
      state.state = 'loading';
    },
    deleteForm(
      state,
      action: PayloadAction<{
        process_id: number;
        task_id: number;
        form_id: number;
        attachment_id: number;
      }>,
    ) {
      state.state = 'loading';
    },
    getFieldsBySections(state, action: PayloadAction<number>) {
      state.state = 'loading';
    },
    setFieldsBySections(
      state,
      action: PayloadAction<{
        process_id: number;
        task_id: number;
        form_id: number;
        attachment_id: number;
      }>,
    ) {
      state.state = 'loading';
    },
    rejectAction(state) {
      state.state = 'rejected';
    },
    restart(state) {
      state.modalOpen = initialState.modalOpen;
      state.state = initialState.state;
      state.currentForm = initialState.currentForm;
      state.currentField = initialState.currentField;
    },
  },
});

export const actions = formBuilder.actions;

export default formBuilder.reducer;

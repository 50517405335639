import React, {useState} from 'react';

import {FormGroup, Input, Label} from 'reactstrap';
import {actions} from '../../../Store/FormBuilder/Slice';
import {useDispatch, useSelector} from 'react-redux';
import IField from '../../../Models/FormBuilder/IField';
import {RootState} from '../../../Store/Reducers';
import Select from 'react-select';
import RuleComponent from "../../Shared/RuleComponent/RuleComponent";
import ToggleCheckbox from "../../Shared/ToggleCheckbox";
import {getFieldName} from "../../../Utils/Utils";


interface IProps {
  field: IField;
}

const CreateFieldForm = ({field}: IProps) => {
  const dispatch = useDispatch();
  const {
    dataListReducer: {
      list
    }
  } = useSelector((state: RootState) => state);
  const dataLists = list.map(dl => ({value: dl.id, label: dl.name}));
  const dataList = dataLists.find(dl => dl.value === field.list_provider_id);
  const [toggle, setToggle] = useState<boolean>((!(!field.visible_when || field.visible_when === "true")));
  const handleSelectChange = (data: any, input: any) => {
    const key = input.name;
    const value = Array.isArray(data) ? {columns: data.map(d => d.value)} : data.value;
    dispatch(actions.modifyField({key, value}));
  };

  const handleChange = ({target}: any) => {
    const key = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    dispatch(actions.modifyField({key, value}));
  };

  const SHARED = ['name', 'label', 'size', 'hint', 'visible_when'];
  const TEXT = [
    ...SHARED,
    'placeholder',
    'default_value',
    'required',
    'read_only',
  ];
  const OPTIONS = [
    ...SHARED,
    'list_provider_id',
    'required',
    'read_only',
  ];
  const DATE = [...SHARED, 'default_value', 'required', 'read_only'];
  // const TABLE = [...SHARED, 'required', 'read_only', 'single_value'];
  const FILE = [...SHARED, 'required', 'read_only', 'single_value'];
  const FORM_GROUP = [...SHARED, 'required'];
  const TABLE = [...SHARED, "format_rules_definition", "sub_type"]
  const DATE_RANGE = [...SHARED, "required"]
  const listByFieldType: { [key: string]: string[] } = {
    SMALL_TEXT: TEXT,
    LARGE_TEXT: TEXT,
    OPTIONS,
    DATE,
    FILE,
    FORM_GROUP,
    TABLE,
    DATE_RANGE,
  };
  const availableAttributes = listByFieldType[field.field_type || 'SMALL_TEXT'];

  const attributes: { [key: string]: any } = {
    name: (
      <FormGroup key={10}>
        <Label htmlFor="field_attr__name" className="font-weight-bold mb-0">
          Nombre
        </Label>
        <Input
          type="text"
          name="name"
          id="field_attr__name"
          value={getFieldName(field) || ""}
          onChange={handleChange}
        />
      </FormGroup>
    ),
    label: (
      <FormGroup key={20}>
        <Label htmlFor="field_attr__label" className="font-weight-bold mb-0">
          Etiqueta
        </Label>
        <Input
          type="text"
          name="label"
          id="field_attr__label"
          value={field.label || ""}
          onChange={handleChange}
        />
      </FormGroup>
    ),
    size: (
      <FormGroup key={30}>
        <Label htmlFor="field_attr__size" className="font-weight-bold mb-0">
          Tamaño
        </Label>
        <Input
          type="number"
          name="size"
          id="field_attr__size"
          value={field.size}
          onChange={handleChange}
          max={12}
          min={1}
        />
      </FormGroup>
    ),
    placeholder: (
      <FormGroup key={40}>
        <Label
          htmlFor="field_attr__placeholder"
          className="font-weight-bold mb-0">
          Placeholder
        </Label>
        <Input
          type="text"
          name="placeholder"
          id="field_attr__placeholder"
          value={field.placeholder || ""}
          onChange={handleChange}
        />
      </FormGroup>
    ),
    default_value: (
      <FormGroup key={50}>
        <Label
          htmlFor="field_attr__default_value"
          className="font-weight-bold mb-0">
          Valor por defecto
        </Label>
        <Input
          type="text"
          name="default_value"
          id="field_attr__default_value"
          value={field.default_value || ""}
          onChange={handleChange}
        />
      </FormGroup>
    ),
    hint: (
      <FormGroup key={60}>
        <Label htmlFor="field_attr__hint" className="font-weight-bold mb-0">
          Descripción
        </Label>
        <textarea
          className="form-control"
          name="hint"
          id="field_attr__hint"
          value={field.hint || ''}
          onChange={handleChange}
        />
      </FormGroup>
    ),
    visible_when: (
      <FormGroup>
        <ToggleCheckbox id="visible_when_toggle"
                        className="mb-2"
                        value={toggle}
                        labelInfo="Mostrar en caso de:"
                        onChange={setToggle}/>

        {toggle && <RuleComponent value={field.visible_when || ""}
                                  onChange={e => handleChange({target: {value: e, name: "visible_when"}})}/>}
      </FormGroup>
    ),
    required: (
      <FormGroup check key={80}>
        <Label
          htmlFor="field_attr__required"
          check
          className="font-weight-bold">
          <Input
            type="checkbox"
            name="required"
            id="field_attr__required"
            checked={field.required}
            onChange={handleChange}
          />
          Requerido?
        </Label>
      </FormGroup>
    ),
    single_value: (
      <FormGroup check key={90}>
        <Label
          htmlFor="field_attr__single_value"
          check
          className="font-weight-bold">
          <Input
            type="checkbox"
            name="single_value"
            id="field_attr__single_value"
            checked={field.single_value}
            onChange={handleChange}
          />
          Valor unico?
        </Label>
      </FormGroup>
    ),
    read_only: (
      <FormGroup check key={100}>
        <Label
          htmlFor="field_attr__read_only"
          check
          className="font-weight-bold">
          <Input
            type="checkbox"
            name="read_only"
            id="field_attr__read_only"
            checked={field.read_only}
            onChange={handleChange}
          />
          Solo lectura?
        </Label>
      </FormGroup>
    ),
    list_provider_id: (
      <FormGroup key={110}>
        <Label
          htmlFor="field_attr__list_provider_id"
          className="font-weight-bold">
          Proveedor de datos
        </Label>
        <Select
          onChange={handleSelectChange}
          name="list_provider_id"
          defaultValue={dataList}
          id="field_attr__list_provider_id"
          options={dataLists}
        />
      </FormGroup>
    ),
    format_rules_definition: (
      <FormGroup>
        <Label>Columnas</Label>
        <Select name="format_rules_definition"
                onChange={handleSelectChange}
                isMulti
                options={field.detail_form?.fields.map(f => ({value: f.name, label: f.label, data: f}))
                || []}
                id="field_attr__format_rules_definition"/>
      </FormGroup>
    ),
    sub_type: (
      <FormGroup check key={111}>
        <Label
          htmlFor="field_attr__sub_type"
          check
          className="font-weight-bold">
          <Input
            type="checkbox"
            name="sub_type"
            id="field_attr__sub_type"
            checked={field.sub_type === "LIST"}
            onChange={() => handleChange({
              target: {
                name: "sub_type",
                value: (!field.sub_type) ? "LIST" : null
              }
            })
            }
          />
          Lista ?
        </Label>
      </FormGroup>
    )
  };

  return <div className="row">
    {availableAttributes.map((key, i) => <div key={i} className={key === "visible_when" ? "col-12" : "col-6"}>
      {attributes[key]}
    </div>)}
  </div>;
};

export default CreateFieldForm;

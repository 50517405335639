import Content from '../../Models/Content';
import Folder from '../../Models/Folder';
import Form from '../../Models/FormBuilder/Form';
import IField from '../../Models/FormBuilder/IField';

export const GET_FORM_BUILDER = 'formBuilder/getFormBuilder';
export const GET_CHILD_FORM = 'formBuilder/getChildForm';
export const GET_PARENT_FORM = 'formBuilder/getParentForm';
export const GET_FIELDS_BY_SECTIONS = 'formBuilder/getParentForm';
export const GET_ADD_NEW_FIELD = 'formBuilder/getAddNewField';
export const GET_DELETE_FIELD = 'formBuilder/getDeleteField';
export const GET_TASK = 'formBuilder/getTask';
export const SET_NEW_FORM = 'formBuilder/setNewForm';
export const UPDATE_FORM = 'formBuilder/updateForm';
export const DELETE_FORM = 'formBuilder/deleteForm';

export interface SetFolderList {
  content: Content[];
  folder: Folder;
}

export interface FormBuilderState {
  modalOpen: boolean;
  currentForm: Form;
  state: string;
  task: { attachments: Form[]; id: number; process_id: number };
  currentField?: IField;
  parentField?:IField
  fields: IField[];
  parentFormId: number | string | null;
}

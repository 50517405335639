import React from 'react';
import {ModalProps} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../Store/DataList/Slice";
import DataList from "../../Models/FormBuilder/DataList";
import ModalComponent from "../Shared/ModalComponent";
import {FormContextValues, useForm} from "react-hook-form";
import {RootState} from "../../Store/Reducers";
import FaSpinnerComponent from "../Shared/FaSpinnerComponent";

interface Props extends ModalProps {
  dataList?: DataList
}

const CreateDataListModal: React.FC<Props> = (props) => {
  const dl: DataList = props.dataList || {} as DataList;
  const isUpdating = Boolean(dl.id);
  const title = isUpdating ? "Edición de Proveedor" : "Creación de proveedor"
  const {register, handleSubmit}: FormContextValues<DataList> = useForm<DataList>({
    defaultValues: Object.assign({value_field: 'value', label_field: 'label'}, dl)
  });
  const state = useSelector((state: RootState) => state.dataListReducer.state);
  const dispatch = useDispatch();

  const onsubmit = (dataList: any) => {
    const dlRef = Object.assign({...dl}, dataList);
    delete dlRef.options;
    delete dlRef.key;
    if (isUpdating) {
      dispatch(actions.getUpdateDataList(dlRef));
    } else {
      dispatch(actions.getAddDataList(dlRef));
    }
    if (props.onClosed) {
      props.onClosed();
    }
  };

  const isSavingDataList = () => state === "savingDataList";

  return <ModalComponent {...props} title={title} noFooter>
    <form onSubmit={handleSubmit(onsubmit)}>
      <div className="form-group">
        <label htmlFor="name" className="form-label">Nombre</label>
        <input type="text" id="name" name="name" ref={register({required: true})} readOnly={isUpdating} className="form-control" />
      </div>
      {/*<div className="form-group">*/}
      {/*  <label htmlFor="query" className="form-label">Query</label>*/}
      {/*  <input type="text" id="query" name="query" ref={register()} className="form-control" />*/}
      {/*</div>*/}
      {/*<div className="form-group">*/}
      {/*  <label htmlFor="provider_type" className="form-label">Tipo de proveedor</label>*/}
      {/*  <select id="provider_type" name="provider_type" ref={register({required: true})} className="form-control" >*/}
      {/*    <option>DATABASE</option>*/}
      {/*    <option>URL</option>*/}
      {/*  </select>*/}
      {/*</div>*/}
      <div className="form-group">
        <label htmlFor="value_field" className="form-label">Referencia valor</label>
        <input type="text" id="value_field" name="value_field" readOnly ref={register({required: true})} className="form-control" />
      </div>
      <div className="form-group">
        <label htmlFor="label_field" className="form-label">Referencia etiqueta</label>
        <input type="text" id="label_field" name="label_field" readOnly ref={register({required: true})} className="form-control" />
      </div>
      {/*<div className="form-group">*/}
      {/*  <label htmlFor="parent_field" className="form-label">Referencia del padre</label>*/}
      {/*  <input type="text" id="parent_field" name="parent_field" ref={register()} className="form-control" />*/}
      {/*</div>*/}
      <div className="">
        <button type="button" onClick={props.onClosed} className="btn btn-danger mr-3" disabled={isSavingDataList()}>Cancelar</button>
        <button className="btn btn-primary" disabled={isSavingDataList()}>
          {isSavingDataList() && <FaSpinnerComponent />} Guardar proveedor
        </button>
      </div>
    </form>
  </ModalComponent>
};

export default CreateDataListModal;

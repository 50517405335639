import React, {useState} from "react";
import ModalComponent from "../../Shared/ModalComponent";
import Section from "../../../Models/Section";
import {useDispatch} from "react-redux";
import {actions} from "../../../Store/Section/Slice";
import EditorTextComponent from "../../Main/EditorComponent/EditorTextComponent";

interface Props {
  sectionData: { open: boolean, section: Section, workFLowId: string },
  onToggle: () => void;
}

export default ({sectionData: {open, section, workFLowId}, onToggle}: Props) => {

  const dispatch = useDispatch();

  const [data, setData] = useState({name: section.name, description: section.description})

  function updateSection() {
    if (section.key) {
      dispatch(actions.renameSection({name: data.name, description: data.description, key: section.key, workFLowId}));
      onToggle();
    }
  }

  return (
    <ModalComponent
      size="lg"
      isOpen={open}
      toggle={onToggle}
      onClose={onToggle}
      onSuccess={updateSection}>
      <h4><b>Editar sección</b></h4>
      <div className="form-group">
        <label htmlFor="edit_name_section">Nombre:</label>
        <input id="edit_name_section"
               className="form-control"
               type="text"
               value={data.name}
               onChange={e => setData({...data, name: e.target.value})}
        />
      </div>
      <div className="form-group">
        <label htmlFor="">Descripción</label>
        <EditorTextComponent
          id="toolbar-modal-editor"
          data={data.description}
          setData={e => setData({...data, description: e})}/>
      </div>
    </ModalComponent>
  )
}

import React from "react";
import ModalComponent from "../../Shared/ModalComponent";
import Document from "../../../Models/Document";
import {actions} from "../../../Store/Folder/Slice";
import {useDispatch} from "react-redux";

interface Props {
  setOpen: (value: boolean) => void,
  open: boolean,
  data: Document;
}

export default ({data, setOpen, open}: Props) => {

  const dispatchRedux = useDispatch();

  const deleteItem = (i: Document) => {
    dispatchRedux(actions.deleteFolderList(i))
  };

  return (
    <ModalComponent noFooter isOpen={open} toggle={() => setOpen(false)}>
      <form onSubmit={event => {
        event.preventDefault();
        if (data) {
          deleteItem(data);
        }
        setOpen(false);
      }}>

        <p>¿Desea eliminar el documento <b>{data.name}</b>?</p>

        <div className="text-center">
          <button type="button" onClick={() => setOpen(false)} className="btn btn-danger mr-3">Cancelar</button>
          <button
            className="btn btn-primary">Eliminar Documento
          </button>
        </div>
      </form>
    </ModalComponent>
  )
}

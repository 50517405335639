import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Form from '../../Models/FormBuilder/Form';
import Fragment from '../../Models/Fragment';
import Section from '../../Models/Section';
import Document from '../../Models/Document';
import {FormBuilderState} from './Types';
import IField from '../../Models/FormBuilder/IField';

const initialState: FormBuilderState = {
  fragments: [],
  fields: [],
  subFields: [],
  forms: [],
  sections: [],
  state: 'idle'
};

const fragments = createSlice({
  name: 'fragment',
  initialState,
  reducers: {
    restart(state) {
      return initialState;
    },
    getFragments(state, aciton: PayloadAction<string>) {
      state.state = 'pending';
    },
    getCurrentFragment(state, action: PayloadAction<string>) {
      state.state = "pending";
    },
    setCurrentFragment(state, action: PayloadAction<Fragment>) {
      state.fragment = action.payload;
      state.state = "resolved";
    },
    setFragments(state, action: PayloadAction<Fragment[]>) {
      state.state = 'resolved';
      state.fragments = action.payload.sort((a, b) => a.sort_index - b.sort_index);
    },
    fragmentCreated(state) {
      state.state = "fragment_created";
    },
    getFragment(state, action: PayloadAction<string>) {
      state.state = 'pending';
    },
    setFragment(state, action: PayloadAction<Fragment>) {
      state.state = 'resolved';
      state.fragment = action.payload;
    },
    getAddFragment(state, aciton: PayloadAction<Section>) {
      state.state = 'pending';
    },
    updateFragment(state, action: PayloadAction<Fragment>) {
      state.state = 'pending';
    },
    setAddFragment(state, action: PayloadAction<Fragment>) {
      state.state = 'resolved';
      state.fragment = action.payload;
    },
    getSections(state, action: PayloadAction<string>) {
      state.state = 'pending';
    },
    setSections(state, action: PayloadAction<Section[]>) {
      state.state = 'resolved';
      state.sections = action.payload;
    },
    onSortEnd(state, action: PayloadAction<{ oldRow: Fragment, newRow: Fragment }>) {
      state.state = "sorting";
    },
    getForms(
      state,
      action: PayloadAction<{ workflowId: number; taskId: number }>,
    ) {
      state.state = 'pending';
    },
    setForms(state, action: PayloadAction<Form[]>) {
      state.state = 'resolved';
      state.forms = action.payload;
    },
    removeFragment(state, action: PayloadAction<Fragment>) {
      state.state = 'pending';
    },
    getFields(state, action: PayloadAction<number>) {
      state.state = 'pending';
    },
    setFields(state, action: PayloadAction<IField[]>) {
      state.state = 'resolved';
      state.fields = action.payload;
    },
    getSubFields(state, action: PayloadAction<number>) {
      state.state = 'pending';
    },
    setSubFields(state, action: PayloadAction<IField[]>) {
      state.state = 'resolved';
      state.subFields = action.payload;
    },
    setDocument(state, action: PayloadAction<Document>) {
      state.state = 'resolved';
      state.document = action.payload;
    },
  },
});

export const actions = {...fragments.actions};

export default fragments.reducer;

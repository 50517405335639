import React, {ReactElement, useEffect, useState} from 'react';
import './App.scss';
import {Auth, initialize} from 'sbx-react-auth-hoc';
import ScrollToTop from './Components/Shared/ScrollToTop/ScrollToTopComponent';
import {Route, Switch} from 'react-router-dom';
import {getParameterByName} from './Utils/Utils';
import {sbxCoreService, sbxSessionService, setHeaderTokenForm,} from './Network';
import FolderListComponent from './Components/Main/FolderListComponent/FolderListComponent';
import SectionComponent from './Components/Main/Section/SectionComponent';
import CategoryComponent from './Components/Main/Category/CategoryComponent';
import {Container} from 'reactstrap';
import SideBarComponent from './Components/Shared/SideBarComponent/SideBarComponent';
import DataListMainComponent from './Components/Main/DataList/DataListMainComponent';
import {setHeaderToken} from './Network/ProcessNetwork';
import {logout} from "./Services";
import NavbarComponent from "./Components/Shared/NavbarComponent";
import UsersListComponent from "./Components/Main/UsersComponent/UsersListComponent";

declare let window: Window;
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Sample Page',
    component: FolderListComponent,
  },
  {
    path: '/sections',
    name: 'Sample Page',
    component: SectionComponent,
  },
  {
    path: '/categories',
    name: 'Sample Page',
    component: CategoryComponent,
  },
  {
    path: '/data_list',
    name: 'Sample Page',
    component: DataListMainComponent,
  },
  {
    path: '/users',
    name: 'Sample Page',
    component: UsersListComponent,
  },
];

export default function App(): ReactElement {
  const [isOpen, setOpen] = useState(true);
  const toggle = () => setOpen(!isOpen);

  const token = getParameterByName('token');
  const access_token = getParameterByName('access_token');

  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        render={props => <route.component {...props} />}
      />
    ) : null;
  });

  useEffect(() => {
    if (token) {
      window.localStorage.setItem('token', token);
    }
    if (access_token) {
      window.localStorage.setItem('access_token', access_token);
      setHeaderToken(access_token);
      setHeaderTokenForm(access_token);
    }
  }, [token, access_token]);

  useEffect(() => {
    if (!localStorage.getItem("access_token") || !localStorage.getItem("token")) {
      logout();
    }
  }, [])

  initialize(
    sbxCoreService,
    sbxSessionService,
    'b95499c3-55a4-4774-b46a-5c64089d829c',
  );

  return (
    <Auth
      onError={() => {
        //window.location.href = 'https://sbxcloud.com/www/simplelegaldev/app/#/';
      }}
      onSuccess={() => {
        // console.log(response);
      }}>
      <ScrollToTop>
        <NavbarComponent toggle={toggle}/>
        <div className="wrapper h-wrap">
          <SideBarComponent toggle={toggle} isOpen={isOpen}/>
          <Container fluid className={`content overflow-auto  ${isOpen ? 'is-open' : ''}`}>
            <Switch>{menu}</Switch>
          </Container>
        </div>
      </ScrollToTop>
    </Auth>
  );
}

import RootReducer from './Reducers';
import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {folderSaga} from './Folder/Saga';
import {editorSaga} from './Editor/Saga';
import {formBuilderSaga} from './FormBuilder/Saga';
import {dataListSaga} from './DataList/Saga';
import {Saga} from './Section/Saga';
import SageCategory from './Category/Saga';
import {fragmentSaga} from './Fragments/Saga';
import {RuleSaga} from "./DocumentRule/Saga";
import {ExecutionSaga} from './ExecutionFlow/Saga';
import UsersSaga from "./Users/Saga";

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const Store = createStore(
  RootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(folderSaga);
sagaMiddleware.run(editorSaga);
sagaMiddleware.run(formBuilderSaga);
sagaMiddleware.run(Saga);
sagaMiddleware.run(SageCategory);
sagaMiddleware.run(dataListSaga);
sagaMiddleware.run(fragmentSaga);
sagaMiddleware.run(RuleSaga);
sagaMiddleware.run(ExecutionSaga);
sagaMiddleware.run(UsersSaga);

export default Store;

import React, {useState} from 'react';
import IField from "../../../Models/FormBuilder/IField";
import {LabelComponent} from "sbx-ui-input-library";
import {getInputComponentByField} from "../../Main/FormBuilder/data";
import {InfoFieldPop} from "../../Shared/PopoversComponent";
import {FormGroup} from "reactstrap";
import {getDefaultValue, scrollToView} from "../../../Utils/Utils";
import {FieldType} from "../../../Models/FieldTypes";


const CreateFieldPreview = ({
                              field,
                              hiddenLabel,
                              onChange,
                              value,
                              header,
                              className,
                              onChangeCurrentRow,
                              getCurrentRow,
                              valueNewRow
                            }: {
  field: IField;
  hiddenLabel?: boolean, value?: any;
  onChange?: (data: any) => void;
  className?: string;
  onChangeCurrentRow?: (value: any) => void;
  getCurrentRow?: (row: string) => void;
  header?: any[],
  valueNewRow: any;
}) => {

  const FieldInput: any = getInputComponentByField(field);
  const [val, setVal] = useState<any>(getDefaultValue(field));

  const fields = field.detail_form?.fields || [];
  return (
    <FormGroup className={className} key={field.id}>
      {
        !hiddenLabel && <LabelComponent icon={field.hint ? <InfoFieldPop
          text={<div style={{maxWidth: "500px"}}>
            <small>{field.hint || "No hay description"}</small>
          </div>}/> : <></>}>{field.label}</LabelComponent>
      }


      <FieldInput {...field}
                  valueNewRow={valueNewRow}
                  onChange={onChange || setVal}
                  value={value === undefined ? val : value}
                  subType={field.sub_type}
                  getCurrentRow={getCurrentRow}
                  count={field.size}
                  header={header}
                  isOutsideRange={() => false}
                  onChangeCurrentRow={onChangeCurrentRow}
                  onFocusedChange={(e: boolean) => {
                    if (e) {
                      (field.field_type === FieldType.dateRange) ?
                        scrollToView("start_date_id_" + field.id) :
                        scrollToView(field.id + "")
                    }
                  }}
                  placeholder={field.placeholder || "Here default placeholder"}
                  format_rules_definition={field.format_rules_definition || undefined}
                  fields={fields.map(f => ({
                    ...f, startDatePlaceholderText: "Fecha inicial",
                    endDatePlaceholderText: field.placeholder || "Fecha final"
                  }))}
                  options={field.options}/>
    </FormGroup>
  )
    ;
};

export default CreateFieldPreview;

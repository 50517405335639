import ProcessExecutionTask from "../../Components/Main/ExecutionFlowComponent/Models/ProcessExecutionTask";
import Form from "../../Models/FormBuilder/Form";

export const LAUNCH_PROCESS = 'form_execution/getLaunchProcess';
export const FINISH_TASK = 'form_execution/getFinishTask';
export const SAVE_TASK = 'form_execution/getSaveTask';
export const GET_PREVIEW_ITEM = "form_execution/getPreviewItem";
export const GET_PROPERTIES = "form_execution/getProperties";

export enum State {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  PROCESS_FINISHED = 'PROCESS_FINISHED',
  PENDING_PREVIEW = "PENDING_PREVIEW",
  REJECTED = 'REJECTED',
  IDLE = 'IDLE'
}

export interface FinishTaskPayload {
  formId: number;
  actionId: number;
  fields: any;
  processExecId: number;
  document_user?: {
    user: string,
    document_key: string,
    file: File,
    folder_key: string,
    emails: { email:string, field_signature:string }[],
    dataFile: string,
    workflowId: number
  }
}

export interface FormExecutionState {
  process?: any;
  state: State;
  task?: ProcessExecutionTask;
  form?: Form,
  previewItem: string;
  properties: { [key: string]: any };
}

import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import * as Services from '../../Services';
import {uploadImage} from '../../Services';
import {ADD_NEW_CATEGORY, DELETE_CATEGORY, EDIT_CATEGORY, GET_CATEGORIES} from './Types';
import {actionsCategory} from './Slice';
import Environment from '../../Environment';
import Category from '../../Models/Category';
import {toast} from '../../Utils/Utils';

function* addNewCategory({payload}: { type: string, payload: { name: string, description?: string, image: Blob } }) {
  try {
    const resFolder = yield call(
      Services.createFolder,
      Environment.mainFolderKey,
      payload.name
    );
    if (resFolder.success) {
      const res = yield Services.createCategory({...payload, folder_key: resFolder.folder.key});
      if (res.success) {
        if (payload.image.size) {
          payload.image = new File([payload.image], `category_${res.keys[0]}.jpg`);
          const upRes = yield call(uploadImage, payload.image);
          if (!upRes.success) {
            toast("No se pudo guardar la imagen", "error")
          }
        }
        toast("Categoria agregada.")
        yield put(actionsCategory.getCategories());
      }
    } else {
      throw Error;
    }
  } catch (e) {
    console.error(e);
  }
}

function* editCategory({payload}: { type: string, payload: Category }) {
  try {
    let icon;
    if (payload.image && typeof payload.image !== "string" && payload.image.size) {
      const upRes = yield call(uploadImage, payload.image);
      if (!upRes.success) {
        toast("No se pudo guardar la imagen", "error")
      } else {
        icon = upRes.item.key_id;
      }
    }
    const resFolder = yield call(
      Services.editCategory,
      {...payload, icon}
    );
    if (resFolder.success) {
      toast("Categoria editada.");
      yield put(actionsCategory.getCategories());
    } else throw Error;
  } catch (e) {
    console.error(e);
  }
}

function* getCategories(action: { type: string }) {
  try {
    const res = yield call(
      Services.getCategories
    );
    yield put(actionsCategory.setCategories(res.results));

  } catch (e) {
    console.error(e);
  }
}


function* deleteCategory({payload}: { type: string, payload: Category }) {
  try {
    const res = yield call(Services.deleteCategory, payload);
    if (res.success) {
      yield put(actionsCategory.getCategories());
    } else {
      throw Error;
    }
  } catch (e) {
    toast(e.message, "error");
  }
}


export default function* () {
  yield all([
    takeEvery(ADD_NEW_CATEGORY, addNewCategory),
    takeEvery(GET_CATEGORIES, getCategories),
    takeEvery(EDIT_CATEGORY, editCategory),
    takeEvery(DELETE_CATEGORY, deleteCategory)
  ]);
}

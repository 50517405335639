import {Rule} from "../Models/Rule";
import {sbxCoreService} from "../Network";

export async function addNewRuleService(rule: Rule): Promise<any> {
  const res = await sbxCoreService.with('document_rule').insert(rule);
  if (res.success) {
    return Promise.resolve(res)
  } else {
    return Promise.resolve({success: false})
  }
}

export async function getRulesByDocumentService(document: string): Promise<{ success: boolean, results: Rule[] }> {
  const res: any = await sbxCoreService.with('document_rule')
    .andWhereIsEqualTo('document', document)
    .loadAll();

  if (res) {
    return Promise.resolve(res)
  } else
    return Promise.resolve({success: false, results: []})
}

export async function deleteRuleService(rule: Rule) {
  const res: any = await sbxCoreService.with('document_rule')
    .whereWithKeys([rule._KEY])
    .delete();

  if (res) {
    return Promise.resolve(res)
  } else
    return Promise.resolve({success: false})
}

import { all, call, put, takeEvery } from '@redux-saga/core/effects';
import * as Services from '../../Services';
import { actions } from './Slice';
import { actions as formBuilderActions } from '../FormBuilder/Slice';
import { actions as dataListActions } from '../DataList/Slice';
import { GET_DOWNLOAD_DOCUMENT } from './Types';

function* getDownloadDocumentSaga({ payload }: any) {
  try {
    const res = yield call(
      Services.getSectionService,
      payload.section,
      payload.document,
    );
    if (res.success) {
      const res_doc = yield call(
        Services.downloadFile,
        res.section.document_key,
      );
      yield put(formBuilderActions.setFormBuilder(res.form));
      yield put(dataListActions.setAllDataList(res.allDataLists));
      if (res_doc.success && res_doc.url) {
        const resFile = yield call(Services.getFileURL, res_doc.url);
        if (resFile) {
          const body = decodeURIComponent(resFile.split('body')[1]);
          const doc = body.substring(1, body.length - 2);
          yield put(
            actions.setDocument({
              section: res.section,
              doc: res.document,
              docText: doc,
            }),
          );
        }
      }
    } else {
      throw Error('entro aqui');
    }
  } catch (e) {
    // toast("Can't get this step", "error");
    console.error(e);
  }
}

export function* editorSaga(): Generator {
  yield all([takeEvery(GET_DOWNLOAD_DOCUMENT, getDownloadDocumentSaga)]);
}

import {all, call, put, takeEvery} from "@redux-saga/core/effects";
import {ADD_NEW_RULE, DELETE_RULE, GET_RULES_BY_DOCUMENT} from "./Types";
import {PayloadAction} from "@reduxjs/toolkit";
import {addNewRuleService, deleteRuleService, getRulesByDocumentService} from "../../Services";
import {actions} from "./Slice";
import {Rule} from "../../Models/Rule";
import {toast} from "../../Utils/Utils";

function* getRulesByDocumentSaga({payload}: PayloadAction<string>) {
  const res = yield call(getRulesByDocumentService, payload);
  yield put(actions.setRulesToList(res.results));
}

function* addNewRuleSaga({payload}: PayloadAction<Rule>) {
  const res = yield call(addNewRuleService, payload);
  if (res.success) {
    toast("Regla agregada.");
    yield put(actions.getRuleByDocument(payload.document));
  } else {
    yield put(actions.resetState("rejected"));
  }
}

function* deleteRuleSaga({payload}: PayloadAction<Rule>) {
  const res = yield call(deleteRuleService, payload);
  if (res.success) {
    toast("Regla eliminada.");
    yield put(actions.getRuleByDocument(payload.document));
  } else {
    toast("No se pudo eliminar esta regla.");
    yield put(actions.resetState("rejected"));
  }
}

export function* RuleSaga(): Generator {
  yield all([
    takeEvery(GET_RULES_BY_DOCUMENT, getRulesByDocumentSaga),
    takeEvery(ADD_NEW_RULE, addNewRuleSaga),
    takeEvery(DELETE_RULE, deleteRuleSaga)
  ]);
}

/* eslint no-eval: 0 */
import {format} from 'date-fns';
import iziToast from 'izitoast';
import Compressor from 'compressorjs';
import {FieldValue} from "../Components/Main/ExecutionFlowComponent/Models/Form";
import {ChildType, FieldType} from "../Models/FieldTypes";
import IField from "../Models/FormBuilder/IField";
import {CONDITIONS} from "./Constants";
import {convertStringToPdfService} from "../Services";

declare var document: Document;

export const categoryImgUrl = "https://sbxcloud.com/www/simplelegaldev/images/category/";

export const setHistorySection = (value: string) => {
  sessionStorage.setItem("history-section", value)
}

export const getHistorySection = () => {
  return sessionStorage.getItem("history-section");
}

const crypto = (window.crypto as any) || {
  getRandomValues: (array: any[]) => {
    for (let i = 0, l = array.length; i < l; i++) {
      array[i] = Math.floor(Math.random() * 256);
    }
    return array;
  },
};


export function setTextToEditor(editorText: string, dataText: string) {
  let text;
  if (editorText.slice(-4).indexOf('</') !== -1) {
    text =
      editorText.substr(0, editorText.length - 4) +
      dataText + editorText.slice(-4);
  } else if (editorText.slice(-5).indexOf('</') !== -1) {
    text =
      editorText.substr(0, editorText.length - 5) +
      dataText + editorText.slice(-5);
  } else {
    text = editorText + dataText;
  }
  return text;
}

export function uuidV4(): string {
  return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (
      ((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >>
      (c / 4)
    ).toString(16),
  );
}

export const conditions = [
  {label: "Igual a", value: " === "},
  {label: "Distinto a", value: " !== "},
  {label: "Mayor que", value: " > "},
  {label: "Menor que", value: " < "},
  {label: "Mayor ó igual a", value: " >= "},
  {label: "Menor ó igual a", value: " <= "},
];

export function splitRule(rule: string) {
  let newRule = rule;
  if (rule && rule !== "true") {
    const rules: string[] = [];
    const vArrOr = rule.split(' || ');
    vArrOr.forEach(a => {
      const vArrAnd = a.split(' && ');
      vArrAnd.forEach(a => rules.push(a));
    });

    rules.forEach(a => {
      const c = getCondition(a);
      const v = a.split(c.value);
      const v0 = v[0].split("_");
      const name = v[0].replace(`${v0[0]}_${v0[1]}_`, "");
      newRule = newRule.replace("&&", "y")
        .replace("||", "ó")
        .replace(c.value, ` ${c.label} `)
        .replace(`"`, "")
        .replace(`"`, "")
        .replace(v[0], name);
    });
    return newRule;
  }
  return "No hay condiciones.";
}

export function getCondition(value: string) {
  let val: any = null;
  conditions.forEach(a => {
    if (value.includes(a.value)) {
      val = a
    }
  })
  return val;
}

export function scrollToView(id: string) {
  const element = document.getElementById(id);
  if (element) {
    setTimeout(() => element.scrollIntoView(true), 200)
  }
}

export function sortArray(
  array: any[],
  indexToRemove: number,
  indexToInsert: number,
): any[] {
  const data = array.splice(0);
  const sortItem = data.splice(indexToRemove, 1);
  data.splice(indexToInsert, 0, sortItem[0]);
  return data;
}

export function toSnakeCase(str: string): string {
  return str
    ? str
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '_')
    : '';
}

export function stopPropagation(event: any): void {
  event.stopPropagation();
}

// export function preventDefault(event: any) {
//   event.preventDefault();
// }

export function megabyteToByte(value: number): number {
  return 1024 * 1024 * value;
}

export const toast = (message: string, type?: string): void => {
  const config = {message};
  switch (type) {
    case 'warning':
      iziToast.warning(config);
      break;
    case 'error':
      iziToast.error(config);
      break;
    default:
      const successConfig = {...config, backgroundColor: "#383EB3", messageColor: "#FFF", iconColor: "#FFF"};
      iziToast.success(successConfig);
      break;
  }
};

export function getParameterByName(name: string, url?: string): string | null {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const formatDate = (date: Date): string => {
  return format(date, 'dd/MM/yyyy');
};

export function ConvertDateToDate(date1: string): Date {
  return new Date(date1);
}

export function appendDocxHeaders(content: string): string {
  const header =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
    "xmlns:w='urn:schemas-microsoft-com:office:word' " +
    "xmlns='http://www.w3.org/TR/REC-html40'>" +
    "<head><meta charset='utf-8'><title>Tmp</title></head><body>";
  const footer = '</body></html>';
  const sourceHTML = header + content + footer;
  return (
    'data:application/vnd.ms-word;charset=utf-8,' +
    encodeURIComponent(sourceHTML)
  );
}

export const getImgCompressed = function (file: Blob): Promise<Blob> {
  return new Promise(resolve => {
    new Compressor(file, {
      quality: .6,
      maxWidth: 300,
      success(fileCompressed) {
        resolve(fileCompressed);
      },
      error(error) {
        resolve(file);
      }
    });
  });
};

export const authToken = !!window.localStorage.getItem('token');


export function getValuesFields(fields: { [key: string]: FieldValue }) {
  return Object.keys(fields)
    .map(f => getValueField(fields[f]))
    .filter(e => e);
}


export function getValueField(valueField: FieldValue) {
  const {
    field: {field_type, sub_type, single_value, name, id, detail_form},
    value,
  } = valueField;

  const fields = detail_form?.fields || [];

  switch (field_type) {
    case FieldType.smallText:
    case FieldType.largeText:
      const val = {
        id,
        name,
        value,
        single_value,
      };
      switch (sub_type) {
        case ChildType.number:
          return {...val, value: parseInt(val.value)}
        default:
          return val
      }

    case FieldType.date:
      return {
        id,
        name,
        value: value ? new Date(value).toISOString() : '',
        single_value,
      };

    case FieldType.dateRange:
      return {
        id,
        name,
        value: (value && value.startDate && value.endDate) ?
          `${new Date(value.startDate).toDateString()} -> ${new Date(value.endDate).toDateString()}` : '',
        single_value,
      };

    case FieldType.table:

      const result: any = Object.keys(value).map(e => {
        const row = value[e];
        return Object.keys(row).map(r => {
          const subField = fields.find(f => f.id === parseInt(r))
          if (subField) {
            return getValueField({value: row[r], field: subField})
          } else return undefined;
        });
      }).slice();
      return {
        id,
        name,
        value: result,
        single_value,
      };

    case FieldType.formGroup:
      const resultForm: any = Object.keys(value).map(r => {
        const subField = fields.find(f => f.id === parseInt(r))
        if (subField) {
          return getValueField({value: value[r], field: subField})
        } else return undefined;
      });
      return {
        id,
        name,
        value: resultForm,
        single_value,
      };

    case FieldType.options:
      switch (sub_type) {
        case 'SELECT':
        case 'TOGGLE':
          return value ? {
            id,
            name,
            single_value,
            value: single_value ? value.value : value.map((val: any) => val.value),
          } : null;
        default:
          return null;
      }

    default:
      return null;
  }
}


export function downloadFile(file: File, name: string) {
  let a = document.createElement("a");
  a.href = URL.createObjectURL(file);
  a.setAttribute("download", `${name}.pdf`);
  a.click();
}

export function convertStringToPdf(text: string,
                                   {name, download}: { name: string, download?: boolean },
                                   callBack?: (file: File) => void) {
  const {
    top,
    bottom,
    left,
    right
  } = {
    top: 94,
    left: 113,
    right: 113,
    bottom: 94
  };

  const html = (
    `<html>
        <head>
            <style>
            @page{
              -fs-page-orientation: portrait; 
              margin-left: ${left}px; 
              margin-right: ${right}px;
              margin-top: ${top}px;
              margin-bottom: ${bottom}px;
            }
            </style>    
        </head>
        <body>
            ${text}
        </body>
    </html>`);

  convertStringToPdfService(html)
    .then((file: Blob) => {
      const nFile = new File([file], name + ".pdf");
      if (callBack) callBack(nFile);
      if (download) downloadFile(nFile, name);
    });
}

export function arrayToMap(data: any[], field: string) {
  return data.reduce((a: any, item: any) => {
    a[item[field]] = item;
    return a
  }, {});
}

export const getDefaultValue = (field: IField) => {
  switch (field.field_type) {
    case "OPTIONS":
    case "DATE":
      return null;
    case "DATE_RANGE":
      return {startDate: null, endDate: null}
    default:
      return "";
  }
}

function getValueToRule(value: FieldValue, name: string) {
  if (value.field.detail_form) {
    const fields = arrayToMap(value.field.detail_form.fields || [], "name");
    const _v = fields[name];
    if (_v) {
      return {value: value.value[_v.id], field: _v}
    }
  }
  return null
}

export const filterFields = (
  array: IField[],
  val: { [key: string]: any },
  properties: { [key: string]: any }
  ) => {

    function getRuleValueField(name: string) {
      let vr: any = Object.keys(val).reduce((a: any, v: any) => {
        const typeField = array.find(f => `${f.id}` === v);

        if (typeField?.field_type === FieldType.formGroup) {
          a = getValueToRule(val[v], name);
        }

        if (typeField?.field_type !== FieldType.formGroup &&
          typeField?.field_type !== FieldType.table &&
          val[v].field.name === name) {
          a = val[v];
        }
        return a;
      }, null);

      if (vr) {
        vr = getValueField(vr)?.value;
      } else {
        vr = properties[name]?.value;
        if (!vr) {
          const _props = Object.keys(properties)
            .reduce((a: any, p) => {
              if (properties[p].type === "ARRAY" && !Array.isArray(properties[p].value[0])) {
                properties[p].value.forEach((b: any) => {
                  a[b.name] = b.value;
                })
              }
              return a;
            }, {})
          vr = _props[name];
        }
      }

      return (typeof vr === "string" ? `"${vr}"` : vr) || `""`;
    }

    const b = array.filter(a => {
      let visible = a.visible_when || 'true';
      let rules = splitRuleArray(a.visible_when || "");
      rules.forEach(r => {
        const value = getRuleValueField(r.field);
        if (Array.isArray(value)) {
          const val = value.find((e: string) => `"${e}"` === r.value);
          visible = visible.replace(r.defaultField, `"${val}"`);
        } else {
          visible = visible.replace(r.defaultField, value);
        }
      });
      console.log(visible)
      return eval(visible);
    });
    return b;
  }
;


export const splitRuleArray = (rule: string) => {
  const defaultValues: any[] = [];

  if (rule) {
    const vArrOr = rule.split(' || ');
    vArrOr.forEach(a => {
      const vArrAnd = a.split(' && ');
      vArrAnd.forEach(a => defaultValues.push(a));
    });
  }

  const sp = defaultValues.map(a => {
    const d = a.split(CONDITIONS.find(c => a.includes(c)));
    const f = d[0].split('_');
    const field = d[0].replace(`${f[0]}_${f[1]}_`, '');
    return {
      formId: f[0],
      fieldId: f[1],
      value: d[1],
      field,
      rule,
      defaultField: d[0],
    };
  });
  return sp;
};


export function refactorPreview(preview: string) {
  let fields: any[] = preview.split("${");
  fields.shift();
  if (fields.length) {
    fields = fields.map(f => f.split("}").shift()).map(f => ({current: "${" + f + "}", replace: "${" + f + "};"}));
  }
  fields.forEach(f => {
    preview = preview
      .split(f.replace).join(f.current)
      .split(f.current).join(f.replace);
  });
  if (preview.includes("##")) {
    preview = preview
      .replace(/}};/g, "}}")
      .replace(/}}/g, "}};")
  }
  return preview
}


export function getFieldName(field: IField, complete?: boolean) {
  let name = `${field.name}`
    .replace(new RegExp(`${field.form_id}_`, "g"), "")
    .replace(new RegExp(`_${field.id}`, "g"), "")
    .split("_").join(" ");
  return complete ? (`${(field.form_id ? `${field.form_id}_` : "") + name + (field.id ? `_${field.id}` : "")}`).split(" ").map(str => str).join("_") : name;

}


export function setNameToField(field: IField) {
  let newField = Object.assign({}, field);
  newField.name = getFieldName(field, true);
  return newField;
}


// The download function takes a CSV string, the filename and mimeType as parameters
// Scroll/look down at the bottom of this snippet to see how download is called
export const download = function (content: string, fileName: string, mimeType?: string) {
  let a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';

  if (navigator.msSaveBlob) { // IE10
    navigator.msSaveBlob(new Blob([content], {
      type: mimeType
    }), fileName);
  } else if (URL && 'download' in a) { //html5 A[download]
    a.href = URL.createObjectURL(new Blob([content], {
      type: mimeType
    }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}


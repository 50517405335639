import {sbxCoreService} from '../Network';
import Category from "../Models/Category";
import Document from "../Models/Document";
import {classToPlain} from "class-transformer";
import {createProcessModel} from "./WorkflowServices/ProcessModelService";
import {updateDictionary, uploadNewFile} from "./DocumentsService";


export const createFolder = (parentKey: string, name: string) => {
  return sbxCoreService.createFolder(parentKey, name);
};
export const editFolder = (folderKey: string, name: string) => {
  return sbxCoreService.renameFolder(folderKey, name);
};

export const getCategoriesDocs = (category: string) => {
  return sbxCoreService.with('document').andWhereIsEqualTo('category', category);
};
export const editDocument = async (document: Document) => {
  document.last_updated = new Date();
  const res = await sbxCoreService.with('document').update(classToPlain(document));
  if (res.success) {
    await updateDictionary("document", ["name", "description", "large_description"], "_KEY")
  }
  return res;
};
export const addNewDocument = async (name: string, category: Category, sort_index: number) => {
  // ! TODO: First you need to call the create workflow from SBX-FLOWS, wait for Hans until is created
  try {
    const document = new Document();
    document.name = name;
    document.sort_index = sort_index;
    if (category.key) {
      document.category = category.key;
    }
    document.last_updated = new Date();
    document.folder_key = category.folder_key;
    const res = await sbxCoreService.insert('document', document);
    if (res.success) {
      await updateDictionary("document", ["name", "description", "large_description"], "_KEY");
      const resFrag = await uploadNewFile(`<h1>${document.name}</h1>`, document.folder_key, res.keys[0]);
      if (resFrag.success) {
        document.document_key = resFrag.item.key_id;
        const resProcess = await createProcessModel(document.name, document.document_key);
        if (resProcess.success) {
          document.workflow_id = resProcess?.item?.id;
          return sbxCoreService.update("document", {...document, _KEY: res.keys[0]})
        } else {
          await deleteDocument(res.keys[0]);
          return {success: false}
        }
      }
    }
  } catch (e) {
    return {success: false}
  }

};


export const deleteDocument = (key: string) => {
  return sbxCoreService.with("document")
    .whereWithKeys([key])
    .delete();
}

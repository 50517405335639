import {sbxCoreService} from "../Network";

export async function getAllUserService() {
  return sbxCoreService
    .with("user")
    .andWhereIsNotEqualTo("role", "GUEST")
    .andWhereIsNotEqualTo("name", "Guest")
    .loadAll()
    .then((res: any) => {
      res.results = res.results
        .filter((r: any) => (
          r.name.toLowerCase().indexOf("test") === -1 &&
          (`${r.last_name}`).toLowerCase().indexOf("test") === -1 &&
          r.email.toLowerCase().indexOf("test") === -1 &&
          r.email.toLowerCase().indexOf("socobox") === -1 &&
          r.email.toLowerCase().indexOf("@sbxcloud.com") === -1 &&
          r.email.toLowerCase().indexOf("admin@adminm.com") === -1
        ))
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
      return res;
    });
}

import React, {useRef} from 'react';
import ModalComponent from "../Shared/ModalComponent";
import {ModalProps} from "reactstrap";
import {useDispatch} from "react-redux";
import {actions} from "../../Store/Folder/Slice";
import Category from "../../Models/Category";

interface Props extends ModalProps {
  category?: Category
}

const CreateDocumentModal: React.FC<Props> = ({category, ...props}) => {
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const onSubmit = () => {
    const name = ref.current?.value;
    if (name && category && props.onClosed) {

      dispatch(actions.getAddNewDocument({name, category}));
      props.onClosed();
    }

  };
  return <ModalComponent title="Crear nuevo documento" {...props} noFooter>
    <form onSubmit={(event => {
      event.preventDefault();
      onSubmit();
    })}>
      <div className="form-group">
        <label htmlFor="">Nombre</label>
        <input ref={ref} type="text" className="form-control"/>
      </div>
      <div className="">
        <button type="button" onClick={props.onClosed} className="btn btn-danger btn-sm mr-3">Cancelar
        </button>
        <button className="btn btn-primary btn-sm" type="submit">Crear Documento</button>
      </div>
    </form>
  </ModalComponent>;
}

export default CreateDocumentModal;

import React from 'react';
import 'reflect-metadata';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "moment/locale/es";

import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import Store from './Store/Store';

import 'react-dates/lib/css/_datepicker.css';
import 'sbx-ui-input-library/index.css';

ReactDOM.render(
  <Provider store={Store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

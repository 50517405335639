import { Expose } from 'class-transformer';
import Fragment from './Fragment';

export default class Section {
  @Expose({ name: '_KEY' })
  key?: string;
  @Expose()
  name!: string;
  @Expose()
  document_key!: string;
  @Expose()
  task_id!: number;
  @Expose()
  form_id!: number | undefined;
  @Expose()
  document!: string;
  @Expose()
  last_updated!: string;
  @Expose()
  description!: string;
  @Expose()
  fragments!: Fragment[];
}

import React, {useEffect, useRef, useState} from 'react';
import ModalComponent from '../../../Shared/ModalComponent';
import {ModalProps} from 'reactstrap';
import Category from '../../../../Models/Category';
import {useDispatch, useSelector} from 'react-redux';
import {actionsCategory} from '../../../../Store/Category/Slice';
import {RootState} from '../../../../Store/Reducers';
import {State} from '../../../../Store/Category/Types';
import {categoryImgUrl, getImgCompressed, toast} from '../../../../Utils/Utils';
import defaultImg from "../../../../Assets/Images/defaultImage.png"
import {downloadFile} from "../../../../Services";

interface Props extends ModalProps {
  category?: Category
}

const CreateCategoryModal: React.FC<Props> = (props) => {
  const {category} = props;
  const ref = useRef<HTMLInputElement>(null);
  const {modalState} = useSelector((state: RootState) => state.categoryReducer);
  const [description, setDescription] = useState<string>('');
  const [file, setFile] = useState<Blob | null>(null);
  const [img, setImg] = useState(defaultImg);
  const dispatch = useDispatch();

  useEffect(() => {
    if (category && category?.icon) {
      downloadFile(category.icon)
        .then(({name}) => {
          setImg(`${categoryImgUrl}${name}`);
        })
    } else {
      setImg(defaultImg);
    }
  }, [category])

  function handleEvent(e: any) {
    let file = e.target.files[0];
    setImg(URL.createObjectURL(file));
    setFile(file);
  }

  const onsubmit = async () => {
    const name = ref?.current?.value;
    if (name) {
      const imageFile = file ? await getImgCompressed(file) : new Blob();
      if (category) {
        const image = new File([imageFile], `category_${category.key}.${imageFile.type.replace("image/", "")}`)
        dispatch(actionsCategory.getEditCategory({...category, name, description: description, image}));
      } else {
        dispatch(actionsCategory.getAddNewCategory({name, description, image: imageFile}));
      }
      if (props.onClosed) {
        props.onClosed();
      }
    } else {
      toast("Por favor, escriba el nombre de la categoria.", "error");
    }
    setDescription("");
  };
  return <ModalComponent {...props} title={`${modalState === State.EDITING ? 'Editar' : 'Crear nueva'} categoria`}
                         noFooter>
    <form onSubmit={event => {
      event.preventDefault();
      onsubmit();
    }}>
      <div className="form-group">
        <div className="text-center show-img-modal">
          <img style={{maxWidth: "100px"}} src={img} onError={() => {
            setImg(defaultImg)
          }} alt=""/>
        </div>
        <div className="text-center">
          <input style={{display: 'none'}} id="file" type="file" accept="image/*" onChange={handleEvent}/>
          <b style={{cursor: 'pointer', textDecoration: 'underline'}}
             onClick={() => document.getElementById('file')?.click()}>Seleccionar imagen</b>
        </div>
        <label htmlFor="" className="form-label">Nombre</label>
        <input type="text" ref={ref} className="form-control"
               defaultValue={props.category?.name || ''}/>
        <label htmlFor="" className="form-label mt-2">Descripción</label>
        <textarea className="form-control" value={description || props.category?.description || ""}
                  onChange={(event => setDescription(event.target.value))} rows={3}/>
      </div>
      <div className="">
        <button type="button" onClick={props.onClosed} className="btn btn-danger mr-3">Cancelar</button>
        <button
          className="btn btn-primary">{`${modalState === State.EDITING ? 'Editar' : 'Crear nueva'} categoria`}</button>
      </div>
    </form>
  </ModalComponent>;
};

export default CreateCategoryModal;

declare let process: {
  env: {
    [key: string]: string;
  };
};

const keys: any = {
  getPreview: {
    250: "680931e9-911c-4244-9456-bc3f64269255"
  },
  dictionary:{
    250: "a5e4eae1-ea20-4bf6-aafd-ef06d758e3ca"
  }
};

export default {
  domain: process.env.REACT_APP_DOMAIN,
  baseUrl: process.env.REACT_APP_BASE_URL,
  appKey: process.env.REACT_APP_APP_KEY,
  mainFolderKey: process.env.REACT_APP_MAIN_FOLDER_KEY,
  token: process.env.REACT_APP_TOKEN_PROCESS,
  cloudScripts: {
    validate: '',
    sbxSearch: keys.dictionary[process.env.REACT_APP_DOMAIN],
    getPreview: keys.getPreview[process.env.REACT_APP_DOMAIN],
  },
  templates: {},
  folders: {
    imagesCategories: "086c4a11-e169-42ed-8804-010fc16862f4"
  },
};

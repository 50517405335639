import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {FolderState, SetFolderList, StateReducer} from "./Types";
import Category from "../../Models/Category";
import Document from "../../Models/Document";

const folderReducer = createSlice({
  name: 'folder',
  initialState: {list: [], state: 'idle', currentFolder: null} as FolderState,
  reducers: {
    setFolderList(state, action: PayloadAction<SetFolderList>) {
      state.list = action.payload.content.sort((a, b) => a.sort_index - b.sort_index);
      state.state = 'resolved';
      state.currentFolder = action.payload.folder
    },
    getFolderList(state, action: PayloadAction<Category>) {
      state.state = StateReducer.PENDING;
    },
    getAddNewFolder(state, action: PayloadAction<{ parentKey: string, name: string }>) {
      state.state = StateReducer.PENDING;
    },
    setAddNewFolder(state, action) {
      state.state = 'resolved';
    },
    getAddNewFile(state, action: PayloadAction<{ parentKey: string, name: string }>) {
      state.state = StateReducer.PENDING;
    },
    setAddNewFile(state, action) {
      state.state = 'resolved';
    },
    getAddNewDocument(state, action: PayloadAction<{ name: string, category: Category }>) {
      state.state = StateReducer.PENDING;
    },
    setAddNewDocument(state, action) {
      state.state = 'resolved';
    },
    getFirstLoad(state) {
      state.state = StateReducer.PENDING;
    },
    setFirstLoad(state) {
      state.state = StateReducer.PENDING;
    },
    deleteFolderList(state, action: PayloadAction<Document>) {
      state.state = StateReducer.PENDING;
    },
    rejectAction(state) {
      state.state = StateReducer.REJECTED;
    }
  }
});

export const actions = folderReducer.actions;

export default folderReducer.reducer

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RuleState, StateReducer} from "./Types";
import {Rule} from "../../Models/Rule";

const initialState: RuleState = {list: [], state: 'idle'};

const ruleSlice = createSlice({
  name: 'rule',
  initialState,
  reducers: {
    addNewRule(state, action: PayloadAction<Rule>) {
      state.state = "pending";
    },
    getRuleByDocument(state, action: PayloadAction<string>) {
      state.state = "pending";
    },
    setRulesToList(state, action: PayloadAction<Rule[]>) {
      state.list = action.payload.sort((a, b) => a.name.localeCompare(b.name));
      state.state = "resolved";
    },
    deleteRule(state, action: PayloadAction<Rule>) {
      state.state = "deleting";
    },
    resetState(state, action: PayloadAction<string | StateReducer>) {
      state.state = action.payload;
    }
  }
});

export const actions = ruleSlice.actions;

export default ruleSlice.reducer;

import {AuthState, ChatActionTypes, SEND_MESSAGE} from './Types'

const initialState: AuthState = {
  messages: []
};

export default function chatReducer(
  state = initialState,
  action: ChatActionTypes
): AuthState {
  switch (action.type) {
    case SEND_MESSAGE:
      return {
        messages: [...state.messages, action.payload]
      };
    default:
      return state
  }
}

import Section from '../../Models/Section';
import Document from '../../Models/Document';

export const GET_DOWNLOAD_DOCUMENT = 'editor/getDocument';

export interface EditorState {
  editorText: string;
  state: string;
  doc?: Document;
  section?: Section;
}

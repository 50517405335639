import React, {useEffect, useState} from 'react';
import {actions} from "../../../Store/DataList/Slice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../Store/Reducers";
import DataList from "../../../Models/FormBuilder/DataList";
import CreateDataListModal from "../../Modals/CreateDataListModal";
import {useHistory} from "react-router-dom";
import TableComponent from "../../Shared/TableComponent/TableComponent";
import {Head} from "../../../Models/Table";


const DataListListComponent = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const {list, state} = useSelector((state: RootState) => state.dataListReducer);
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.getAllDataList());
    return () => {
    };
  }, [dispatch]);

  const handleDelete = (row: any) => {
    setDeleting(true);
    dispatch(actions.getDeleteDataList({dataListId: row.id}));
  };

  const goToDetail = (dl: DataList) => {
    history.push(`/data_list/${dl.id}`)
  };

  useEffect(() => {
    if (deleting && state === "idle") {
      setDeleting(false);
    }
  }, [deleting, state]);


  const headers: Array<Head> = [
    {field: "name", header: "Nombre", width: "60%"},
    {field: "value_field", header: " Referencia valor", width: "20%"},
    {field: "label_field", header: "Referencia etiqueta", width: "20%"}
  ];

  return (
    <div className="card">
      <div className="card-header d-flex">
        <h3 className="mr-auto">Listado de proveedores: </h3>
        <button className="btn btn-primary" onClick={() => setOpenModal(true)}>Crear nuevo proveedor
        </button>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <TableComponent rows={list}
                          headers={headers}
                          filter
                          loading={state === "pending"}
                          pagination
                          rowsCount={15}
                          onEvent={goToDetail}
                          labelEvent="Ver"
                          onRemove={handleDelete}/>
        </div>
      </div>
      <CreateDataListModal isOpen={openModal} toggle={() => setOpenModal(false)}
                           onClosed={() => setOpenModal(false)}/>
    </div>
  );
};

export default DataListListComponent;

import Section from '../../Models/Section';
import Document from '../../Models/File';
import Category from '../../Models/Category';

export const ADD_NEW_CATEGORY = 'category/getAddNewCategory';
export const GET_CATEGORIES = 'category/getCategories';
export const EDIT_CATEGORY = 'category/getEditCategory';
export const DELETE_CATEGORY = 'category/getDeleteCategory';

export enum State {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  IDLE = 'IDLE',
  CREATING = 'CREATING',
  EDITING = 'EDITING',
  DELETING = 'DELETING',
}

export interface SetFolderList {
  content: Section[];
  workflow: Document;
}

export interface CategoryState {
  list: Category[];
  currentCategory?: Category;
  state: State;
  modalState?: State;
}

import {combineReducers} from 'redux';
import authReducer from '../Auth/Reducer';
import folderReducer from '../Folder/Slice';
import editorReducer from '../Editor/Slice';
import formBuilder from '../FormBuilder/Slice';
import sectionReducer from '../Section/Slice';
import categoryReducer from '../Category/Slice';
import dataListReducer from '../DataList/Slice';
import fragmentReducer from '../Fragments/Slice';
import rulesReducer from '../DocumentRule/Slice';
import executionReducer from '../ExecutionFlow/Slice';
import usersReducer from '../Users/Slice';

export const initialReducer = {
  authReducer: {
    messages: [],
  },
  folderReducer: {
    list: [],
    state: 'idle',
    currentFolder: null,
  },
  editorReducer: {
    editorText: '',
    state: 'idle',
  },
  formBuilder: {
    modalOpen: false,
    state: 'idle',
    currentForm: {
      id: 1,
      domain: 0,
      name: 'test',
      label: '12',
      fields: [],
    },
    parentFormId: null,
  },
  sectionReducer: {
    list: [],
    state: 'idle',
    workflow: null,
  },
  categoryReducer: {
    list: [],
    state: 'IDLE',
  },
  dataListReducer: {
    state: 'idle',
    list: [],
  },
  fragmentReducer,
};
const RootReducer = combineReducers({
  authReducer,
  folderReducer,
  editorReducer,
  formBuilder,
  sectionReducer,
  categoryReducer,
  dataListReducer,
  fragmentReducer,
  rulesReducer,
  executionReducer,
  usersReducer
});

export default RootReducer;
export type RootState = ReturnType<typeof RootReducer>;

import {sbxCoreService} from '../Network';
import {getFile, uploadFile, uploadNewFile} from './DocumentsService';
import Fragment from '../Models/Fragment';
import Section from '../Models/Section';
import {toast} from "../Utils/Utils";
import Document from "../Models/Document";

export async function getFragmentsBySection(
  section: string,
): Promise<Fragment[]> {
  const res: any = await sbxCoreService
    .with('fragment')
    .andWhereIsEqualTo('section', section)
    .find();
  if (res) {
    // for await (const fragment of res.results) {
    //   const resFile = await getFile(fragment.document_key);
    //   fragment.content = resFile;
    // }
    return Promise.resolve(res.results);
  }
  return Promise.resolve([]);
}

export async function getFragmentsByDocument(
  document: string,
): Promise<Fragment | null> {
  const res: any = await sbxCoreService
    .with('fragment')
    .andWhereIsEqualTo('document', document)
    .find();
  if (res) {
    for await (const fragment of res.results) {
      const resFile = await getFile(fragment.document_key);
      fragment.text = resFile;
    }
    return Promise.resolve(res.results[0]);
  }
  return Promise.resolve(null);
}

export async function getFragmentService(section: string): Promise<Fragment[]> {
  const res: { results: Fragment[] } = await sbxCoreService
    .with('fragment')
    .whereWithKeys(section)
    .find();
  if (res) {
    for await (const fragment of res.results) {
      const resFile = await getFile(fragment.document_key);
      fragment.text = resFile;
    }
    return Promise.resolve(res.results);
  }
  return Promise.resolve([]);
}

export async function addNewFragmentService(
  section: Section,
): Promise<Fragment[]> {
  const sort_index = section.fragments.length ?
    (Math.max.apply(null, section.fragments.map(a => a.sort_index)) + 1) : 1;
  const fragmentName = `Fragment #${sort_index}`;
  const resUpload: any = await uploadNewFile(
    `<h1>${fragmentName}</h1>`,
    section.document_key,
    fragmentName,
  );
  if (resUpload && section.key) {
    const document_key = resUpload.item.key_id;
    const fragment: Fragment = {
      section: section.key || '',
      document_key,
      sort_index,
      rule: '',
      name: fragmentName,
    };
    const res: any = await sbxCoreService.insert('fragment', fragment);
    if (res.success) {
      const res = getFragmentsBySection(section.key);
      return Promise.resolve(res);
    }
  }
  return Promise.reject('Problemas con la creación de fragmento');
}

export async function updateFragmentService({
                                              _KEY,
                                              name,
                                              rule,
                                              sort_index,
                                            }: Fragment): Promise<any> {
  if (!rule) {
    rule = 'true';
  }
  const res = await sbxCoreService.update('fragment', {
    _KEY,
    name,
    rule,
    sort_index,
  });
  if (res) {
    return Promise.resolve({success: true, item: res});
  } else {
    return Promise.resolve({success: false});
  }
}


export async function removeFragmentService(fragment: Fragment): Promise<{ success: boolean }> {
  const res = await sbxCoreService.with("fragment").whereWithKeys(fragment._KEY).delete();
  if (res && res.success) {
    return Promise.resolve({success: true})
  } else {
    return Promise.resolve({success: false})
  }
}

export async function updateNewAndOldFragmentService(newRow: Fragment, oldRow: Fragment): Promise<{ success: boolean }> {
  const newFrag = await updateFragmentService(newRow);
  const oldFrag = await updateFragmentService(oldRow);
  if (newFrag.success && oldFrag.success) {
    return Promise.resolve({success: true})
  } else {
    return Promise.resolve({success: false})
  }
}


export async function uploadFragment( text: string, document?: Document) {
  if (document) {
    const res = await uploadFile(
      new File([text],
        document.key + '.slg', {
          type: 'text/doc',
        }),
      document.folder_key,
    );
    if (res.success) {
      toast('Archivo actualizado');
    }
  }
}

import React, {useCallback, useEffect, useRef, useState} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import './editor.css';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../Store/Reducers';
import {actions} from '../../../Store/Editor/Slice';
import {actions as ActionsRule} from '../../../Store/DocumentRule/Slice';
import RuleComponent from '../../Shared/RuleComponent/RuleComponent';
import {useParams} from 'react-router-dom';
import AddFieldNameToEditor from '../../Shared/AddFieldNameToEditor';
import {Button, Card, CardBody, CardFooter, CardHeader, FormGroup, Label, ModalFooter} from 'reactstrap';
import Select from 'react-select';
import ModalComponent from '../../Shared/ModalComponent';
import ConfirmModal from '../../Modals/ConfirmModal';
import {getFile} from '../../../Services';
import {scrollToView} from "../../../Utils/Utils";

interface Props {
  initialText?: string;
  onUpload: (text: string) => void;
}

export const editorConfiguration = {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'Underline',
    '|',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bulletedList',
    'numberedList',
    '|',
    'insertTable',
    'imageUpload',
    'blockQuote',
    '|',
    'alignment',
    '|',
    'undo',
    'redo',
  ],
};

let globalEditor: any;

function EditorComponent({onUpload, initialText}: Props): React.ReactElement {
  const dispatch = useDispatch();
  const {workflowId} = useParams();
  const [reset, setReset] = useState(false);
  const [exist, setExist] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [valueNewRule, setValueNewRule] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [errorNameRule, setErrorNameRule] = useState("");
  const [newSignature, setNewSignature] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const {editorText} = useSelector(
    (state1: RootState) => state1.editorReducer,
  );
  const {sectionReducer: {document: workflow}} = useSelector(
    (state: RootState) => state
  );

  const {list, state} = useSelector((state: RootState) => state.rulesReducer);
  const getFileDocument = useCallback(async (key: string) => {
    const res = await getFile(key);
    existFile.current = true;
    return res;
  }, []);

  const existFile = useRef(false);
  useEffect(() => {
    if (workflow && !existFile.current) {
      getFileDocument(workflow.document_key).then(res =>
        dispatch(actions.changeEditorText(res)),
      );
    }
  }, [workflow, getFileDocument, dispatch]);

  useEffect(() => {
    if (reset) setReset(e => !e);
  }, [reset]);

  const [rule, setRule] = useState('');
  const [optionRule, setOptionRule] = useState<any>(null);

  useEffect(() => {
    const ex = list.find(r => r.rule === rule);
    setExist(!!ex);
    setOptionRule((op: any) => {
      if (ex && !op) return {value: ex.rule, label: ex.name};
      return op;
    });
  }, [rule, list]);

  function saveRule() {
    if (workflowId && !exist && rule) {
      if (valueNewRule && valueNewRule.match(/([\w][0-9]*)$/i)) {
        const existName = list.find(l => l.name === valueNewRule);
        if (existName) {
          setErrorNameRule("Este nombre ya existe");
        } else {
          setDataToCursor(`##${valueNewRule}{{ }};`);
          setErrorNameRule("");
          dispatch(
            ActionsRule.addNewRule({
              document: workflowId,
              name: valueNewRule,
              rule,
            }),
          );
          resetVal();
        }
      } else {
        setErrorNameRule("El nombre no puede estar vacio o con caracteres especiales.")
      }
    } else {
      setDataToCursor(`##${optionRule.label}{{ }}`);
      resetVal();
    }
  }


  function resetVal() {
    setOptionRule(null);
    setRule('');
    setReset(true);
    setOpenDeleteModal(false);
    setErrorNameRule("");
    setValueNewRule("");
  }

  const setDataToCursor = (text: string) => {
    globalEditor.model.change((writer: any) => {
      writer.insertText(
        text,
        globalEditor.model.document.selection.getFirstPosition(),
      );
    });
    scrollToView("editor_scroll");
  };

  useEffect(() => {
    if (state === 'resolved') {
      setReset(true);
      setOpen(false);
    }
  }, [state]);

  useEffect(() => {
    if (optionRule) {
      setRule(optionRule.value);
      setReset(true);
    }
  }, [optionRule, list]);

  const download = async () => {
    const source = editorText;
    const fileDownload = document.createElement('a');
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = 'document.doc';
    fileDownload.click();
    document.body.removeChild(fileDownload);
  };

  const addSignature = () => {
    if (ref.current) {
      const signature = ref.current.value.toLowerCase()
        .replace("firma ", "")
        .replace("firma_", "");
      setDataToCursor(`$(firma_${signature});`)
    }
  }

  return (
    <div className="App">
      <AddFieldNameToEditor
        title="Reemplazar campos"
        workflowId={workflowId || ''}
        getName={setDataToCursor}
      />
      <FormGroup className="text-left">
        <div className="row">
          <div className="col-8 col-md-6">
            <Label>Reglas existentes</Label>
            <Select
              value={optionRule}
              onChange={e => setOptionRule(e)}
              options={list.map(l => ({
                label: l.name,
                value: l.rule,
                data: l,
              }))}
            />
          </div>
          {optionRule && (
            <div className="col-4 col-md-6">
              <Label>&nbsp;</Label>
              <div>
                <button className="btn btn-primary mr-2" onClick={resetVal}>
                  Limpiar
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => setOpenDeleteModal(true)}>
                  Eliminar
                </button>
              </div>
            </div>
          )}
        </div>
      </FormGroup>
      {workflow && !reset ? (
        <RuleComponent
          value={rule}
          onChange={setRule}
          onAction={() => (rule ? (!exist ? setOpen(true) : saveRule()) : null)}
          showAction={true}
          labelAction={exist ? 'Agregar regla' : 'Guardar regla'}
        />
      ) : (
        <b>Loading...</b>
      )}
      <div className="row mb-3">
        <div className="col-12">
          <h3>&nbsp;</h3>
          <div className="document-editor">
            <div id="toolbar-container-editor"/>
            <div id="editor_scroll" className="document-editor__editable-container">
              <CKEditor
                config={editorConfiguration}
                editor={DecoupledEditor}
                onInit={(editor: any) => {
                  globalEditor = editor;
                  // Add the toolbar to the container
                  const toolbarContainer = document.querySelector(
                    '#toolbar-container-editor',
                  );
                  if (toolbarContainer)
                    toolbarContainer.appendChild(
                      editor.ui.view.toolbar.element,
                    );
                }}
                data={editorText}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  dispatch(actions.changeEditorText(data));
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary mr-2"
          onClick={() => setNewSignature(true)}>
          Agregar firma
        </button>
        <button
          onClick={() => download()}
          className="btn btn-primary mr-2">
          Descargar
        </button>
        <button
          onClick={() => onUpload(editorText)}
          className="btn btn-primary">
          Actualizar
        </button>
      </div>
      <ModalComponent isOpen={open} toggle={() => null} noFooter>
        <FormGroup>
          <Label>Nombre de la nueva regla</Label>
          <input
            type="text"
            className="form-control"
            placeholder="Nombre de regla"
            onChange={e => setValueNewRule(e.target.value)}
            value={valueNewRule}
          />
          {<small className="text-danger">{errorNameRule}</small>}
        </FormGroup>
        <ModalFooter>
          <button
            disabled={state === 'pending'}
            className="btn btn-primary btn-sm"
            onClick={saveRule}>
            Guardar Regla
          </button>
        </ModalFooter>
      </ModalComponent>

      <ModalComponent
        isOpen={newSignature}
        size="lg"
        noFooter
        toggle={() => setNewSignature(false)}>
        <Card>
          <CardHeader><h5>Assignar una firma</h5></CardHeader>
          <CardBody>
            <input type="text" ref={ref} className="form-control"/>
          </CardBody>
          <CardFooter className="text-right">
            <Button
              onClick={addSignature}
              color="primary">Agregar</Button>
          </CardFooter>
        </Card>
      </ModalComponent>

      <ConfirmModal
        isOpen={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onSuccess={() => {
          dispatch(ActionsRule.deleteRule(optionRule.data));
          resetVal();
        }}
        loading={state === 'deleting'}
      />
    </div>
  );
}

export default EditorComponent;

import React from "react";

interface Props {
  id?: string;
  name?: string;
  value?: boolean;
  onChange?: (e: boolean) => void;
  className?: string;
  labelInfo?: string;
}

const ToggleCheckbox = (props: Props) => {

  return (
    <div className={"custom-control custom-switch " + (props.className || "")}>
      <input type="checkbox"
             className="custom-control-input"
             id={props.id}
             onChange={() => props.onChange && props.onChange(!props.value)}
             checked={props.value}/>
      <label className="custom-control-label"
             htmlFor={props.id}><b>{props.labelInfo === undefined ? "Toggle this switch element": props.labelInfo}</b></label>
    </div>
  )
}

export default ToggleCheckbox;

/* eslint-disable react/prop-types */
import ModalComponent, {ModalComponentProps} from '../Shared/ModalComponent';
import React from 'react';

interface Props extends ModalComponentProps {
  title?: string;
  description?: React.ReactNode;
  labelCancelButton?: string;
  onSuccess: () => void;
  labelSuccessButton?: string;
  setOpen?: (e: boolean) => void;
  loading: boolean;
}

const ConfirmModal: React.FC<Props> = props => {
  const {
    onSuccess,
    setOpen,
    description,
    labelCancelButton,
    labelSuccessButton,
    loading
  } = props;

  return (
    <ModalComponent {...props} noFooter>
      <form
        onSubmit={event => {
          event.preventDefault();
          onSuccess();
        }}>
        <div className="text-center mb-3">
          {description || '¿Deseas hacer esta acción?'}
        </div>
        <div className="text-center">
          <button
            disabled={loading}
            type="button"
            onClick={() => setOpen && setOpen(false)}
            className="btn btn-danger mr-5">
            {labelCancelButton || 'Cancelar'}
          </button>
          <button disabled={loading} className="btn btn-primary">
            {labelSuccessButton || 'Aceptar'}
          </button>
        </div>
      </form>
    </ModalComponent>
  );
};
export default ConfirmModal;

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {State, UserState} from "./Types";


const initialState: UserState = {
  state: State.IDLE,
  users: []
}

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getAllUsers(state) {
      state.state = State.PENDING;
    },
    setAllUsers(state, action: PayloadAction<any[]>) {
      state.state = State.RESOLVED;
      state.users = action.payload;
    },
    changeState(state, action: PayloadAction<State>) {
      state.state = action.payload;
    }
  }
})

export const usersAction = slice.actions;
export default slice.reducer;

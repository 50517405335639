import React, {useEffect} from "react";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import TableComponent from "../../Shared/TableComponent/TableComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../Store/Reducers";
import {State} from "../../../Store/Users/Types";
import {usersAction} from "../../../Store/Users/Slice";
import {download} from "../../../Utils/Utils";

const UsersListComponent = () => {


  const {users, state} = useSelector((state: RootState) => state.usersReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersAction.getAllUsers())
  }, [dispatch])


  function onDownload() {
    let columns = ["_KEY", "name", "last_name", "email", "role"];
    let csvContent = `${columns.join(",")};\n`;
    users.forEach(user => {
      csvContent += `${columns.map(key => user[key]).join(",")};\n`;
    });
    download(csvContent, `users-${Date.now()}.csv`, 'text/csv;encoding:utf-8');
  }


  return (
    <Card className="card-table">
      <CardHeader className="d-flex justify-content-between align-items-center">
        <h3 className="m-0">Usuarios:</h3>
        <Button onClick={onDownload} color="success"><FontAwesomeIcon icon={faFileExport}/> Exportar</Button>
      </CardHeader>
      <CardBody>
        <TableComponent
          rowsCount={20}
          loading={state === State.PENDING}
          pagination
          filter
          rows={users}
          headers={[
            {
              field: "name",
              header: "Nombre",
              type: "String"
            },
            {
              field: "last_name",
              header: "Apellido",
              type: "String"
            },
            {
              field: "email",
              header: "Correo",
              type: "String"
            }
          ]}/>
      </CardBody>
    </Card>
  )
}

export default UsersListComponent;

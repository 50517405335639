import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Store/Reducers';
import { actions } from '../../../Store/DataList/Slice';
import { Link, useParams } from 'react-router-dom';
import SpinnerComponent from '../../Shared/SpinnerComponent';
import FaSpinnerComponent from '../../Shared/FaSpinnerComponent';
import DataList from '../../../Models/FormBuilder/DataList';
import { LabelComponent, SelectComponent } from 'sbx-ui-input-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

const Row: React.FC<{
  dataList: DataList;
  row: any;
  dragProvider?: any;
  thStyle?: any;
  create?: boolean;
}> = ({ dataList, row, create, dragProvider = {},thStyle={} }) => {
  const { state } = useSelector((state: RootState) => state.dataListReducer);
  const [editView, setEditView] = useState(false);
  const [value, setValue] = useState('');
  const [label, setLabel] = useState('');
  const dispatch = useDispatch();

  const deleting = state === 'deletingDataListOption';
  const saving = state === 'savingDataListOption';

  const handleEdit = () => {
    setEditView(true);
    setValue(row[dataList.value_field]);
    setLabel(row[dataList.label_field]);
  };

  useEffect(() => {
    if (state === 'idle') {
      setEditView(false);
    }
  }, [state]);

  const handleSave = () => {
    if (
      row[dataList.value_field] === value &&
      row[dataList.label_field] === label
    ) {
      return setEditView(false);
    }
    const data = { dataListId: dataList.id, option: Object.assign({}, row) };
    data.option[dataList.value_field] = value;
    data.option[dataList.label_field] = label;
    setValue('');
    setLabel('');
    if (row.id) {
      dispatch(actions.getUpdateDataListOption(data));
    } else {
      dispatch(actions.getAddDataListOption(data));
    }
  };

  const handleDelete = () => {
    dispatch(
      actions.getDeleteDataListOption({
        dataListId: dataList.id,
        optionId: row.id,
      }),
    );

    return;
  };

  return (
    <>
      <div style={thStyle} className="d-flex">
        {editView || create ? (
          <div className="form-group mb-0">
            <input
              onChange={e => setValue(e.target.value)}
              className="form-control form-control-sm"
              value={value || ''}
            />
          </div>
        ) : (
          <>
            {dragProvider && (
              <span
                {...dragProvider.dragHandleProps}
                className="mr-2">
                <FontAwesomeIcon icon={faGripVertical} />
              </span>
            )}
            {row[dataList.value_field]}
          </>
        )}
      </div>
      <div style={thStyle} className="d-flex">
        {editView || create ? (
          <div className="form-group mb-0">
            <input
              onChange={e => setLabel(e.target.value)}
              className="form-control form-control-sm"
              value={label || ''}
            />
          </div>
        ) : (
          row[dataList.label_field]
        )}
      </div>
      {dataList.provider_type === 'DATABASE' && (
        <div style={thStyle} className="d-flex">
          {editView || create ? (
            <>
              <button
                className="btn btn-sm btn-primary mr-1"
                onClick={handleSave}
                disabled={saving}>
                {saving && <FaSpinnerComponent />} Guardar
              </button>
              {!create && (
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => setEditView(false)}
                  disabled={saving}>
                  Cancelar
                </button>
              )}
            </>
          ) : (
            <>
              <button
                className="btn btn-sm btn-primary mr-2"
                onClick={handleEdit}
                disabled={deleting}>
                Editar
              </button>
              <button
                className="btn btn-sm btn-danger"
                onClick={handleDelete}
                disabled={deleting || saving}>
                {deleting && <FaSpinnerComponent />} Eliminar
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
};

const DataListComponent = () => {
  const { dataListId } = useParams<any>();
  const dispatch = useDispatch();
  const [valueSelect, setValueSelect] = useState(null);
  const { state, currentDataList } = useSelector(
    (state: RootState) => state.dataListReducer,
  );
  const thStyle: CSSProperties = {
    width: currentDataList?.provider_type === 'DATABASE' ? '30%' : '50%',
  };

  let options: any = [];

  if (currentDataList) {
    options = currentDataList.options.map(item => ({
      value: item[currentDataList.value_field],
      label: item[currentDataList.label_field],
    }));
  }

  useEffect(() => {
    dispatch(actions.getDataListWithOptions({ dataListId }));
  }, [dispatch, dataListId]);
  const dragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (
      destination &&
      source &&
      destination.index !== source.index &&
      currentDataList &&
      currentDataList?.provider_type === 'DATABASE'
    ) {
      const resultRows = [...currentDataList.options];
      // remove `from` item and store it
      const from = resultRows.splice(source.index, 1)[0];
      // insert stored item into position `to`
      resultRows.splice(destination.index, 0, from);
      dispatch(
        actions.getUpdateDataListOptions({
          dataList: {
            ...currentDataList,
            options: resultRows.map((i, index) => ({
              ...i,
              sort_index: index,
            })),
          },
        }),
      );
      dispatch(
        actions.setDataListWithOptions({
          ...currentDataList,
          options: resultRows.map((i, index) => ({
            ...i,
            sort_index: index,
          })),
        }),
      );
    }
  };
  return (
    <>
      {state === 'pending' ? (
        <div className="text-center">
          <SpinnerComponent />
        </div>
      ) : (
        <div className="card">
          <div className="card-header d-flex align-items-center">
            <Link to="/data_list" className="btn btn-sm btn-primary mr-2">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <h3 className="mb-0">Proveedor de datos</h3>
          </div>
          <div className="card-body row">
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-header py-1 px-3">
                  <h5 className="">{currentDataList?.name}</h5>
                </div>
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={thStyle}>Atributo</th>
                        <th style={thStyle}>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Nombre</td>
                        <td className="text">{currentDataList?.name}</td>
                      </tr>
                      {/*<tr><td>Query</td><td className="text">{currentDataList?.query}</td></tr>*/}
                      {/*<tr><td>Tipo de proveedor</td><td>{currentDataList?.provider_type}</td></tr>*/}
                      <tr>
                        <td>Referencia valor</td>
                        <td>{currentDataList?.value_field}</td>
                      </tr>
                      <tr>
                        <td>Referencia etiqueta</td>
                        <td>{currentDataList?.label_field}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="form-group">
                    <LabelComponent inputId="select_input">
                      Vista Previa:
                    </LabelComponent>
                    <SelectComponent
                      id="select_input"
                      name="select_input"
                      options={options}
                      value={valueSelect}
                      placeholder="here placeholder select"
                      onChange={e => setValueSelect(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <h5 className="mb-0">Lista de Opciones</h5>
              <DragDropContext onDragEnd={dragEnd}>
                <div className="d-flex border-bottom mb-2">
                  <span style={thStyle}>Valor</span>
                  <span style={thStyle}>Etiqueta</span>
                  {currentDataList?.provider_type === 'DATABASE' && (
                    <span style={{ width: '40%' }}>Acciones</span>
                  )}
                </div>

                <Droppable droppableId="droppable-1" type="OPTIONS">
                  {(provided, snapshot) => (
                    <div
                      style={{ overflow: 'auto', height: '300px' }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}>
                      {currentDataList?.options.map((row, index) => (
                        <Draggable
                          // adding a key is important!
                          key={row.id}
                          draggableId={row.id.toString()}
                          index={index}>
                          {providedDrag => (
                            <div
                              className="d-flex mb-2"
                              ref={providedDrag.innerRef}
                              {...providedDrag.draggableProps}>
                              <Row
                                dragProvider={providedDrag}
                                thStyle={thStyle}
                                key={row.id}
                                dataList={currentDataList}
                                row={row}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <div>
                  {currentDataList && (
                    <div className="d-flex justify-content-between align-items-center">
                      <Row dataList={currentDataList} row={{}} create={true} />
                    </div>
                  )}
                </div>
              </DragDropContext>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DataListComponent;

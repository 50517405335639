import {sbxCoreService} from '../Network';
import Axios, {AxiosPromise} from 'axios';
import Category from '../Models/Category';
import Response from '../Models/Response';
import Document from '../Models/Document';
import {plainToClass} from 'class-transformer';
import Folder from '../Models/Folder';
import env from '../Environment';
import {refactorPreview} from "../Utils/Utils";

export const getFolderContent = (key: string): Promise<Response<any>> => {
  return sbxCoreService.listFolder(key);
};

export const uploadFile = (
  file: File,
  folderKey: string,
): Promise<Response<any>> => {
  return sbxCoreService.uploadFile(folderKey, file);
};
export const uploadNewFile = (
  content: string,
  folderKey: string,
  name: string,
): Promise<Response<any>> => {
  const file = new File([content], name + '.slg', {});
  return sbxCoreService.uploadFile(folderKey, file);
};
export const downloadFile = (keyFile: string): Promise<any> => {
  return sbxCoreService.downloadFile(keyFile);
};
export const createFile = (keyFile: string): Promise<Response<any>> => {
  return sbxCoreService.downloadFile(keyFile);
};

export const getPreviewDocs = (): Promise<Response<any>> => {
  return sbxCoreService.run('680931e9-911c-4244-9456-bc3f64269255', {});
};

export function updateDictionary(model: "document" | string, fields: Array<string>, key?: string) {
  return sbxCoreService.run(env.cloudScripts.sbxSearch, {name: model, fields, key});
}

export const getDocuments = async (
  category: Category,
): Promise<{
  success: boolean;
  contents?: Document[];
  folder?: Folder;
}> => {
  const res: any = await sbxCoreService
    .with('document')
    .andWhereIsEqualTo('category', category.key)
    .loadAll();

  if (res) {
    const resContent: any = await getFolderContent(category.folder_key);
    if (resContent.success) {
      return {
        success: true,
        contents: res.results.map((i: any) => plainToClass(Document, i)),
        folder: resContent.folder,
      };
    }
  }
  return {success: false};
};

export const getFile = async (keyFile: string): Promise<any> => {
  const res = await downloadFile(keyFile);
  return new Promise(((resolve, reject) => {
    try {
      getFileURL(res.url)
        .then((res) => {
          resolve(refactorPreview(`${res}`))
        })
        .catch(() => resolve("Failed to download file"));
    } catch (e) {
      resolve("Failed to download file")
    }
  }))
};
export const getFileURL = (url: string): AxiosPromise => {
  return Axios.get(url).then(res => res.data);
};

export function convertStringToPdfService(text: string) {
  return Axios.post("http://ec2-3-82-1-83.compute-1.amazonaws.com:8080/api/v1/generate/pdf",
    {
      html: `<div> ${text} </div>`
    },
    {responseType: "blob"})
    .then(res => res.data)
}

export function getPreview(processId: number, executionId: number, documentKey: string) {
  return sbxCoreService.run(env.cloudScripts.getPreview, {
    processId,
    executionId,
    key: documentKey,
    access_token: localStorage.getItem("access_token")
  })
}



import {sbxCoreService} from '../Network';
import Category from "../Models/Category";
import {editFolder} from "./FolderService";
import {plainToClass} from "class-transformer";
import environment from "../Environment";

export const createCategory = (category: Category) => {
  delete category.image;
  return sbxCoreService.insert('category', category).then((res: any) => {
    // res.results = plainToClass(res.results, Category);
    return res;
  });
};
export const editCategory = async (category: Category) => {
  const res = await sbxCoreService.with('category')
    .update({_KEY: category.key, name: category.name, description: category.description, icon: category.icon})
  if (res) {
    const resUpdateFolder = await editFolder(category.folder_key, category.name);
    if (resUpdateFolder) {
      return Promise.resolve({success: true, category})
    }
  }
  return Promise.reject();
};
export const getCategories = () => {
  return sbxCoreService.with('category').loadAll().then((res: any) => {

    res.results = plainToClass(Category, res.results, {excludeExtraneousValues: true});
    res.results?.sort();

    return res;
  });
};

export function uploadImage(file: Blob) {
  return sbxCoreService.uploadFile(environment.folders.imagesCategories, file);
}


export const deleteCategory = (category: Category) => {
  return sbxCoreService.with("category")
    .whereWithKeys([category.key])
    .delete();
}

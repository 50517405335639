import React from 'react';
import ModalComponent from '../../../Shared/ModalComponent';
import {ModalProps} from 'reactstrap';
import Category from '../../../../Models/Category';
import {useDispatch} from 'react-redux';
import {actionsCategory} from '../../../../Store/Category/Slice';

interface Props extends ModalProps {
  category: Category;
}

const DeleteCategoryModal: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const onsubmit = () => {
      dispatch(actionsCategory.getDeleteCategory(props.category));
      if (props.onClosed) {
        props.onClosed();
      }
    };

    return <ModalComponent {...props} title="Eliminar categoria" noFooter>
      <form onSubmit={event => {
        event.preventDefault();
        onsubmit();
      }}>

        <p>¿Desea eliminar la categoria de {props.category?.name}?</p>

        <div className="">
          <button type="button" onClick={props.onClosed} className="btn btn-danger mr-3">Cancelar</button>
          <button
            className="btn btn-primary">Eliminar categoria
          </button>
        </div>
      </form>
    </ModalComponent>;
  }
;

export default DeleteCategoryModal;

import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export interface ModalComponentProps extends ModalProps {
  onSuccess?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  noFooter?: boolean;
  header?: React.ReactNode;
  title?: string;
  footer?: string;
  onClose?: () => void;
  labelSuccess?: string;
  labelCancel?: string;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
                                                         children,
                                                         noFooter,
                                                         isOpen,
                                                         toggle,
                                                         onSuccess,
                                                         disabled,
                                                         isLoading,
                                                         header,
                                                         footer,
                                                         size = "md",
                                                         title,
                                                         onClose,
                                                         labelCancel,
                                                         labelSuccess
                                                       }) => {
  return (
    <Modal zIndex={2000} scrollable toggle={toggle} size={size} isOpen={isOpen}>
      {header || title ? <ModalHeader>{header || title}</ModalHeader> : null}
      <ModalBody>{children}</ModalBody>
      {!noFooter ? (
        <ModalFooter>
          {footer ? (
            footer
          ) : (
            <>
              {onClose && <Button disabled={isLoading} color="secondary" onClick={onClose}>
                {labelCancel || "Cancel"}
              </Button>}
              <button
                disabled={disabled || isLoading}
                className="btn btn-primary"
                onClick={onSuccess || onClose}
              >
                {labelSuccess || "Save"}
                {isLoading ? <FontAwesomeIcon className="mr-2" icon={faSpinner} pulse/> : null}
              </button>
            </>
          )}
        </ModalFooter>
      ) : null}
    </Modal>
  );
};

export default ModalComponent;

enum FieldType {
  OPTIONS = 'OPTIONS',
  TABLE = 'TABLE',
  FORM_GROUP = 'FORM_GROUP',
  SMALL_TEXT = 'SMALL_TEXT',
  DATE = 'DATE',
  LARGE_TEXT = 'LARGE_TEXT',
  FILE = 'FILE',
  DATE_RANGE = 'DATE_RANGE'
}

export default FieldType;

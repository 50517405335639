import React, {useEffect, useState} from 'react';
import {Route, RouteComponentProps, Switch} from 'react-router-dom';
import CategoryListComponent from "./CategoryList/CategoryListComponent";
import CreateCategoryModal from "./CreateCategory/CreateCategoryModal";
import {useDispatch, useSelector} from "react-redux";
import {actionsCategory} from "../../../Store/Category/Slice";
import {RootState} from "../../../Store/Reducers";

const CategoryComponent: React.FC<RouteComponentProps> = ({match}) => {
  const [openModalCategory, setOpenModalCategory] = useState(false);
  const dispatch = useDispatch();
  const {list, state} = useSelector((state: RootState) => state.categoryReducer);

  useEffect(() => {
    dispatch(actionsCategory.getCategories());
    return () => {
    };
  }, [dispatch]);

  const setCreateModal = () => {
    setOpenModalCategory(true);
    dispatch(actionsCategory.setCreateModal());
  }

  return <div className="card">
    <div className="card-header d-flex">
      <h3 className="mr-auto">Listado de categorias: </h3>
      <button className="btn btn-primary" onClick={setCreateModal}>Crear nueva
        categoria
      </button>
    </div>
    <div className="card-body scroll-height-table">
      <Switch>
        <Route render={() => <CategoryListComponent list={list} state={state}/>} exact
               path={`${match.path}/`}/>
      </Switch>
    </div>
    <CreateCategoryModal isOpen={openModalCategory} toggle={() => setOpenModalCategory(false)}
                         onClosed={() => setOpenModalCategory(false)}/>
  </div>
}

export default CategoryComponent;

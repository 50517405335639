import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SectionState, SetFolderList, StateReducer} from './Types';
import Document from '../../Models/Document';
import Section from '../../Models/Section';

const initialState: SectionState = {list: [], state: 'idle'};

const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    setSections(state, action: PayloadAction<SetFolderList>) {
      state.list = action.payload.content;
      state.state = 'resolved';
      state.document = action.payload.workflow;
    },
    getSections(state, action: PayloadAction<string>) {
      state.state = StateReducer.PENDING;
    },
    getAddSection(
      state,
      action: PayloadAction<{
        name: string;
        document: Document;
        description: string;
        prevSec?: Section;
      }>,
    ) {
      state.state = StateReducer.PENDING;
    },
    setAddSection(state, action: PayloadAction<string>) {
      state.state = StateReducer.PENDING;
    },
    getEditDocument(state, action: PayloadAction<Document>) {
      state.state = StateReducer.PENDING;
    },
    setEditDocument(state, action: PayloadAction<Document>) {
      state.state = 'resolved';
      state.document = action.payload;
    },
    getAddNewFolder(state, action) {
      state.state = StateReducer.PENDING;
    },
    setAddNewFolder(state, action) {
      state.state = 'resolved';
    },
    getAddNewFile(
      state,
      action: PayloadAction<{ parentKey: string; name: string }>,
    ) {
      state.state = StateReducer.PENDING;
    },
    setAddNewFile(state, action) {
      state.state = 'resolved';
    },
    renameSection(
      state,
      action: PayloadAction<{
        name: string;
        description: string;
        key: string;
        workFLowId: string;
      }>,
    ) {
      state.state = StateReducer.PENDING;
    },

    deleteSection(
      state,
      action: PayloadAction<{
        document: Document;
        task: Section;
        workflowId: string;
      }>,
    ) {
      state.state = StateReducer.PENDING;
    },
    rejectAction(state) {
      state.state = StateReducer.REJECTED;
    },

    getSection(
      state,
      action: PayloadAction<{ section: string; document: string }>,
    ) {
      state.state = StateReducer.PENDING;
    },
    setSection(
      state,
      action: PayloadAction<{ section: Section; document: Document }>,
    ) {
      state.state = StateReducer.RESOLVED;
      state.section = action.payload.section;
      state.document = action.payload.document;
    },
    restart(state) {
      state.state = StateReducer.RESOLVED;
      state.section = undefined;
      state.document = undefined;
    },
  },
});

export const actions = sectionSlice.actions;

export default sectionSlice.reducer;

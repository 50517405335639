import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CategoryState, State} from './Types';
import Category from '../../Models/Category';

const initialState: CategoryState = {list: [], state: State.IDLE};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategories(state, action: PayloadAction<Category[]>) {
      state.list = action.payload;
      state.state = State.RESOLVED;
    }, getCategories(state) {
      state.state = State.PENDING;
    }, getEditCategory(state, action: PayloadAction<Category>) {
      state.state = State.PENDING;
    }, getDeleteCategory(state, action: PayloadAction<Category>) {
      state.state = State.PENDING;
    },
    setEditCategory(state, action: PayloadAction<Category[]>) {
      state.state = State.RESOLVED;
      state.list = action.payload;
    },
    setCurrentCategory(state, action: PayloadAction<Category>) {
      state.currentCategory = action.payload;
    },
    setAddNewCategory(state, action: PayloadAction<Category[]>) {
      state.state = State.RESOLVED;
      state.list = action.payload;
    },
    getAddNewCategory(state, action: PayloadAction<{ name: string, description?: string, image: Blob  }>) {
      state.state = State.PENDING;
    },
    setEditModal(state) {
      state.modalState = State.EDITING;
    },
    setCreateModal(state) {
      state.modalState = State.CREATING;
    },
    setDeleteModal(state) {
      state.modalState = State.DELETING;
    }
  }
});

export const actionsCategory = slice.actions;

export default slice.reducer;

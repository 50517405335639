import DataList from "../../Models/FormBuilder/DataList";

export const GET_ALL_DATA_LIST = 'dataList/getAllDataList';
export const GET_ADD_DATA_LIST = 'dataList/getAddDataList';
export const GET_DELETE_DATA_LIST = 'dataList/getDeleteDataList';
export const GET_EDIT_DATA_LIST = 'dataList/getUpdateDataList';
export const GET_DATA_LIST_WITH_OPTIONS = 'dataList/getDataListWithOptions';
export const GET_ADD_DATA_LIST_OPTION = 'dataList/getAddDataListOption';
export const GET_EDIT_DATA_LIST_OPTION = 'dataList/getUpdateDataListOption';
export const GET_DELETE_DATA_LIST_OPTION = 'dataList/getDeleteDataListOption';

export interface DataListState {
  state: string,
  list: DataList[],
  currentDataList?: DataList
}

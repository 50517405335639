import env from "../Environment";
import {get, post, put, deleteReq} from "../Network";
import Response from "../Models/Response";
import DataList from "../Models/FormBuilder/DataList";

export const getAllDataList = (): Promise<Response<any>> => {
  return get(`/api/v2/${env.domain}/wf/dl`);
}

export const getDataList = (dataListId: string | number): Promise<Response<any>> => {
  return get(`/api/v2/${env.domain}/wf/dl/${dataListId}`);
}

export const getDataListWithOptions = (dataListId: string | number): Promise<Response<any>> => {
  return get(`/api/v2/${env.domain}/wf/dl/${dataListId}/options`);
}

export const createDataListOption = (dataListId: string | number, option: any): Promise<Response<any>> => {
  return post(`/api/v2/${env.domain}/wf/dl/${dataListId}/options`, option);
}

export const updateDataListOption = (dataListId: string | number, option: any): Promise<Response<any>> => {
  return put(`/api/v2/${env.domain}/wf/dl/${dataListId}/options/${option.id}`, option);
}

export const deleteDataListOption = (dataListId: string | number, optionId: string | number): Promise<Response<any>> => {
  return deleteReq(`/api/v2/${env.domain}/wf/dl/${dataListId}/options/${optionId}`);
}

export const createDataList = (dataList: DataList): Promise<Response<any>> => {
  return post(`/api/v2/${env.domain}/wf/dl`, dataList);
}

export const updateDataList = (dataList: DataList): Promise<Response<any>> => {
  return put(`/api/v2/${env.domain}/wf/dl/${dataList.id}`, dataList);
}

export const deleteDataList = (dataListId: string | number): Promise<Response<any>> => {
  return deleteReq(`/api/v2/${env.domain}/wf/dl/${dataListId}`);
}

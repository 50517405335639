import React, {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import EditorComponent from "../../EditorComponent/EditorComponent";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../Store/Reducers";
import {actions as ruleAction} from "../../../../Store/DocumentRule/Slice";
import {actions} from "../../../../Store/Section/Slice";
import {uploadFragment} from "../../../../Services/FragmentService";

const FragmentDetailComponent = () => {
  const {workflowId} = useParams();
  const {sectionReducer: {document}} = useSelector((state: RootState) => state);
  const dispatch = useDispatch();


  useEffect(() => {
    if (workflowId) {
      dispatch(ruleAction.getRuleByDocument(workflowId));
    }
  }, [dispatch, workflowId])

  useEffect(() => {
    if (!document && workflowId) {
      dispatch(actions.getSections(workflowId))
    }
  }, [document, workflowId, dispatch])


  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          <Link
            to={`/sections/${workflowId}`}
            className="btn btn-sm btn-primary mr-2">
            <FontAwesomeIcon icon={faArrowLeft}/>
          </Link>
          <h3 className="m-0">Documento: {document?.name}</h3>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <EditorComponent onUpload={text => uploadFragment(text, document)}/>
        </div>
      </div>
    </>
  )
}

export default FragmentDetailComponent;

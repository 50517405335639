import React from 'react';
import { NavItem, NavLink, Nav } from 'reactstrap';
import {faCropAlt, faThList, faCopy, faUser} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

const SideBarComponent = (props: any) => {
  return (
    <div className={`sidebar ${props.isOpen ? 'is-open' : ''}`}>
      <div className="sidebar-header">
        <span color="info" onClick={props.toggle} style={{color: '#fff'}}>&times;</span>
        <h5>Dashboard</h5>
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3">
          {/*<SubMenu title="Home" icon={faHome} items={submenus[0]}/>*/}
          <NavItem>
            <NavLink tag={Link} to={'/'}>
              <FontAwesomeIcon icon={faCopy} className="mr-2"/>Listado de documentos
            </NavLink>
          </NavItem>
          {/*<SubMenu title="Pages" icon={faCopy} items={submenus[1]}/>*/}
          <NavItem>
            <NavLink tag={Link} to={'/categories'}>
              <FontAwesomeIcon icon={faCropAlt} className="mr-2"/>Listado de categorias
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink tag={Link} to={'/data_list'}>
              <FontAwesomeIcon icon={faThList} className="mr-2"/>Proveedores de datos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={'/users'}>
              <FontAwesomeIcon icon={faUser} className="mr-2"/>Usuarios registrados
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </div>
  );
};

// const submenus = [
//   [
//     {
//       title: "Home 1",
//       target: "Home-1"
//     },
//     {
//       title: "Home 2",
//       target: "Home-2",
//     },
//     {
//       itle: "Home 3",
//       target: "Home-3",
//     }
//   ],
//   [
//     {
//       title: "Page 1",
//       target: "Page-1",
//     },
//     {
//       title: "Page 2",
//       target: "Page-2",
//     }
//   ]
// ];

export default SideBarComponent;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditorState } from './Types';
import Section from '../../Models/Section';
import Document from '../../Models/Document';

const initialState: EditorState = {
  editorText: '',
  state: 'idle',
  doc: undefined,
  section: undefined,
};
const slice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setDocument(
      state,
      action: PayloadAction<{
        docText: string;
        doc: Document;
        section: Section;
      }>,
    ) {
      state.editorText = action.payload.docText;
      state.doc = action.payload.doc;
      state.section = action.payload.section;
      state.state = 'editing';
    },
    getDocument(
      state,
      action: PayloadAction<{ section: string; document: string }>,
    ) {
      state.state = 'pending';
    },
    changeEditorText(state, action: PayloadAction<string>) {
      state.editorText = action.payload;
      state.state = 'editing';
    },
    restart: () => {
      return initialState;
    },
  },
});

export const { ...actions } = slice.actions;

export default slice.reducer;

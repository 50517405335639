import env from '../../Environment';
import {deleteReq, get as getProcess, post} from '../../Network/ProcessNetwork';
import Response from '../../Models/Response';
import TaskType from '../../Models/Workflow/TaskType';
import {get} from '../../Network';

export async function createTask(
  name: string,
  process_model_id: number,
  task_type: TaskType,
  rule = '',
  label = 'label',
): Promise<Response<any>> {
  const res = await post(
    `/api/v2/${env.domain}/wf/process/model/${process_model_id}/task`,
    {
      name,
      label,
      rule,
      task_type,
    },
  );

  if (res.success) {
    return Promise.resolve(res);
  } else {
    return Promise.reject({success: false});
  }
}

export async function getTaskDetail(
  workflowId: number,
  taskId: number,
): Promise<Response<any>> {
  return getProcess(
    `/api/v2/${env.domain}/wf/process/model/${workflowId}/task/${taskId}`,
  )
    .then(async (res: any) => {
      if (res) {
        res.item.attachments = await Promise.all(
          res.item.attachments.map(async (f: any) => {
            const form = await get(
              `/api/v2/${env.domain}/wf/form/${f.attachment_id}`,
            );
            return Object.assign(form.item, f);
          }),
        ).catch(() => []);
      }
      return Promise.resolve(res);
    })
    .catch(err => Promise.reject({success: false}));
}


export async function deleteTaskService(modelId: number, taskId: number): Promise<Response<any>> {
  const res = await deleteReq(`/api/v2/${env.domain}/wf/process/model/${modelId}/task/${taskId}`);
  if (res.success) {
    return Promise.resolve(res)
  } else {
    return Promise.reject({success: false})
  }
}

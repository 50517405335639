export const SEND_MESSAGE = 'SEND_MESSAGE';
export const LOGIN = 'LOGIN';

interface SendMessageAction {
  type: typeof SEND_MESSAGE
  payload: any
}

export interface AuthState {
  messages: any[]
}

interface LoginAction {
  type: typeof LOGIN
  meta: {
    timestamp: number
  }
}

export type ChatActionTypes = SendMessageAction | LoginAction

import React, {CSSProperties, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEdit, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";

interface Props {
  label: React.ReactNode;
  value: string,
  loading?: boolean;
  className?: string;
  onSuccess?: (value: string) => void;
}

const btn: CSSProperties = {
  width: "30px",
  height: "30px"
}

export default ({
                  label,
                  value,
                  loading,
                  onSuccess,
                  className
                }: Props) => {

  const [edit, setEdit] = useState<boolean>(false);
  const [valueField, setValueField] = useState<string>("");

  useEffect(() => {
    setValueField(value);
  }, [value]);

  return (
    <>
      {edit ? <div className="py-0 px-2 m-0 d-flex justify-content-start align-items-center w-100">
        <input value={valueField} onChange={e => setValueField(e.target.value)} type="text"
               className={className || "form-control"}/>
        <button style={btn} onClick={() => {
          onSuccess && onSuccess(valueField);
          setEdit(false);
        }} className="btn btn-primary btn-sm ml-2">
          <FontAwesomeIcon icon={faCheck}/>
        </button>
        <button style={btn} onClick={() => setEdit(e => !e)} className="btn btn-danger btn-sm ml-2">
          <FontAwesomeIcon icon={faTimes}/>
        </button>
      </div> : <div className="d-flex align-items-center">
        <h3 className="m-0 py-0 px-2 ">
          {loading ? <FontAwesomeIcon icon={faSpinner} spin/> : <>
            {label}
          </>}
        </h3>
        {!loading &&
        <FontAwesomeIcon title="Editar documento" style={{cursor: "pointer"}} className="mt-1 text-primary"
                         onClick={() => setEdit(true)}
                         icon={faEdit}/>}
      </div>}

    </>
  )
}

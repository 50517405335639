import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {RootState} from "../../../Store/Reducers";
import Document from "../../../Models/Document";
import Select from "react-select";
import './FolderListComponent.css'
import {actionsCategory} from "../../../Store/Category/Slice";
import {actions} from "../../../Store/Folder/Slice";
import {actions as actionDocument} from "../../../Store/Section/Slice";

import CreateDocumentModal from "../../Modals/CreateDocument";
import DeleteFolderListModal from "../../Modals/DeleteFolderListModal/DeleteFolderListModal";
import TableComponent from "../../Shared/TableComponent/TableComponent";
import {Head} from "../../../Models/Table";
import {plainToClass} from "class-transformer";
import {getParameterByName, setHistorySection} from "../../../Utils/Utils";
import ToggleCheckbox from "../../Shared/ToggleCheckbox";
import ModalComponent from "../../Shared/ModalComponent";

const FolderListComponent = () => {
  const dispatchRedux = useDispatch();
  const [currentFolder, setCurrentFolder] = useState<{ label: string, value: string } | null>(null);
  const [openNewDocModal, setOpenNewDocModal] = useState(false);
  const history = useHistory();
  const [item, deleteItem] = useState<Document>(new Document());
  const [openModal, setOpenModal] = useState(false);
  const {list, state} = useSelector((state1: RootState) => state1.folderReducer);
  const {list: listCategory, state: stateCategory} = useSelector((state1: RootState) => state1.categoryReducer);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [confirm, setConfirm] = useState<{ open: boolean, data: null | Document }>({open: false, data: null})

  useEffect(() => {
    setDocuments(list);
  }, [list])

  useEffect(() => {
      dispatchRedux(actionsCategory.getCategories());
      return () => {
      };
    }, [dispatchRedux]
  );
  const onChange = ({label, value}: any) => {
    setCurrentFolder({label, value});
    const category = listCategory.find(i => i.key === value);
    if (category?.key) {
      setHistorySection(value);
      dispatchRedux(actions.getFolderList(category));
    }
  };

  useEffect(() => {
    if (listCategory.length) {
      let key = getParameterByName("key");
      const first = key ? listCategory.find(a => a.key === key) || listCategory[0] : listCategory[0];
      setCurrentFolder({label: first.name, value: first.key || ''});
      const category = listCategory.find(i => i.key === first.key || '');
      if (category?.key) {
        dispatchRedux(actions.getFolderList(category));
      }
    }
  }, [listCategory, dispatchRedux]);

  const goToDetail = (i: Document) => {
    history.push(`/sections/${i.key}`);
  };

  const headers: Array<Head> = [
    {field: "name", header: "Nombre", width: "60%"},
    {field: "last_updated", header: "Fecha de actualización", type: "Date", width: "20%"},
    {field: "visible_toggle", type: "ReactNode", width: "20%", header: "Visible"}
  ];

  function sortEnd({newRow, oldRow}: { newRow: Document, oldRow: Document }) {
    let newList = new Array(...documents)
    let newIndex = documents.findIndex(a => newRow.key === a.key);
    let oldIndex = documents.findIndex(a => oldRow.key === a.key);

    newList[newIndex] = {...oldRow, sort_index: newIndex};
    newList[oldIndex] = {...newRow, sort_index: oldIndex};
    setDocuments(newList);

    dispatchRedux(actionDocument.getEditDocument(plainToClass(Document, {
      sort_index: oldIndex,
      _KEY: newRow.key
    })));
    dispatchRedux(actionDocument.getEditDocument(plainToClass(Document, {
      sort_index: newIndex,
      _KEY: oldRow.key
    })));
  }

  function onEditDocument() {
    if (confirm.data && confirm.open) {
      let newList = new Array(...documents)
      let index = documents.findIndex(a => confirm.data?.key === a.key);
      let row = documents.find(a => confirm.data?.key === a.key);
      if (row) {
        newList[index] = {...row, visible: confirm.data.visible};
        setDocuments(newList);
        setConfirm({open: false, data: null})
        dispatchRedux(actionDocument.getEditDocument(plainToClass(Document, {
          visible: confirm.data.visible,
          _KEY: confirm.data.key
        })))
      }
    }
  }

  return <div className="card card-table">
    <div className="card-header d-flex">
      <h3 className="mr-3">Categorias: </h3>
      <Select
        className="mr-auto"
        placeholder="Categoria"
        onChange={onChange}
        isLoading={stateCategory !== 'RESOLVED'}
        value={currentFolder}
        options={listCategory.map(category => ({
          value: category.key,
          label: category.name,
        }))}
      />
      <button
        className="btn btn-sm btn-primary"
        onClick={() => {
          setOpenNewDocModal(true);
        }}>
        Crear nuevo documento
      </button>
    </div>
    <div className="card-body">
      <div className="table-responsive">
        <>
          <TableComponent pagination
                          rows={documents.map(doc => {
                            return {
                              ...doc,
                              visible_toggle: <ToggleCheckbox
                                labelInfo={""}
                                id={doc.key}
                                value={doc.visible}
                                onChange={e => setConfirm({
                                  open: true,
                                  data: {...doc, visible: e}
                                })}/>
                            }
                          })}
                          filter
                          loading={state === "pending"}
                          onSortEnd={sortEnd}
                          rowsCount={15}
                          onRemove={(e: Document) => {
                            deleteItem(e);
                            setOpenModal(true);
                          }}
                          drag
                          onEdit={goToDetail}
                          headers={headers}/>
        </>


      </div>
    </div>

    <DeleteFolderListModal
      data={item}
      setOpen={setOpenModal}
      open={openModal}
    />

    <CreateDocumentModal
      isOpen={openNewDocModal}
      category={listCategory.find(
        category => category.key === currentFolder?.value,
      )}
      toggle={() => setOpenNewDocModal(false)}
      onClosed={() => setOpenNewDocModal(false)}
    />
    <ModalComponent
      isOpen={confirm.open}
      onClose={() => setConfirm({open: false, data: null})}
      onSuccess={onEditDocument}
      labelCancel="No"
      labelSuccess="Sí"
    >
      <div>
        {`¿Desea ${confirm.data?.visible ? "Mostrar" : "Ocultar"} este documento?`}
      </div>
    </ModalComponent>
  </div>
};

export default FolderListComponent;

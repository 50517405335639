import React from 'react';
import {TableBody, TableCell, TableRow} from '@material-ui/core';
import {Head} from '../../../Models/Table';
import {getValue} from './TableComponent';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGripVertical} from '@fortawesome/free-solid-svg-icons';

interface Props {
  actions: boolean;
  headers: Head[];
  rows: any[];
  alignRows?: 'left' | 'right' | 'center';
  onRemove?: (data: any) => any;
  onEdit?: (data: any) => any;
  onEvent?: (data: any) => any;
  labelRemove?: string;
  labelEdit?: string;
  labelEvent?: string;
  drag?: boolean;
  onSortEnd?: (data: any) => void;
  setRows: (rows: any[]) => void;
  isDragging?: boolean;
}

export default ({
                  onSortEnd,
                  actions,
                  alignRows,
                  headers,
                  rows,
                  drag = false,
                  labelEdit,
                  labelEvent,
                  labelRemove,
                  onEdit,
                  onEvent,
                  onRemove,
                  setRows,
                  isDragging
                }: Props) => {

  const ButtonsActions = ({row}: { row: any }) => {
    return (
      <>
        {onEdit && (
          <button
            onClick={() => onEdit(row)}
            className="btn btn-sm btn-primary mr-1">
            {labelEdit || 'Editar'}
          </button>
        )}
        {onEvent && (
          <button
            onClick={() => onEvent(row)}
            className="btn btn-sm btn-info mr-1">
            {labelEvent || 'Evento'}
          </button>
        )}
        {onRemove && (
          <button
            onClick={() => onRemove(row)}
            className="btn btn-sm btn-danger mr-1">
            {labelRemove || 'Borrar'}
          </button>
        )}
      </>
    );
  };

  const dragEnd = ({destination, source}: any) => {
    if (destination && source && destination.index !== source.index) {
      let resultRows = rows;
      const newRow = rows[destination.index];
      const oldRow = rows[source.index];
      resultRows[source.index] = newRow;
      resultRows[destination.index] = oldRow;
      setRows(resultRows);
      onSortEnd && onSortEnd({newRow, oldRow, newRows: resultRows});
    }
  };

  const dragging = (d: any) => isDragging ? {} : d;

  return (
    <TableBody>
      {!drag && (!!rows.length ?
        rows.map((r: any, i) => (
          <TableRow key={i}>
            {headers.map((h, x) => (
              <TableCell key={x} className={"font-roboto"} align={h.align || alignRows}>
                {getValue(r[h.field], h.type)}
              </TableCell>
            ))}
            {actions && (
              <TableCell align={'right'} className="font-roboto">
                <ButtonsActions row={r}/>
              </TableCell>
            )}
          </TableRow>
        )) : <TableRow>
          <TableCell rowSpan={headers.length}>
            <small>No hay resultados</small>
          </TableCell>
        </TableRow>)}
      {drag && (
        <TableRow>
          <TableCell className="p-0 m-0 table-sort-custom">
            {/*table header */}
            <div className="MuiTableRow-root MuiTableRow-head">
              <span className="MuiTableCell-root MuiTableCell-head">
                <FontAwesomeIcon
                  style={{opacity: '0'}}
                  icon={faGripVertical}
                />
              </span>
              {headers.map((h, x) => (
                <span
                  style={{width: h.width}}
                  className="MuiTableCell-root MuiTableCell-head font-roboto"
                  key={x}>
                  {h.header}
                </span>
              ))}
              {actions && (
                <span
                  style={{minWidth: '250px'}}
                  className="MuiTableCell-root MuiTableCell-head font-roboto"
                />
              )}
            </div>
            {/*end table header */}
            <DragDropContext onDragEnd={dragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="w-100">

                    {!!rows.length ? rows.map((item: any, i: number) => (
                        <Draggable draggableId={i + 'drag'} key={i} index={i}>
                          {(provided1, snapshot1) => (
                            <div
                              ref={provided1.innerRef}
                              {...provided1.draggableProps}
                              className="MuiTableRow-root">
                            <span
                              {...dragging(provided1.dragHandleProps)}
                              className="MuiTableCell-root MuiTableCell-body">
                              <FontAwesomeIcon icon={faGripVertical}/>
                            </span>
                              {headers.map((h, x) => (
                                <div
                                  style={{width: h.width}}
                                  className="MuiTableCell-root MuiTableCell-body font-roboto"
                                  key={x}>
                                  {h.type === "html" ?
                                    (
                                      <div className="table-text-limit"
                                            dangerouslySetInnerHTML={
                                              {__html: getValue(item[h.field], h.type)}}/>
                                    ) : getValue(item[h.field], h.type)
                                  }
                                </div>
                              ))}
                              {actions && (
                                <span
                                  style={{minWidth: '250px'}}
                                  className="MuiTableCell-root MuiTableCell-body text-right font-roboto">
                                <ButtonsActions row={item}/>
                              </span>
                              )}
                            </div>
                          )}
                        </Draggable>
                      )) :
                      <div className="MuiTableCell-root MuiTableCell-body border-0">
                        <small>No hay resultados</small>
                      </div>}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

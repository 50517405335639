import React, {useEffect, useState} from 'react';
import ModalComponent from '../../Shared/ModalComponent';
import CreateFieldForm from './CreateFieldForm';
import CreateFieldPreview from './CreateFieldPreview';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../Store/Reducers';
import {actions as actionsDataList} from "../../../Store/DataList/Slice";
import {smallTextField} from "../../Main/FormBuilder/Fields";
import IField from "../../../Models/FormBuilder/IField";
import {setNameToField} from "../../../Utils/Utils";

interface Props {
  onSuccess: (oldField?: IField) => void;
  onError: () => void;
}

const CreateFieldModal: React.FC<Props> = ({onSuccess, onError}: Props) => {
  const {currentField, state, modalOpen, parentField} = useSelector(
    (state: RootState) => state.formBuilder,
  );

  const field = currentField || parentField || smallTextField;

  const [tempField] = useState<undefined | IField>(setNameToField(field));
  const reduxDispatch = useDispatch();
  useEffect(() => {
    reduxDispatch(actionsDataList.getAllDataList());
  }, [reduxDispatch]);


  return (
    <ModalComponent
      size="xl"
      isLoading={state === 'creating_field'}
      isOpen={modalOpen}
      onClose={onError}
      toggle={onError}
      labelSuccess="Guardar"
      labelCancel="Cancelar"
      onSuccess={() => onSuccess(tempField)}>
      <h4>Metadata del campo</h4>
      <div className="row justify-content-center type-l">
        <div className="col-8 p-4 bg-light">
          {field && (
            <>
              <h4>Vista previa</h4>
              <CreateFieldPreview field={field} valueNewRow={null}/>
            </>
          )}
        </div>
        <div className="col-12 pt-3">
          <CreateFieldForm field={field}/>
        </div>
      </div>
    </ModalComponent>
  );
};

export default CreateFieldModal;

import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import {
  GET_ADD_FRAGMENT,
  GET_CURRENT_FRAGMENT,
  GET_FRAGMENTS,
  ON_SORT_END_FRAGMENTS,
  REMOVE_FRAGMENT,
  UPDATE_FRAGMENT
} from './Types';
import {actions} from './Slice';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  addNewFragmentService,
  getFragmentsByDocument,
  getFragmentsBySection,
  getFragmentService,
  removeFragmentService,
  updateFragmentService,
  updateNewAndOldFragmentService,
} from '../../Services/FragmentService';
import Section from '../../Models/Section';
import Fragment from '../../Models/Fragment';
import {toast} from '../../Utils/Utils';
import {getForm, getSections, getTaskDetail} from '../../Services';

declare let location: Location;

function* loadFragmentsSaga({payload}: PayloadAction<string>) {
  let res = yield getFragmentsBySection(payload);
  if (res) {
    yield put(actions.setFragments(res));
  }
}

function* getCurrentFragmentSaga({payload}: PayloadAction<string>) {
  let res = yield getFragmentsByDocument(payload);
  if (res) {
    yield put(actions.setCurrentFragment(res));
  }
}


function* getFragmentSaga({payload}: PayloadAction<string>) {
  const res = yield getFragmentService(payload);
  if (res) {
    yield put(actions.setFragment(res[0]));
  }
}

function* addNewFragmentSaga({payload}: PayloadAction<Section>) {
  const res = yield addNewFragmentService(payload);
  if (res) {
    yield put(actions.setFragments(res));
    yield put(actions.fragmentCreated());
  }
}

function* updateFragmentSaga({payload}: PayloadAction<Fragment>) {
  try {
    const res = yield call(updateFragmentService, payload);
    if (res.success) {
      yield put(actions.setFragment(payload));
    }
  } catch (e) {
    yield put(actions.restart());
    toast('No se pudo actualizar', 'error');
  }
}

function* getSectionsSaga({payload}: PayloadAction<string>) {
  try {
    const res = yield getSections(payload);
    if (res.success) {
      yield put(actions.setSections(res.contents));
      yield put(actions.setDocument(res.document));
    }
  } catch (e) {
    yield put(actions.restart());
    toast('No se pudo actualizar', 'error');
  }
}

function* getForms({
                     payload,
                   }: PayloadAction<{ workflowId: number; taskId: number }>) {
  try {
    const res = yield call(getTaskDetail, payload.workflowId, payload.taskId);
    if (res.success) {
      yield put(actions.setForms(res.item.attachments));
    }
  } catch (e) {
    yield put(actions.restart());
    toast('No se pudo actualizar', 'error');
  }
}

function* getFields({payload}: PayloadAction<number>) {
  try {
    const res = yield call(getForm, payload);
    console.log('res', res);
    if (res.success) {
      yield put(actions.setFields(res.item.fields));
    }
  } catch (e) {
    yield put(actions.restart());
    toast('No se pudo actualizar', 'error');
  }
}

function* removeFragmentSaga({payload}: PayloadAction<Fragment>) {
  try {
    const res = yield call(removeFragmentService, payload);
    if (res.success) {
      yield put(actions.getFragments(payload.section))
    } else throw Error;
  } catch (e) {
    yield put(actions.restart());
    toast('No se pudo borrar el fragmento', 'error');
  }
}

function* onSortEndSaga({payload}: PayloadAction<{ newRow: Fragment, oldRow: Fragment }>) {
  try {
    const res = yield call(updateNewAndOldFragmentService, payload.newRow, payload.oldRow);
    if (res) {
      yield put(actions.getFragments(payload.oldRow.section))
    }
  } catch (e) {
    yield put(actions.restart());
  }
}

export function* fragmentSaga(): Generator {
  yield all([
    takeEvery(GET_FRAGMENTS, loadFragmentsSaga),
    takeEvery(GET_CURRENT_FRAGMENT, getCurrentFragmentSaga),
    takeEvery('fragment/getFragment', getFragmentSaga),
    takeEvery('fragment/getSections', getSectionsSaga),
    takeEvery('fragment/getForms', getForms),
    takeEvery('fragment/getFields', getFields),
    takeEvery(GET_ADD_FRAGMENT, addNewFragmentSaga),
    takeEvery(UPDATE_FRAGMENT, updateFragmentSaga),
    takeEvery(REMOVE_FRAGMENT, removeFragmentSaga),
    takeEvery(ON_SORT_END_FRAGMENTS, onSortEndSaga)
  ]);
}
